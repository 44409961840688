import React from 'react';
import "./StyledEditableProject.scss";
import { Link } from 'react-router-dom';

const StyleEditable = ({ img, bgImg, link, title, subtitle, checked, id, handleChange }) => {
    return (<div className="StyleEditableProject">
        {/* <div className="cc__styled_editable-shadow"></div> */}
        <div className="cc__styled_editable-inner">
            {
                handleChange &&
                <label className="cc__styled-checkbox">
                    <input onChange={e => handleChange(e)} name={id} type="checkbox" className="cc__styled-checkbox-input" checked={checked} />
                    {/* <span className="checkmark"></span> */}
                    <div className="cc__styled-checkbox-selected"></div>
                </label>
            }

            <div className="cc__styled_editable-shadow"></div>
        </div>
        {
            bgImg && <img className="cc__styled_editable-bg" src={process.env.PUBLIC_URL + bgImg} alt="background_image" />
        }
        <div className="cc__styled_editable-inner info-container">
            {
                img &&
                <img className="cc__editable-img" src={process.env.PUBLIC_URL + img} alt="" />
            }
            <div className="info">
                <p className="styled__p-gray">{title}</p>
                <p className="small-10-white">{subtitle}</p>
            </div>

            <Link to={link + (id != null ? id : "")}>
                <img className="cc__editable-icon" src={process.env.PUBLIC_URL + "/icons/edit-w.svg"} alt="" />
            </Link>
        </div>
    </div>);
}

export default StyleEditable;