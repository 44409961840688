import React from 'react';
import PrivateRoute from '../../PrivateRoute';
import Main from './Main/Main';
import Crear from './Crear/Crear';
import Editar from './Editar/Editar';
import { useState } from 'react';
import MultipleEdit from './MultipleEdit/MultipleEdit';

const Index = () => {
    const [selected, setSelected] = useState([]);
    return (<>
        <PrivateRoute exact path="/admin/ley/" component={(props) => <Main setSelected={setSelected} selected={selected} {...props} />} />
        <PrivateRoute exact path="/admin/ley/create" component={Crear} />
        <PrivateRoute exact path="/admin/ley/edit" component={(props) => <MultipleEdit selected={selected} {...props} />} />
        <PrivateRoute exact path="/admin/ley/edit/:id" component={Editar} />
    </>);
}

export default Index;