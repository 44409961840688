import app from "../../firebaseConfig";
import { useContext } from "react";
import { withRouter, Redirect } from "react-router";
import React, { useCallback } from 'react';
import { AuthContext } from "../../AuthContext";
import "./Login.scss";
import { Link } from "react-router-dom";

const Login = ({ history }) => {

    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            console.log(email.value, password.value);
            try {
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push("/admin");
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const currentUser = useContext(AuthContext);
    if (currentUser.currentUser) {
        return <Redirect to="/admin" />
    }
    // jpgarias09@gmail.com
    // ProbandoProbando
    return (<div className="cc__login-container">
        <form className="form-container" onSubmit={handleLogin}>
            <h1 ><u>Log in</u></h1>
            <label className="styled__label-brown-16px " htmlFor="">Utilisateur </label>
            <input className="styled__p-gray " type="text" name="email" />
            <label className="styled__label-brown-16px" htmlFor="">Mot de Passe</label>
            <input className="styled__p-gray " type="password" name="password" />
            <button type="submit">Envoyer</button>
            <Link to="/admin/recover">
                <p>Récupérer le mot de passe</p>
            </Link>
            <img className="logo-login" src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"} alt="logo" title="logo" />
        </form>
    </div>);
}

export default withRouter(Login);