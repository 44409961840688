import React from 'react';
import PageEditor from './Home/PageEditor';
import Main from './Main/Main';
import SinglePage from './SinglePage/SinglePage';
import PrivateRoute from '../../PrivateRoute';
import DefaultEdit from './DefaultEdit/DefaultEdit';
import ProgrammEdit from './ProgrammEdit/ProgrammEdit';
import Group from './Group/Group';
import { useState } from 'react';


const Index = () => {
    const selectedPaged = useState([])
    return (<>
        <PrivateRoute exact path="/admin/pages" component={props => <Main selectedPaged={selectedPaged} {...props} />} />
        <PrivateRoute exact path="/admin/pages/home" component={PageEditor} />
        <PrivateRoute exact path="/admin/edit-page" component={SinglePage} />
        <PrivateRoute exact path="/admin/pages/group" component={props => <Group typeEdit={"group"} {...props} />} />
        <PrivateRoute exact path="/admin/pages/vendre" component={props => <DefaultEdit typeEdit={"vendre"} {...props} />} />
        <PrivateRoute exact path="/admin/pages/contact" component={props => <DefaultEdit typeEdit={"contact"} {...props} />} />
        <PrivateRoute exact path="/admin/pages/programmes" component={props => <ProgrammEdit typeEdit={"programmes"} {...props} />} />
        <PrivateRoute exact path="/admin/pages/investissement" component={props => <DefaultEdit typeEdit={"investissement"} {...props} />} />
    </>);
}

export default Index;