import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

import "./Menu.scss";
import app from "../../Admin/firebaseConfig";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      generalInfo: {},
    };
  }

  componentDidMount() {
    app.database().ref("generalInfo/").once('value').then(ss => {
      this.setState({ generalInfo: ss.val() }, () => {
        this.setState({ loaded: true })
      })
    })
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="menu__container">
        <div className="menu__container-top">
          <div className="menu__top-left">
            {/*<p><a href="#news-letter-register">Abonnez-vous à notre Newsletter</a> </p>*/}
            <section className="social">
              <div className="fb-like"
                   data-href="https://www.facebook.com/ConseilEtCommercialisation/"
                   data-width=""
                   data-layout="button"
                   data-action="like"
                   data-size="small"
                   data-share="false">
              </div>
              {/*<a href={this.state.generalInfo.facebook} target="_blank" rel="noopener noreferrer">*/}
              {/*  <img*/}
              {/*      alt="facebook"*/}
              {/*      src={process.env.PUBLIC_URL + "/icons/fb.svg"}*/}
              {/*  />*/}
              {/*</a>*/}
              <a
                 href={this.state.generalInfo.youtube}
                 target="_blank"
                 rel="noopener noreferrer">
                <img alt="youtube"
                    title="youtube"
                    src={process.env.PUBLIC_URL + "/icons/youtube1.svg"}
                />
              </a>
            </section>
            <img
              className="menu-responsive"
              src={process.env.PUBLIC_URL + "/icons/menu-responsive.svg"}
              alt="fermer-menu"
              title="fermer-menu"
              onClick={() => {
                this.setState({ open: !this.state.open });
              }}
            />
            <div
              className={
                this.state.open
                  ? "menu__bot-right display-none "
                  : "menu__bot-right"
              }>
              <div
                className="menu__bot-right-container"
                onClick={() => {
                  this.setState({ open: !this.state.open });
                }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/logo/logo_cc_white.svg"}
                  style={{
                    height: "55px",
                    marginTop: "2em",
                    marginBottom: "8em",
                  }} className={"tablet"}
                  alt={"C&C"}
                  title="C&C"
                >
                </img>
                <div style={{ display: "flex", flexDirection: "column" }} className={"phone"}>
                  <NavLink exact activeClassName="selectedmenu" to="/">
                    Accueil
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="selectedmenu"
                    to="/programmes">
                    Programmes neufs
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="selectedmenu"
                    to="/investissement">
                    Investissement locatif
                  </NavLink>
                  <NavLink exact activeClassName="selectedmenu" to="/vendre">
                    Un bien à vendre ?
                  </NavLink>
                  <NavLink exact activeClassName="selectedmenu" to="/groupe">
                    C&C groupe
                  </NavLink>
                  <NavLink exact activeClassName="selectedmenu" to="/contact">
                    Contact
                  </NavLink>
                </div>

                <img
                  className="x-icon"
                  src={process.env.PUBLIC_URL + "/icons/close_pink.svg"}
                  alt="closing menu button"
                  title="bouton fermer menu"
                  onClick={() => {
                    this.setState({ open: !this.state.open });
                  }}
                  style={{ position: "absolute", top: "2em", left: "2em" }}
                />
              </div>
            </div>
          </div>
          <div className="menu__top-right">
            <p>
              <img
                className="icon"
                src={process.env.PUBLIC_URL + "/icons/Group 9.svg"}
                alt="icone-mail"
                title="icone mail"
                >
              </img>
              <a className="top-container-links" href="mailto:contact@conseil-commercialisation.fr">
                {" "}
                contact@conseil-commercialisation.fr
              </a>
            </p>
            <p>
              {" "}
              <a className="top-container-links" href="tel:+33 1 86 95 76 44"> +33 1 86 95 76 44</a>
            </p>
            <Link to="/" className="small-logo">
              <img
                src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"}
                alt="logo"
                title="logo"
              ></img>
            </Link>
            <a className="top-container-links-background" target="_blank" rel="noopener noreferrer" href="https://cc-residentiel.com/wp-login.php?redirect_to=https%3A%2F%2Fcc-residentiel.com%2Fwp-admin%2F&reauth=1">
              <img
                className="icon"
                src={process.env.PUBLIC_URL + "/icons/User Icon.svg"}
                alt="icone-utilisateur"
                title="icone utilisateur"
              >
              </img>
            </a>
          </div>
        </div>
        <div className="menu__container-bot">
          <Link to="/" className="menu__bot-left menu__link-img" >
            <img
              src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"}
              alt="logo"
              title="logo"
              style={{
                width: this.props.width ? this.props.width : "155px",
              }}></img>
          </Link>
          <div className="menu__bot-right-container-parent">
            <div
              className="menu__bot-right-container"
              onClick={() => {
                this.setState({ open: !this.state.open });
              }}>
              <NavLink exact activeClassName="selectedmenu" to="/">
                Accueil
              </NavLink>
              <NavLink exact activeClassName="selectedmenu" to="/programmes">
                Programmes neufs
              </NavLink>
              <NavLink
                exact
                activeClassName="selectedmenu"
                to="/investissement">
                Investissement locatif
              </NavLink>
              <NavLink exact activeClassName="selectedmenu" to="/vendre">
                Un bien à vendre ?
              </NavLink>
              <NavLink exact activeClassName="selectedmenu" to="/groupe">
                C&C groupe
              </NavLink>
              <NavLink exact activeClassName="selectedmenu" to="/contact">
                Contact
              </NavLink>

              <img
                className="x-icon"
                src={process.env.PUBLIC_URL + "/icons/close_pink.svg"}
                alt="bouton fermer menu"
                title="bouton fermer menu"
                onClick={() => {
                  this.setState({ open: !this.state.open });
                }}
                style={{ position: "absolute", top: "2em", left: "2em" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
