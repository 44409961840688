import './StyledContainer.scss';
import React, { Component } from 'react';
import { Parallax } from "react-scroll-parallax";

const map = (value, start1, stop1, start2, stop2) => {
    const outgoing = start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
    // var badness = null;
    // if (outgoing != outgoing) {
    //     badness = "NaN (not a number)";
    // } else if (outgoing == Number.NEGATIVE_INFINITY || outgoing == Number.POSITIVE_INFINITY) {
    //     badness = "infinity";
    // }

    return outgoing;
}

class StyledContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opacidad: 40,
            styledBar: props.styledBar ? props.styledBar : 0,
        }

        this.opacityRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrolls)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrolls)
    }

    handleScrolls = (e) => {
        var item = this.opacityRef.current.getBoundingClientRect();

        var top = item.top;
        var bot = item.top + item.height;

        if (top < 0 && bot > 0) {
            var opacityMap = map(top, 0, -(item.height), 50, 300);
            // console.log(opacityMap);
            this.setState({
                opacidad: opacityMap
            })
        }
    }

    // TODO : Parallax put again if need animation

    render() {
        return (
            <div ref={this.opacityRef} className={this.state.styledBar == 0 ? "cc-styled_container " : "cc-styled_no_container "}
                     style={this.props.pdng ? { paddingTop: this.props.pdng, paddingBottom: this.props.pdng } : null}>
            {this.state.styledBar == 0 && <div className={"cc-styled_bar " + this.props.scn} ></div>}
            <Parallax >
                <div className={(this.state.styledBar == 0 ? "cc-styled_wrapper " : "cc_no_parent_styled_wrapper ") + this.props.cn} >
                    {
                        this.props.children
                    }
                </div>
            </Parallax>
        </div>);
    }
}

StyledContainer.defaultProps = {
    cn: '',
    scn: 'cc-styled-left'
}

export default StyledContainer;