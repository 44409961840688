import React from "react";
import Slider from "../../Components/Slider/Slider";
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import "./Ley.scss";
import { useEffect } from "react";
import app from "../../Admin/firebaseConfig";
import { useState } from "react";
import MetaTags from "react-meta-tags";

const Ley = ({ match, setvisual }) => {
  const [loaded, setLoaded] = useState(false)
  const [ley, setLey] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    app.database().ref("leyes2/").child(match.params.leyId).once("value").then(ss => {
      if (ss.val()) {
        setLey(ss.val());
        return true;
      }
      return false;
    }).then((didLoad) => {
      if (didLoad) setLoaded(true);
    })
  }, []);


  function HasTable(props) {
    //window.history.pushState(null, ley.title, ley.slug);
    const data = props.tabla;
    if (data != null && data != undefined) {
      return <StyledContainer cn="cc-middle" scn="cc-styled-right" className={"tableWrapper"}>
        <div className="inversion__info" style={{ padding: "30px" }}>
          <h1 className="title-h1-brown " style={{ textAlign: "right" }}>
            Tableau de <br /><u>Zones Pinel</u>
          </h1>
          <div className={'table'}>
            <div className={'header'} >
              <div className={'cell'}>zones pinel</div>
              <div className={'cell'}>BARÈME PINEL 2020</div>
            </div>
            <div className={'spacer'}></div>
            <div className={'body'}>
              <div className={'row'}>
                <div className={'cell'}>Zone ABis</div>
                <div className={'cell'}>17,43 €/m2</div>
              </div>
              <div className={'row'}>
                <div className={'cell'}>Zone A</div>
                <div className={'cell'}>12,95 €/m2</div>
              </div>
              <div className={'row'}>
                <div className={'cell'}>Zone B1</div>
                <div className={'cell'}>10,55 €/m2</div>
              </div>
              <div className={'row'}>
                <div className={'cell'}>Zone B2 & C</div>
                <div className={'cell'}>9,02 €/m2</div>
              </div>
            </div>
          </div>
        </div>
      </StyledContainer>
    } else {
      //Do Nothing
      return null
    }
  }
  return (
      <>{loaded &&

      <div className="leyes_container">
        <BannerSlide title={ley.nombre} />
        <StyledContainer cn="cc-middle">
          <div >
            <h2 className="title-h2-brown" style={{ textAlign: "left" }}>
              {ley.subtitle}
            </h2>
            <p style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: ley.description }}></p>
          </div>
        </StyledContainer>

        {HasTable(ley)}

      </div >
      }    </>
  );
};

const handleClick = (e, f) => {
  f();
}

export default Ley;
