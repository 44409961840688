import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import app from '../../../firebaseConfig';
import "./Crear.scss";
import { useState } from 'react';
import StyledCreate from '../../../Components/Create/StyledCreate';
import uuid from 'react-uuid';
import StyledLoi from '../../../Components/StyledLoi/StyledLoi';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useRef } from 'react';

class Proyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 2,
            title: '',
            zipCode: 0,
            lugar: "",
            currentState: "TRAVAUX EN COURS",
            housing: {
                tipo: "",
                quantity: [0, 0],
                pieces: [0, 0]
            },
            folder: '',
            pdf: null,
            piezas: 0,
            maxPrice: 0,
            minPrice: 0,
            startProject: { month: 11, year: 2020 },
            leyes: [],
            description: "",
            detailedDescriptionResidence: "",
            detailedDescriptionPrices: "",
            detailedDescriptionPlace: "",
            detailedDescriptionPros: "",
            detailsSalesOffice: "",
            imgs: [],
            dirProject: {
                name: "",
                dir: "0,0"
            },
            dirSell: {
                name: "",
                dir: "0,0"
            },
            atentionHour: {
                cita: false,
                weekend: {
                    start: '14:00', end: '19:00'
                },
                weekday: {
                    start: '10:00', end: ' 19:00'
                }
            },
            phone: "879898798",
            promotor: {
                logo: '',
                description: ""
            },
            promotorName: "",
            promotorList: [],
            openModal: false,
            loiList: [],
            loading: false,
            nearProject: {
                titulo: "",
                visualiacionProyectos: ["-", "-", "-", "-", "-", "-"],
                cantidadDeProyectos: 6
            },
            opciones: {
                project: ["-", "-", "-", "-", "-", "-"],
                modalOpen: false,
                pdfRef: null,
                pageType: "",
                toastM: "",
                toastMS: "warning",
                openWarning: false
            },
            metaTitle: "",
            metaDescription: "",
            slug: "",
        }

        this.months = [
            { month: "janvier", value: 1 },
            { month: "février", value: 2 },
            { month: "Mars", value: 3 },
            { month: "avril", value: 4 },
            { month: "mai", value: 5 },
            { month: "juin", value: 6 },
            { month: "juillet", value: 7 },
            { month: "août", value: 8 },
            { month: "septembre", value: 9 },
            { month: "Octobre", value: 10 },
            { month: "Novembre ", value: 11 },
            { month: "Décembre", value: 12 }];

        this.projectState = ["TRAVAUX EN COURS", 'NOUVELLE RÉALISATION', 'LIVRAISON IMMÉDIATE'];
        this.pdfRef = React.createRef();
    }

    componentDidMount() {
        this.loading();
    }

    loading = async () => {
        //Busca todos los proyectos, para poder seleccionar los proyectos más cercanos
        const projects = app.database().ref("projects/");
        const projectList = [];
        //Verifico si me están pasando algun id como parametro para bsucarlo en la base de datos

        try {
            let pageType = this.props.match.url.split("/");
            await this.setState({ opciones: { ...this.state.opciones, pageType: pageType[3] } })
        } catch (error) {
            this.props.history.push('/admin');
        }

        if (this.props.match.params.id) {
            var projectId = this.props.match.params.id;
            projects.child(projectId).once("value").then(ss => {
                if (ss.val()) {
                    this.setState({ ...ss.val() }, () => {
                        this.setState({ leyes: ss.val().leyes })
                    })

                }
            })
        } else {

            const ley = app.database().ref("certificados/");
            const loiList = [];

            //Busca todos los promotores, que en realidad deberían ser todos los certificados disponibles
            ley.once("value").then(ss => {
                ss.forEach(child => {
                    loiList.push(child.val())
                })
            }).then(() => {
                this.setState({
                    leyes: loiList
                })
            })
        }

        projects.once("value").then(ss => {

            ss.forEach(child => {
                projectList.push({ valor: child.val().id, name: child.val().title });
            })
            projectList.push({ valor: "-", name: "-" });
        }).then(() => {
            this.setState({ opciones: { ...this.state.opciones, project: projectList } });
        })

        const promotores = app.database().ref("promotores/")
        const promotoresList = []

        //Obtengo todos los promotores disponibles
        promotores.once("value").then(ss => {
            ss.forEach(child => {
                promotoresList.push(child.val())
            })
            if (promotoresList && promotoresList.length > 0) {
                promotoresList.sort(function(a, b){
                    if(a.nombre.toLowerCase() < b.nombre.toLowerCase()) { return -1; }
                    if(a.nombre.toLowerCase() > b.nombre.toLowerCase()) { return 1; }
                    return 0;
                })
                this.setState({ promotorList: promotoresList })
            }
        }).then(() => {
            //Si promotoresList no está vacío Settea el estado al valor encontrado
            //console.log(promotoresList)
            if (promotoresList) {
                this.setState([{ promotorList: promotoresList }])

            }
        });
    }

    handleInputChange = (nombre, value) => {
        var splitedName = nombre.split("/");
        var settings = {};
        this.settingChilds(this.state, splitedName, value);
        this.setState(settings);
    }

    handlenearProjectChange = (name, value) => {
        name = name.split("-")

        if (name[0] === "visualiacionProyectos") {
            // var element = this.state
            var updatedElement = [...this.state.nearProject.visualiacionProyectos];
            // return;
            updatedElement[parseInt(name[1])] = value;
            this.setState({
                nearProject: {
                    ...this.state.nearProject, visualiacionProyectos: this.state.nearProject.visualiacionProyectos.map((e, i) => {
                        return parseInt(name[1]) === i ? value : e
                    })
                }
            })
        }
        else {
            this.setState({ nearProject: { ...this.state.nearProject, [name[0]]: value } })
        }
    }

    cancelUpload = () => {
        this.props.history.push("/admin");
    }

    settingChilds = (obj, keyPath, value) => {
        var lastKeyIndex = keyPath.length - 1;

        var lastKeyIsNumber = !isNaN(keyPath[lastKeyIndex]);

        if (lastKeyIsNumber) {
            lastKeyIndex -= 1;
        }

        for (var i = 0; i < lastKeyIndex; ++i) {
            var key = keyPath[i];
            if (!(key in obj)) {
                obj[key] = {}
            }
            obj = obj[key];
        }

        if (lastKeyIsNumber) {
            obj[keyPath[lastKeyIndex]][parseInt(keyPath[keyPath.length - 1])] = value;
        } else {
            obj[keyPath[lastKeyIndex]] = value;
        }
    }

    handleFileChange = (value) => {
        var imageCopy = [...this.state.imgs];
        imageCopy.push(value);
        this.setState({ imgs: imageCopy })
    }

    isEmpty = (obj) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    handleUploadValidation = () => {

    }

    slugify (str) {
        var map = {
            'a' : 'á|à|ã|â|À|Á|Ã|Â',
            'e' : 'é|è|ê|É|È|Ê',
            'i' : 'í|ì|î|Í|Ì|Î',
            'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
            'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
            'c' : 'ç|Ç',
            'n' : 'ñ|Ñ'
        };

        for (var pattern in map) {
            str = str.replace(new RegExp(map[pattern], 'g'), pattern);
        };

        return str;
    };

    handleUpload = async () => {

        var {
            pdf,
            id,
            title,
            zipCode,
            lugar,
            nearProject,
            currentState,
            housing,
            maxPrice,
            minPrice,
            startProject,
            leyes,
            description,
            detailedDescriptionPros,
            detailedDescriptionPlace,
            detailedDescriptionPrices,
            detailedDescriptionResidence,
            detailsSalesOffice,
            imgs,
            dirProject,
            dirSell,
            atentionHour,
            phone,
            promotor,
            metaTitle,
            metaDescription,
            slug
        } = { ...this.state };

        if (title === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Nom de projet manquant",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (zipCode === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Code postal manquant",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (lugar === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "La place manque",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });

            return
        } else if (currentState === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "État actuel manquant",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (maxPrice === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Prix maximum manquant",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (leyes.length <= 0) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Les certificats n'ont pas été sélectionnés",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (description === "" || (detailedDescriptionResidence === "" && detailedDescriptionPrices === "" && detailedDescriptionPros === "" && detailedDescriptionPlace === "")) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Description, Résidence, Avantages, Prix, lieu est/sont manquant(s)",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (imgs.length <= 0) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Aucune image sélectionnée",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (phone === "") {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Numéro de téléphone manquant",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (this.isEmpty(housing)) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Informations sur le logement manquantes",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (this.isEmpty(startProject)) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Date de début du projet manquante",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (this.isEmpty(dirProject)) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Adresse de projet manquante",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (this.isEmpty(atentionHour)) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Les heures de bureau manquent",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        } else if (this.isEmpty(promotor)) {
            this.setState({
                opciones: {
                    ...this.state.opciones,
                    toastM: "Le promoteur n'a pas été sélectionné",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
            return
        }

        var imgURLs = [];
        const db = app.database();

        this.setState({ loading: true });

        for (const imageFile of imgs) {
            if (imageFile.file) {
                await this.uploadImageAsPromise(imageFile.file).then((testURL) => {
                    imageFile.imgURL = testURL.url;
                    imageFile.ref = testURL.fileRef;
                    delete (imageFile.file)

                    imgURLs.push(imageFile);
                })
            } else {
                imgURLs.push(imageFile);
            }
        }

        if (pdf !== null) {
            if (pdf.name)
                await this.uploadImageAsPromise(pdf).then(url => pdf = url)
        }

        var slug = "/"+this.state.lugar+"/"+this.state.title;
        slug = slug.toLowerCase();
        slug = this.slugify(slug);
        slug = slug.split(' ').join('-');

        //Verificamos si la página acutal en la que está activo este compoenente                                                                        º
        var objToUpload = {
            id,
            promotor,
            title,
            zipCode,
            lugar,
            currentState,
            housing,
            maxPrice,
            dirProject,
            dirSell,
            minPrice,
            startProject,
            leyes,
            atentionHour,
            phone,
            imgs: imgURLs,
            nearProject,
            pdf,
            detailsSalesOffice,
            slug: slug,
            metaTitle,
            metaDescription,
            //Los saltos de línea son reemplazados pro <br/>
            description: description.replace(/\n/g, "<br />"),
            detailedDescriptionResidence: detailedDescriptionResidence.replace(/\n/g, "<br />"),
            detailedDescriptionPrices: detailedDescriptionPrices.replace(/\n/g, "<br />"),
            detailedDescriptionPlace: detailedDescriptionPlace.replace(/\n/g, "<br />"),
            detailedDescriptionPros: detailedDescriptionPros.replace(/\n/g, "<br />")
        }

        if (this.state.opciones.pageType === "edit") {
            db.ref("projects/").update({ [id]: objToUpload }).then(() => {
                this.setState({ loading: false });
                this.props.history.push("/admin");
            })
        } else if (this.state.opciones.pageType === "create") {
            const newKey = db.ref("projects/").push().key;
            objToUpload.id = newKey;

            db.ref(`projects/${newKey}`).set(objToUpload).then(() => {
                this.setState({ loading: false });
                this.props.history.push("/admin");
            });
        }
    }

    uploadImageAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var storage = app.storage();
                var imageExtention = imageName.splice(imageName.length - 1);
                var fileRef = `projects/${uuid() + "." + imageExtention}`;
                var storageRef = storage.ref(fileRef);

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(url => {
                        resolve({ url, fileRef });
                    });
                })
            }
        })
    }

    removeImageFromStorage = (fileRef) => {
        this.setState({ loading: true })
        app.storage().ref("/" + fileRef).delete().then(() => {
            app.database().ref("projects").child(this.props.match.params.id).update({ pdf: null }).then(() => {
                this.setState({
                    pdf: null,
                    loading: false,
                    opciones: {
                        ...this.state.opciones,
                        toastM: "Fichier supprimé avec succès",
                        toastMS: "warning",
                    }
                }, () => {
                    this.warningMessage(true);
                });
            })
        }).catch(err => {
            this.setState({
                loading: false,
                opciones: {
                    ...this.state.opciones,
                    toastM: "Une erreur s'est produite lors de la suppression du fichier",
                    toastMS: "warning",
                }
            }, () => {
                this.warningMessage(true);
            });
        })
    }

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.warningMessage(false);
    };

    warningMessage = (state) => {
        this.setState({ opciones: { ...this.state.opciones, openWarning: state } });
    };

    render() {
        return (<div className="cc__page_editor-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                {
                    this.state.opciones.pageType === 'edit' ?
                        <h1 className="styled__h1-brown_underline style__align-right" style={{ textTransform: 'uppercase' }}>Éditer</h1>
                        :
                        <h1 className="styled__h1-brown_underline style__align-right" style={{ textTransform: 'uppercase' }}>créer</h1>
                }
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Général</h1>
                <StyledInputs
                    value={this.state.title}
                    type={"input"}
                    name="title"
                    title="Nom du programme *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.lugar}
                    type={"input"}
                    name="lugar"
                    title="Titre *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.zipCode}
                    type={"input"}
                    name="zipCode"
                    title="Code postal *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.detailedDescriptionResidence}
                    type="textarea"
                    name="detailedDescriptionResidence"
                    title="Residence *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.detailedDescriptionPlace}
                    type="textarea"
                    name="detailedDescriptionPlace"
                    title="Le lieu *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.detailedDescriptionPros}
                    type="textarea"
                    name="detailedDescriptionPros"
                    title="Les avantages *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.detailedDescriptionPrices}
                    type="textarea"
                    name="detailedDescriptionPrices"
                    title="La grille de prix *"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.description}
                    type="textarea"
                    name="description"
                    title="La description *"
                    handleChange={this.handleInputChange}
                />
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Caractéristiques</h1>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.housing.quantity[0]}
                        type="input"
                        name="housing/quantity/0"
                        title="Maison - Nombre de piéces"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.housing.quantity[1]}
                        type="input"
                        name="housing/quantity/1"
                        title="Appartement - Nombre de pièces"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.housing.pieces[0]}
                        type="input"
                        name="housing/pieces/0"
                        title="Nombre minimum de pièces"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.housing.pieces[1]}
                        type="input"
                        name="housing/pieces/1"
                        title="Nombre maximum de pièces"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.startProject.month}
                        type="select"
                        options={this.months.map(e => ({ name: e.month, valor: e.value }))}
                        name="startProject/month"
                        title="Date de début - Mois *"
                        className="first-element"
                        key={23}
                        handleChange={(name, value) => this.setState({ startProject: { ...this.state.startProject, month: parseInt(value) } })}
                    />
                    <StyledInputs
                        value={this.state.startProject.year}
                        type="input"
                        name="startProject/year"
                        title="Date de début - Année *"
                        className="first-element"
                        key={24}
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.currentState}
                        type="select"
                        options={this.projectState}
                        name="currentState"
                        title="Ètat du projet *"
                        key={25}
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />

                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Prix</h1>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.minPrice}
                        type="input"
                        name="minPrice"
                        title="Prix minimum *"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.maxPrice}
                        type="input"
                        name="maxPrice"
                        title="Prix maximum *"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Emplacement et contact</h1>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.phone}
                        type="input"
                        name="phone"
                        title="Téléphone *"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                <div className="cc__row">
                    <StyledInputs
                        type="input"
                        value={this.state.dirSell.name}
                        name="dirSell/name"
                        title="Adresse du bureau des ventes"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        type="input"
                        value={this.state.dirProject.name}
                        name="dirProject/name"
                        title="Adresse du site du projet *"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                <div className="cc__row">
                    <StyledInputs
                        type="input"
                        value={this.state.dirSell.dir}
                        name="dirSell/dir"
                        title="Coordonnées GPS du bureau de vente"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.dirProject.dir}
                        type="input"
                        name="dirProject/dir"
                        title="Coordonnées GPS du site du projet *"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                    <StyledInputs
                        value={this.state.detailsSalesOffice}
                        type="textarea"
                        name="detailsSalesOffice"
                        title="Détails bureau de vente"
                        handleChange={this.handleInputChange}
                    />
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Horaire d'attention</h1>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.atentionHour.weekend.start}
                        type="input"
                        name="atentionHour/weekend/start"
                        title="Week-end"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.atentionHour.weekend.end}
                        type="input"
                        name="atentionHour/weekend/end"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />
                </div>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.atentionHour.weekday.start}
                        type="input"
                        name="atentionHour/weekday/start"
                        title="Jour de la semaine"
                        className="first-element"
                        handleChange={this.handleInputChange}
                    />
                    <StyledInputs
                        value={this.state.atentionHour.weekday.end}
                        type="input"
                        name="atentionHour/weekday/end"
                        className="second-element"
                        handleChange={this.handleInputChange}
                    />

                </div>
                <div className="cc__column">
                    <label className="cc__checkbox">
                        <input className="cc__checkbox-input" type="checkbox" checked={this.state.atentionHour.cita} value={this.state.atentionHour.cita} name="" id="" onChange={(e) => {
                            this.setState({ atentionHour: { ...this.state.atentionHour, cita: e.target.checked } })
                        }} />
                        <div className="cc__checkbox-outline">
                            <div className="cc__checkbox-selected">
                            </div>
                        </div>
                        <p className="styled__label-brown-16px"> Ouvert sur Rendez-Vous </p>
                    </label>
                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Projets à proximité</h1>
                <p className="styled__label-brown-16px style__align-left">Ordre de projet</p>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[0]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-0"
                        title="Premier projet"
                        handleChange={this.handlenearProjectChange}
                    />
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[1]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-1"
                        title="Deuxième projet"
                        handleChange={this.handlenearProjectChange}
                    />
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[2]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-2"
                        title="Trosiéme projet"
                        handleChange={this.handlenearProjectChange}
                    />
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[3]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-3"
                        title="Quatrième projet"
                        handleChange={this.handlenearProjectChange}
                    />
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[4]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-4"
                        title="Cinquième projet"
                        handleChange={this.handlenearProjectChange}
                    />
                    <StyledInputs
                        value={this.state.nearProject.visualiacionProyectos[5]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-5"
                        title="Sixième projet"
                        handleChange={this.handlenearProjectChange}
                    />
                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Promoteur</h1>
                <p>{this.state.promotor.id}</p>
                <StyledInputs
                    value={this.state.promotor}
                    options={this.state.promotorList.map(e => ({ name: e.nombre, valor: e.id }))}
                    type="select"
                    name="titulo"
                    title="Promoteur *"
                    className="first-element"
                    handleChange={(name, value) => this.setState({ promotor: value })}
                />
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Certificat</h1>
                <div className="ley_container">
                    {this.state.leyes.map((e, i) => {
                        return <StyledLoi
                            nombre={e.title}
                            img={e.imgURL}
                            checked={e.checked}
                            value={e.checked}
                            id={e.id}
                            key={"leyes-" + i}
                            handleChange={(el) => this.setState({
                                leyes: this.state.leyes.map((ele, index) => { return i === index ? { ...ele, checked: el.target.checked } : ele })
                            })} />
                    })}
                </div>
            </section>
            <div className="cc__division"></div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Images</h1>
                <ImageList
                    images={this.state.imgs}
                    openModal={() => this.setState({ openModal: !this.state.openModal })}
                    removeImage={(i) => {
                        var { imgs } = { ...this.state };
                        imgs.splice(i, 1);
                        this.setState({ imgs });
                    }}
                />
                {this.state.openModal && <ModalImages handleChange={this.handleFileChange} closeModal={() => this.setState({ openModal: false })} />}
            </div>
            <div className="cc__division"></div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left" >PDF</h1>
                <input className="hide" type="file" name="" id="" onChange={(e) => this.setState({ pdf: e.target.files[0] })} ref={this.pdfRef} />
                {this.state.pdf === null ? <>
                    <StyledCreate
                        action={() => { this.pdfRef.current.click() }}
                        nombre="Ajouter un PDF"
                        width={300}
                    />
                </> : <div className="pdf__container" onClick={() => this.removeImageFromStorage(this.state.pdf.fileRef)}>
                        <div className="bg-container"></div>
                        <h1 className="styled__h3-gray">{this.state.pdf.name ? this.state.pdf.name : "Supprimer PDF"}</h1>
                        <p className="styled__label-pink ">{this.state.pdf.name ? ((parseInt(this.state.pdf.size) / 1000) + " kb") : ""}</p>
                        <div className="shadow"></div>
                    </div>}
            </div>
            <Snackbar
                open={this.state.opciones.openWarning}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                <SnackbarContent style={{ backgroundColor: "#eb0271", display: 'flex', flexDirection: 'row', justifyContent: 'center' }} message={this.state.opciones.toastM} onClose={this.handleClose} />
            </Snackbar>
            <div className="cc__division"></div>
            <div className="cc__section">
                <div className="cc__button-2">
                    <button className="cc__button accept" onClick={this.cancelUpload}>Annuler</button>
                    <button className="cc__button cancel" onClick={() => this.handleUpload()}>Envoyer</button>
                </div>
            </div>
        </div>);
    }
}

const ImageList = ({ images, openModal, removeImage }) => {
    return (<div className="cc__imglist-container">
        {
            images.map((e, i) => {
                if (typeof e == "string") {
                    return <CardImage key={"il-" + i} img={e} index={i} removeImage={removeImage} />
                }
                return <CardImage key={"il-" + i} img={e.imgURL} index={i} removeImage={removeImage} />

            })
        }
        <StyledCreate
            nombre="Ajouter une image"
            action={openModal}
            width={300}
        />
    </div>);
}

const CardImage = ({ img, index, removeImage }) => {
    return (
        <div className="cc__cardimage" style={{ backgroundImage: `url(${img})` }}>
            <div className="options">
                <div className="delete option" onClick={() => removeImage(index)}>
                    <img src="/icons/Icon awesome-trash-alt.svg" alt="" />
                </div>
                <div className="edit option">
                    <img src="/icons/edit-w.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

const ModalImages = ({ handleChange, closeModal }) => {

    const [currentImageType, setCurrentImageType] = useState("Image");
    const [link, setLink] = useState("");
    const [currentImage, setCurrentImage] = useState(null);
    const [currentImageURL, setCurrentImageURL] = useState(null);
    const btnRef = useRef(null);

    return (<div className="cc__modal-container">
        <div className="cc__modal-info">
            <StyledInputs
                value={currentImageType}
                options={["Video", "Augmented Reality", "YouTube", "Image"]}
                type="select"
                name="image"
                title="Type"
                handleChange={(name, value) => { setCurrentImageType(value) }}
            />
            <FormTypes
                type={currentImageType}
                setLink={setLink}
                link={link}
            />
            {
                currentImage !== null ?
                    <div className="form-img" >
                        {currentImageURL && <img src={currentImageURL} alt="" />}
                    </div> :
                    <StyledCreate
                        action={() => btnRef.current.click()}
                        nombre="Ajouter une image"
                        width={300} />
            }
            <input accept="image/*" className="hidden" type="file" name="" id="" ref={btnRef} onChange={
                e => {
                    setCurrentImage(e.target.files[0])
                    readURL(e.target, setCurrentImageURL)
                }
            } />
            <br/><br/>
            <div className="cc__button-2">
                <button className="cc__button accept" onClick={closeModal}>Annuler</button>
                <button className={(currentImage != null & currentImageURL != null) ? "cc__button cancel" : "cc__button cancel active"} onClick={
                    () => {
                        (currentImage != null & currentImageURL != null) && console.log("");
                        (currentImage != null & currentImageURL != null) && handleChange({ link: link, imgURL: currentImageURL, type: currentImageType, file: currentImage });
                        (currentImage != null & currentImageURL != null) && closeModal();
                    }}>
                    Envoyer
                </button>
            </div>
        </div>
    </div>)
}

const FormTypes = ({ type, setLink, link }) => {
    switch (type) {
      case "Video":
          return (
              <>
                  <StyledInputs
                      value={link}
                      type="input"
                      name="id"
                      title="Video"
                      handleChange={(name, value) => {
                          setLink(value);
                      }}
                  />
              </>
          )

        case "YouTube":
            return (
                <>
                    <StyledInputs
                        value={link}
                        type="input"
                        name="id"
                        title="YouTube ID"
                        handleChange={(name, value) => {
                            setLink(value);
                        }}
                    />
                </>
            )

        case "Augmented Reality":
            return (
                <>
                    <StyledInputs
                        value={link}
                        type="input"
                        name="link"
                        title="VR link"
                        handleChange={(name, value) => {
                            setLink(value);
                        }}
                    />
                </>
            )

        default:
            return <></>;
    }
}

const readURL = (e, setCurrentImageURL) => {
    if (e.files && e.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
            setCurrentImageURL(e.target.result);
        }

        reader.readAsDataURL(e.files[0]);
    }
}


export default Proyectos;
