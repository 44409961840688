import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from '../../../firebaseConfig';
import uuid from 'react-uuid';

class DefaultEdit extends Component {
    constructor(props) {
        super(props);
        this.state = { titulo: "", img: null }
    }

    componentDidMount() {
        if (this.props.typeEdit) {
            app.database().ref(this.props.typeEdit + "/").once("value").then(ss => {
                this.setState(ss.val())
            })
        } else {
            this.props.history.push("/admin/pages");
        }
    }

    uploadEdited = async () => {
        if (this.state.img.name) {
            var downloadURL = ""
            await this.uploadImageAsPromise(this.state.img, this.state.img.name)
                .then(url => {
                    downloadURL = url
                })
            app.database().ref(this.props.typeEdit + "/").set({ ...this.state, img: downloadURL })
        }
    }

    uploadImageAsPromise = async (e, name) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var imageExtention = imageName.splice(imageName.length - 1);
                const storageRef = app.storage().ref(`${name}/${uuid() + "." + imageExtention}`)

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(downloadURL => {
                        resolve(downloadURL);
                    })
                })
            }
        })
    }

    render() {
        return (<div className="cc__container">
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right" style={{ textTransform: "uppercase" }}>{this.props.typeEdit}</h1>
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Header</h1>
                <StyledInputs
                    value={this.state.titulo}
                    type={"input"}
                    name="nombreDePagina"
                    title="Titre"
                    handleChange={(name, value) => this.setState({
                        titulo: value
                    })}
                />
                <StyledFileUpload
                    name="imagenActual"
                    title="Image Hero"
                    img={this.state.img}
                    onFileChange={(name, value) => {
                        this.setState({ img: value })
                    }} />
                <div className="cc__button-2">
                    <button className="cc__button accept" >Nettoyer</button>
                    <button className="cc__button cancel" onClick={this.uploadEdited}>Envoyer</button>
                </div>
            </section>
        </div>);
    }
}

export default DefaultEdit;