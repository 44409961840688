import React, { Component } from 'react';
import app from '../../../firebaseConfig';
import "./MultipleEdit.scss";
import uuid from 'react-uuid';

class MultipleEdit extends Component {

    constructor(props) {
        super(props);
        this.props.selected ? (this.props.selected.length <= 0 && this.props.history.push("/admin/certificat")) : this.props.history.push("/admin/certificat")
        this.state = {
            selected: props.selected,
            certificadoList: [],
            loading: false
        };
    }


    componentDidMount() {
        if (this.props.selected.length <= 0) {
            this.props.history.push("/admin/certificat");
        }
        const leyes = app.database().ref("certificados/")
        const certificadoList = []
        leyes.once("value").then(ss => {
            ss.forEach(child => {
                certificadoList.push(child.val())
            })
        }).then(() => {
            if (certificadoList)
                this.setState({ certificadoList: certificadoList })
        });
    }

    cancelUpload = () => {
        this.props.history.push("/admin/certificat");
    }

    handleUpload = async () => {

        this.setState({ loading: true })
        const db = app.database().ref("certificados/");

        let certificadosSelected = this.state.selected.slice();
        let certificadosObject = {};


        for (const loi of certificadosSelected) {
            if (loi.imgURL.hasOwnProperty("name")) {
                await this.uploadFileAsPromise(loi.imgURL).then(res => {
                    loi.imgURL = res;
                })
            }
        }

        certificadosSelected.forEach(e => {
            certificadosObject[e.id] = e;
        });

        db.update(certificadosObject).then((e) => {
            this.setState({ loading: false })
            this.props.history.push("/admin/certificat")
        });
    }

    uploadFileAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var storage = app.storage();
                var imageExtention = imageName.splice(imageName.length - 1);
                var fileRef = `certificados/${uuid() + "." + imageExtention}`;
                var storageRef = storage.ref(fileRef);

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(url => {
                        resolve({ url, fileRef });
                    });
                })
            }
        })
    }

    render() {
        return (<div className="multiple-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">LOIS</h1>
            </div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Lois à Modifier</h1>
                <div className="cc__styledtable-container">
                    <div className="content">
                        <p className="styled__label-pink">Nom</p>
                        <p className="styled__label-pink">Image</p>
                    </div>
                    {this.state.selected.map((e, i) => {
                        const buttonRef = React.createRef();
                        return <>
                            <div className="cc__division"></div>
                            <div className="content">
                                <input type="text" name="" id="" value={e.title} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, title: el.target.value } : e)
                                })} />
                                <input className="hidden" type="file" name="" id="" ref={buttonRef} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, imgURL: el.target.files[0] } : e)
                                })} />
                                <input className="button__defult" type="button" value="(télécharger l'image)" onClick={() => buttonRef.current.click()} />
                                {typeof e.imgURL === "string" ?
                                    <a href={e.imgURL} target="_blank" rel="noopener noreferrer">
                                        <img src={process.env.PUBLIC_URL + "/icons/link.svg"} alt="" style={{ width: 20 }} />
                                    </a>
                                    :
                                    <p className="styled__label-pink">
                                        {typeof e.imgURL.name !== "undefined" && e.imgURL.name}
                                        {typeof e.imgURL.size !== "undefined" && " (" + e.imgURL.size / 1000 + "kb)"}
                                    </p>
                                }
                            </div>
                        </>
                    })}
                    <div className="cc__division"></div>
                </div>
                <div className="cc__button-2">
                    <button className="cc__button-1 accept" onClick={this.cancelUpload}>Cancel</button>
                    <button className="cc__button-1 cancel" onClick={() => this.handleUpload()}>Eenregistrer les modifications</button>
                </div>
            </div>
        </div >);
    }
}

export default MultipleEdit;