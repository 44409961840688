import React, { Component } from 'react';
import ModificationOptions from '../../../Components/ModifcationOptions/ModificationOptions';
import StyleEditable from '../../../Components/StyledEditable/StyleEditable';
import "./Main.scss"
import app from "../../../firebaseConfig";
import StyledCreate from '../../../Components/Create/StyledCreate';


class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLogo: "",
            hasChanged: false,
            leyesList: []
        }
    }

    componentDidMount() {
        const promotores = app.database().ref("leyes/");
        const leyesList = []

        promotores.once("value").then(ss => {
            ss.forEach(child => {
                leyesList.push({
                    link: child.val().link,
                    nombre: child.val().nombre,
                    description: child.val().description,
                    logo: child.val().logo,
                    id: child.val().id,
                    checked: false
                });
            })
        }).then(() => {
            this.setState({ leyesList })
        });
    }

    componentDidUpdate(update) {
    }

    handleChange = (name, value) => {
        console.log(name, value);
    }

    handleCheckboxChange = (evt) => {
        this.setState({
            leyesList: this.state.leyesList.map(e => {
                // console(e.id);
                if (evt.target.name === e.id) {
                    return {
                        ...e,
                        checked: evt.target.checked
                    }
                }
                // console.log(e);
                return e;
            })
        }, () => {
            // console.log(this.state.leyesList);
            // this.props.setSelected(this.state.leyesList.map(e => e.checked && e.id))
        });
    }

    uploadFile = async () => {
        const dataBase = app.database();
        console.log(dataBase);
    }

    deleteItems = () => {
        // console.log(this.props.history)
        const db = app.database().ref("leyes/");
        Promise.all(
            this.state.leyesList.map(e => {
                if (e.checked) {
                    // console.log("Eliminado")
                    db.child(e.id).remove();
                }
            })
        ).then(() => {
            this.props.history.push("/admin/ley");
        })
    }

    editItems = () => {
        var items = this.state.leyesList.filter(e => e.checked);
        if (items.length > 0) {
            this.props.setSelected(items);
            this.props.history.push("/admin/ley/edit");
        }
    }

    render() {
        return (<div className="cc__main-promotor">
            <section className="cc__fullsection">
                <div className="main_title">
                    <h1 className="styled__h1-brown margin-40px">Lois</h1>
                    {<ModificationOptions
                        activate={this.state.leyesList.filter(e => e.checked === true).length > 0}
                        crear="/admin/ley/create"
                        eliminar={this.deleteItems}
                        editar={this.editItems} />}
                </div>
            </section>

            <section className="cc__middle">
                <div className="cc__left">
                    <div className="cc__section">
                        <div className="cc__row">
                            <p className="styled__p-gray">Lois sélectionnés ({this.state.leyesList.filter(e => e.checked === true).length})</p>
                        </div>
                    </div>
                    <div className="cc__pages-list">
                        {this.state.leyesList.map(e => {
                            return <StyleEditable
                                link={"/admin/ley/edit/"}
                                title={e.nombre}
                                img={e.logo}
                                id={e.id}
                                handleChange={this.handleCheckboxChange}
                                checked={e.checked}
                            />
                        })}
                        <StyledCreate
                            nombre="Nouveau promoteur"
                            link="/admin/ley/create"
                            width={300}
                        />

                    </div>
                </div>
            </section>
        </div>);

    }
}

export default Main;