import React from "react";
import "./Contact.scss";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import MapBox from "../../Components/Mapa/MapBox";
import { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useEffect } from "react";
import app from "../../Admin/firebaseConfig";
import GMap from "../../Components/GMap/GMap";
import MetaTags from "react-meta-tags";
const validarEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email === "" ? null : re.test(String(email).toLowerCase());
};

const Contact = (props) => {
  const [isValid, setIsEmail] = useState(null);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [generalInfo, setGeneralInfo] = useState({});
  const [isLoaded, setLoaded] = useState(false);

  const [header, setHeader] = useState({ img: "", titulo: "" })

  useEffect(() => {
    setLoaded(false);
    window.scrollTo(0, 0);
    app.database().ref("generalInfo/").once("value").then(ss => {
      if (ss.val()) {
        setGeneralInfo(ss.val())
        return true;
      }
      return false;
    }).then(loaded => {
      //Si los archivos de la base de datos cargaron correcatamente
      app.database().ref("contact/").once('value').then(ss => {
        if (ss.val()) {
          setHeader(ss.val());
        }
      }).then(() => {
        setLoaded(true);
      })
    })
  }, []);

  const currentEmailState = () => {
    return isValid ? "input__correct" : "input__incorrect";
  };

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      backgroundColor: "#eb0271",
      '& .message': {
        backgroundColor: "#eb0271",
        color: "white"
      }
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openT, setOpenT] = useState(false);
  const [toastMessage, setTMessage] = useState("");
  const [toastMessageS, setTS] = useState("warning");
  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const checkForm = () => {
    //handleClickT
    // handleToggle();
    if (name == "") {
      setTMessage("Vous devez entrer votre nom");
      setTS("warning");
      handleClickT();
      return;
    }
    if (phone == "") {
      setTMessage("Vous devez entrer votre téléphone");
      setTS("warning");
      handleClickT();
      return;
    }
    if (isNaN(phone)) {
      setTMessage("le numéro entré n'est pas un numéro de téléphone");
      setTS("warning");
      handleClickT();
      return;
    }

    if (email == "") {
      setTMessage("Vous devez saisir un email valide");
      setTS("warning");
      handleClickT();
      return;
    }
    if (currentEmailState() == "input__incorrect") {
      setTMessage("Vous devez saisir un email valide");
      setTS("error");
      handleClickT();
      return;
    }
    if (subject == "") {
      setTMessage("Vous devez saisir un message");
      setTS("warning");
      handleClickT();
      return;
    }
    sendPostMail();
  };
  const sendPostMail = async () => {
    handleToggle();
    const contactUrl = "https://conseil-commercialisation.web.app/api/mail/contact";
    // const contactUrl = "http://localhost:5000/api/mail/contact";
    const postData = {
      name: name,
      email: email,
      phone: phone,
      subject: subject,
    };
    let result = await axios.post(contactUrl, postData);
    console.log(result.data);
    if (result.data.status == "ok") {
      handleClose();
      setTMessage("Votre message a été envoyé");
      setTS("success");
      handleClickT();
      resetForm();
    } else {
      handleClose();
      setTMessage(
        "Une erreur s'est produite lors du traitement de votre message"
      );
      setTS("error");
      handleClickT();
    }
  };

  const resetForm = () => {
    setEmail("");
    setName("");
    setPhone("");
    setSubject("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickT = () => {
    setOpenT(true);
  };

  const handleCloseT = (event, reason) => {
    if (reason === "clickaway") {
      return;
      setOpenT(false);
    }
    setOpenT(false);
  };

  return (
    <>{isLoaded &&
      <div className="contact__container">
        <div className="wrapper">
          <MetaTags>
            <title>Formulaire de contact | Conseil & Commercialisation</title>
            <meta name="description" content="Conseil & Commercialisation ● Spécialiste du neuf pour la résidence principale et l’investissement locatif ● Appartements & maisons ● Paris & Ile-de-France ● Contactez-nous ►" />
            <meta property="og:title" content="Formulaire de contact | Conseil & Commercialisation" />
          </MetaTags>
        </div>
        <BannerSlide title={header.titulo} img={header.img} />
        <div className="contact__wrapper">
          <div className="styled-bg"></div>
          <section className="form__container">
            <h2
              className="title-h2-brown border-bot-pink-4 "
              style={{ textAlign: "left" }}>
              Nos spécialistes de la promotion immobilère sont à votre écoute
            </h2>
            <p style={{ textAlign: "left", color: "#5c5c5c" }}>
              Vous souhaitez des informations complémentaires sur l’un de nos
              programmes ? Vous souhaitez vendre un terrain ? Vous avez une
              question ?
          </p>

            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                //this.handleEmailSending(e);
              }}>
              <label>
                <p style={{ color: "#5c5c5c" }}>Nom</p>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label>
                <p style={{ color: "#5c5c5c" }}>N° Téléphone</p>
                <input
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </label>
              <label>
                <p style={{ color: "#5c5c5c" }}>Email</p>
                <div className={isValid === null ? "" : currentEmailState()}>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setIsEmail(validarEmail(e.target.value));
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </label>
              <label>
                <p style={{ color: "#5c5c5c" }}>Sujet</p>
                <textarea
                  type="text"
                  name="commet"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}></textarea>
              </label>
              <input type="hidden" name="crs" />
              <div className="form__button">
                {/*<input*/}
                {/*  className="cancel"*/}
                {/*  type="submit"*/}
                {/*  style={{ fontWeight: 700 }}*/}
                {/*  value="Cancel"*/}
                {/*/>*/}
                <input
                  className="send"
                  type="button"
                  style={{ fontWeight: 700 }}
                  value="Envoyer"
                  onClick={checkForm}
                />
              </div>
            </form>
          </section>
          <div className="map_container">
            <div className="contact_data-container">
              <div className="contact_data-item">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Group 49.svg"}
                  alt="localisation"
                  title="localisation"
                />
                <div className="contanct_data-info">
                  <p>
                    <b>Adresse</b>
                  </p>
                  <a href="https://www.google.com/maps/place/9+Rue+Neuve+Saint-Germain,+92100+Boulogne-Billancourt/data=!4m2!3m1!1s0x47e67aedf7199fb9:0x85d9f0898ed86dfb?sa=X&ved=2ahUKEwjMreCwvov9AhUeQaQEHXQqAp8Q8gF6BAgIEAI">
                    {
                      generalInfo.adresse.split(",").map(e => {
                        return <p>{e}</p>
                      })
                    }
                  </a>
                </div>
              </div>
              <div className="contact_data-item">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Group 61.svg"}
                  alt="enveloppe"
                  title="enveloppe"
                />
                <div className="contanct_data-info">
                  <p>
                    <b>Email</b>
                  </p>
                  <a href={`mailto:${generalInfo.email}`}>{generalInfo.email}</a>
                </div>
              </div>
              <div className="contact_data-item">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Group 48.svg"}
                  alt="telephone"
                  title="telephone"
                />
                <div className="contanct_data-info">
                  <p>
                    <b>Téléphone</b>
                  </p>
                  <p>{generalInfo.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={openT}
          autoHideDuration={6000}
          onClose={handleCloseT}
        >
          {/* <Alert onClose={handleCloseT} className={classes}>
          {toastMessage}
        </Alert> */}
          <SnackbarContent style={{ backgroundColor: "#eb0271", display: 'flex', flexDirection: 'row', justifyContent: 'center' }} message={toastMessage} onClose={handleCloseT} />
        </Snackbar>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    }</>);
};

export default Contact;
