import React, { Component } from 'react';
import ModificationOptions from '../../../Components/ModifcationOptions/ModificationOptions';
import StyleEditable from '../../../Components/StyledEditable/StyleEditable';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import SideBarOptions from '../../../Components/SideBarOptions/SideBarOptions';
import "./Main.scss"
import app from "../../../firebaseConfig";
import StyledCreate from '../../../Components/Create/StyledCreate';


class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLogo: "",
            hasChanged: false,
            promotorList: [],
            promotorOrder: Array(6).fill(" "),
            loading: false
        }

        this.orderName = ['Premier', 'Deuxième', 'Troisième', 'Quatrième', 'Cinquième', 'Sixième']
    }

    componentDidMount() {
        const promotores = app.database().ref("promotores/");
        const promotorList = []

        promotores.once("value").then(ss => {
            ss.forEach(child => {
                if (child.val().logo && child.val().id) {
                    promotorList.push({
                        link: child.val().link,
                        nombre: child.val().nombre,
                        description: child.val().description,
                        logo: child.val().logo,
                        id: child.val().id,
                        checked: false
                    });
                }
            })
            promotorList.push({ id: "-", nombre: "-" })
        }).then(() => {
            console.log(promotorList)
            this.setState({promotorList})
        });

        const promoteurOrder = app.database().ref("promoteurOrder/");

        promoteurOrder.once("value").then(ss => {
            this.setState({ promotorOrder: ss.val() });
        })
    }

    componentDidUpdate(update) {
    }

    handleChange = (name, value) => {
        console.log(name, value);
    }

    handleCheckboxChange = (evt) => {
        this.setState({
            promotorList: this.state.promotorList.map(e => {
                // console(e.id);
                if (evt.target.name === e.id) {
                    return {
                        ...e,
                        checked: evt.target.checked
                    }
                }
                // console.log(e);
                return e;
            })
        }, () => {
            this.state.promotorList.map(e => console.log(e.checked))
            // console.log(this.state.promotorList);
            // this.props.setSelected(this.state.promotorList.map(e => e.checked && e.id))
        });
    }

    uploadFile = async () => {
        // const dataBase = app.database();
    }

    deleteItems = () => {
        const db = app.database().ref("promotores/");
        Promise.all(
            this.state.promotorList.map(e => {
                if (e.checked) {
                    // console.log("Eliminado")
                    db.child(e.id).remove();
                }
            })
        ).then(() => {
            this.props.history.push("/admin/promotor");
        })
    }

    uploadPromoteurOrder = () => {
        console.log(this.state.promotorOrder)
        const promoteurOrder = app.database().ref("promoteurOrder/");
        this.setState({ loading: true });
        promoteurOrder.set(this.state.promotorOrder).then(() => {
            this.setState({ loading: false });
        })
    }

    handleEdit = () => {
        var items = this.state.promotorList.filter(e => e.checked);
        if (items.length > 0) {
            this.props.setSelected(items);
            this.props.history.push("/admin/promotor/edit");
        }
    }


    render() {
        return (<div className="cc__main-promotor">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <section className="cc__fullsection">
                <div className="main_title">
                    <h1 className="styled__h1-brown margin-40px">Promoteurs</h1>
                    {<ModificationOptions
                        eliminar={this.deleteItems}
                        activate={this.state.promotorList.filter(e => e.checked === true).length > 0}
                        editar={this.handleEdit}
                    />}
                </div>
            </section>

            <section className="cc__middle">
                <div className="cc__left">
                    <div className="cc__section">
                        <div className="cc__row">
                            <p className="styled__p-gray">Promoteurs sélectionnés ({this.state.promotorList.filter(e => e.checked === true).length})</p>
                        </div>
                    </div>
                    <div className="cc__pages-list">

                        {this.state.promotorList.map( (e, i) => {
                            return e.id !== "-" &&
                                <StyleEditable
                                    key={i}
                                    link={"/admin/promotor/edit/"}
                                    title={e.nombre}
                                    img={e.logo}
                                    id={e.id}
                                    handleChange={this.handleCheckboxChange}
                                    checked={e.checked}
                                />
                        })}
                        <StyledCreate
                            nombre="Nouveau promoteur"
                            link="/admin/promotor/create"
                            width={300}
                        />
                    </div>
                </div>
                {/* <ModificationOptions /> */}
                <SideBarOptions title={<h3 className="styled__h3-gray">Ordre du pr<u>omoteur</u></h3>}>
                    <h1 className="styled__label-gray-18px style__align-left">Contact</h1>
                    {
                        this.state.promotorOrder.map((e, i) => {
                            return <StyledInputs
                                key={i}
                                value={e}
                                type="select"
                                options={this.state.promotorList.map((e, i) => { return { valor: e.id, name: e.nombre, key: i } })}
                                title={this.orderName[i] + " promoteur"}
                                name="firstLogo"
                                handleChange={(name, value) => {
                                    this.setState({
                                        promotorOrder: this.state.promotorOrder.map((el, index) => {
                                            return i === index ? value : el
                                        })
                                    })
                                }}
                            />
                        })
                    }
                    <button className="cc__button cancel" onClick={() => this.uploadPromoteurOrder()}>Envoyer</button>
                </SideBarOptions>
            </section>
        </div>
        );
    }
}

export default Main;