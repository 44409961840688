import React, { Component } from "react";
import "./Simulation.scss";

import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import MuiAlert from "@material-ui/lab/Alert";
class Simulation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [
        {
          end: false,
          type: "Situation maritale",
          input: "ask",
          question: "Quelle est votre situation maritale ?",
          response: [
            "Célibataire",
            "Pacsé(e) / Marié(e)",
            "Divorcé(e) / Séparé(e)",
            "Veuf / Veuve",
            "Union libre",
          ],
        },
        {
          end: false,
          question: "Combien d’enfants avez-vous à charge ?",
          input: "ask",
          type: "N° Enfants",
          response: [0, 1, 2, 3, 4],
        },
        {
          end: false,
          type: "Revenu mensuel",
          question: "Quel est le revenu mensuel net du foyer ? (avant impôts)",
          input: "ask",
          response: [
            "- 2500 €",
            "Entre 2500 € et 5000 €",
            "Entre 5000 € et 7500 €",
            "+ 7500 €",
          ],
        },
        {
          end: false,
          type: "Capacité d’épargne",
          question: "Quelle est votre capacité d’épargne mensuel ?",
          input: "ask",
          response: ["- 250 €", "Entre 250 € et 500 €", "+ 500 €"],
        },
        {
          end: true,
          description:
            "Afin de recevoir une simulation personnalisée de votre projet, nous vous invitons à laisser vos coordonnés pour qu’un de nos conseillers vous rappelle ",
          type: "Vos données",
          inputs: [
            {
              label: "Nom*",
              type: "text",
              name: "name",
            },
            {
              label: "N° Téléphone",
              type: "number",
              name: "phone",
            },
            {
              label: "Email",
              type: "email",
              name: "email",
            },
            {
              label: "Code Postal",
              type: "number",
              name: "zipcode",
            },
          ],
          checkbox: [
            {
              label: "Je souhaite être rappelé(e)",
              name: "access-phone",
            },
            {
              label:
                "Oui, je souhaite être alerté(e) des opportunités immobilières exclusives de Conseil & Commercialisation",
              name: "access-email",
            },
          ],
        },
      ],
      answerSelection: {
        0: "",
        1: "",
        2: "",
        3: "",
      },
      data: {
        name: "",
        phone: "",
        email: "",
        zipcode: "",
        call: false,
        emailAllow: false,
      },
      currentQuestion: 0,
      open: false,
      toastM: "",
      toastMS: "warning",
    };
  }

  handleChange = (dir) => {
    const setQuestion = this.state.currentQuestion + dir;
    if (dir === -1 && this.state.currentQuestion >= 0) {
      this.setState({ currentQuestion: setQuestion });
    }
    if (
      dir === 1 &&
      this.state.currentQuestion < this.state.questions.length - 1
    ) {
      this.setState({ currentQuestion: setQuestion });
    }
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  setSelected = (currentQuestion, value) => {
    // console.log(currentQuestion, value);
    this.setState({
      answerSelection: {
        ...this.state.answerSelection,
        [currentQuestion]: value,
      },
    });
  };

  options = () => {
    return (
      <div className="questions">
        <div className="title-h2-pink-helvetica">
          {this.state.questions[this.state.currentQuestion].question}
        </div>
        <div className="options_container">
          {this.state.questions[this.state.currentQuestion].response.map(
            (e, i) => {
              return (
                <button
                    key={i}
                    className={
                    this.state.answerSelection[this.state.currentQuestion] === i
                      ? "option selected"
                      : "option"
                  }
                  onClick={() => {
                    console.log(
                      this.state.answerSelection[this.state.currentQuestion]
                    );
                    this.setSelected(this.state.currentQuestion, i);
                  }}>
                  {e}
                </button>
              );
            }
          )}
        </div>
        <div className="form__button" style={{ width: "90%" }}>
          {this.state.currentQuestion !== 0 ? (
            <button className="cancel" onClick={() => this.handleChange(-1)}>
              Étape précédente
            </button>
          ) : (
              ""
            )}
          <button className="send" onClick={() => this.handleChange(1)}>
            Étape suivante
          </button>
        </div>
      </div>
    );
  };

  contact = () => {
    return (
      <div className="questions">
        <p>{this.state.questions[this.state.currentQuestion].description}</p>
        <form action="">
          {this.state.questions[this.state.currentQuestion].inputs.map(
            (e, i) => {
              return (
                <label className="label-input">
                  <p>{e.label}</p>
                  <input
                    type="text"
                    value={this.state.data[e.name]}
                    name={e.name}
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </label>
              );
            }
          )}
          {this.state.questions[this.state.currentQuestion].checkbox.map(
            (e, i) => {
              return (
                <label className={"label-checkbox check-" + i}>
                  <input type="checkbox" name={e.name} id="" />
                  <p>{e.label}</p>
                </label>
              );
            }
          )}
          <div className="form__button  end_form">
            <button className="cancel" onClick={() => this.handleChange(-1)}>
              Étape précédente
            </button>
            <button
              className="send"
              type="submit"
              value="submit"
              onClick={(e) => this.handleSubmit(e)}>
              Envoyer
            </button>
          </div>
        </form>
      </div>
    );
  };

  //TOAST CONFIG

  useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);

  handleClick = () => {
    this.setState({ open: false })
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false })
  };

  //--------------
  handleSubmit = async (e) => {
    e.preventDefault();
    var inputs = e.currentTarget.parentNode.parentNode.querySelectorAll(
      'input[type="text"]'
    );
    let fieldsOk = true;
    for (let i = 0; i < inputs.length; i++) {
      const inputText = inputs[i];
      if (inputText.value === "") {
        inputText.focus();
        inputText.style.borderColor = "red";
        fieldsOk = false;
        break;
      } else {
        inputText.style.borderColor = "#c9c5c3";
      }
    }
    if (fieldsOk === true) {
      /* if (this.state.data.call == true && this.state.data.emailAllow == true) { */
      if (
        (this.state.answerSelection[0] !== "" &&
          this.state.answerSelection[1] !== "" &&
          this.state.answerSelection[2] !== "") ||
        this.state.answerSelection[3] !== ""
      ) {
        //ALL Correct proceed to send mail
        const contactUrl = "https://conseil-commercialisation.web.app/api/mail/simulation";
        const personalInfo = this.state.data;
        const postData = {
          name: personalInfo.name,
          email: personalInfo.email,
          phone: personalInfo.phone,
          zipcode: personalInfo.zipcode,
          call: personalInfo.call,
          emailC: personalInfo.emailAllow,
          question1: this.state.questions[0].response[this.state.answerSelection[0]],
          question2: this.state.questions[1].response[this.state.answerSelection[1]],
          question3: this.state.questions[2].response[this.state.answerSelection[2]],
          question4: this.state.questions[3].response[this.state.answerSelection[3]],
        };
        console.log(postData);
        try {


          let serverResponse = await axios.post(contactUrl, postData);

          if (serverResponse.data.status == "ok") {
            this.setState({
              toastM: "Votre message a été envoyé",
              toastMS: "success",
            }, () => {
              this.setState({ open: true })
              this.resetForm();
              this.setState({
                currentQuestion: 0
              })
              this.props.setvisual();
            });
          } else {
            this.setState({
              toastM: "Une erreur s'est produite lors du traitement de votre message",
              toastMS: "error",
            }, () => {
              this.setState({ open: true })
            });
          }
        } catch (error) {
          this.setState({
            toastM: "Une erreur s'est produite lors du traitement de votre message",
            toastMS: "error",
          }, () => {
            this.setState({ open: true })
          });
        }
      } else {
        //There are questions without response
        this.setState({
          toastM: "Vous devez répondre à toutes les questions",
          toastMS: "warning",
        }, () => {
          this.setState({ open: true })
        });
      }
    } else {
      //Some textfields are empty
      this.setState({
        toastM: "Vous devez saisir toutes vos données",
        toastMS: "warning",
      }, () => {
        this.setState({ open: true })
      });
    }
  };
  resetForm = () => {
    this.setState({
      answerSelection: {
        0: "",
        1: "",
        2: "",
        3: "",
      },
      data: {
        name: "",
        phone: "",
        email: "",
        zipcode: "",
        call: false,
        emailAllow: false,
      },
      currentQuestion: 0,
    });
  }

  render() {
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return (
      <div
        className={
          this.props.isOpen
            ? "simulation__container open_simulation"
            : "simulation__container close_simulation"
        }>
        <div
          className="close-simulation"
          onClick={() => {
            this.props.setvisual();
            this.setState({ currentQuestion: 0 });
          }}></div>
        <div className="simulation__wrapper" ba>
          <img
            className="simulation-bg"
            src={process.env.PUBLIC_URL + "/img/ampersand.svg"}
            alt="esperluette icone"
            title="esperluette icone"
          />
          <div className="simulation__menu">
            <img
              src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"}
              alt="logo"
              title="logo"
              style={{ width: 54 }}
              className="logo"
            />
            <div className="optiones_menu">
              <div className="option-menu">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Path 315.svg"}
                  alt="check"
                  title="check"
                  className="logo"
                />
                <p style={{ fontSize: "10px", margin: 0, color: "#eb0271" }}>
                  Gratuit et sans
                </p>
                <p style={{ fontSize: "10px", margin: 0 }}>engagement</p>
              </div>
              <div className="option-menu">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Group 131.svg"}
                  alt="enveloppe"
                  title="enveloppe"
                  className="logo"
                />
                <p style={{ fontSize: "10px", margin: 0, color: "#eb0271" }}>
                  Simulation personnalisée
                </p>
                <p style={{ fontSize: "10px", margin: 0 }}>par mail</p>
              </div>
              <div className="option-menu">
                <div className="x-close" onClick={() => this.props.setvisual()}></div>
              </div>
            </div>
          </div>
          {
            //////////////////////////////////////////////////OPCIONES
            this.state.questions[this.state.currentQuestion].end
              ? this.contact()
              : this.options()
          }
          <div className="bottom_line">
            <div className="simulation_status">
              {this.state.questions.map((e, i) => {
                return (
                  <div
                    className={
                      i <= this.state.currentQuestion
                        ? "simulator active"
                        : "simulator"
                    }>
                    <div className="line"></div>
                    <div className="bullet_container">
                      <div className="bullet"></div>
                      <p>{e.type}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}>
          {/* <Alert onClose={this.handleClose} severity={this.state.toastMS}>
            {this.state.toastM}
          </Alert> */}
          <SnackbarContent style={{ backgroundColor: "#eb0271", display: 'flex', flexDirection: 'row', justifyContent: 'center' }} message={this.state.toastM} onClose={this.handleClose} /></Snackbar>
      </div>
    );
  }
}

export default Simulation;
