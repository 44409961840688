import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from "./AuthContext";
import { useContext } from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const { currentUser } = useContext(AuthContext);
    return (<Route
        {...rest}
        render={props => {
            return !!currentUser ?
                (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
        }}
    ></Route >);
}

export default PrivateRoute;