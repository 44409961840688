import React, { Component } from 'react';
import './SlideShow.scss'
import './flickity.min.css'
import Flickity from 'react-flickity-component'


class SlideShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgCarousel: [
                {
                    image: '/img/places/manon_Photo - 17.jpg',
                    titulo: 'Les Villas de Manon',
                    des: 'Meaux ',
                    zip: 77,
                    size: 'img-full-width'

                }, {
                    image: '/img/places/2856-C&C_VERRIERES_RUE_HD.jpg',
                    titulo: 'Résidence du Clocher ',
                    des: 'Verrières-le-Buisson',
                    zip: 77,
                    size: 'img-full-width'
                }, {
                    image: '/img/places/Pers-Cour interieure.png',
                    titulo: 'Residence Magnolias',
                    des: 'Villiers sur Marne',
                    zip: 77,
                    size: 'img-full-width'
                }, {
                    image: '/img/places/Exterieur Jardin.jpg',
                    titulo: 'Villa Cerisaie ',
                    des: 'Châtenay-Malabry',
                    zip: 77,
                    size: 'img-full-height'
                },
            ],
            currentImage: 0,
            openModal: false
        }
    }
    componentDidMount = () => {
        // You can register events in componentDidMount hook
        this.flkty.on('staticClick', (event, pointer, cellElement, cellIndex) => {
            if (typeof cellIndex == 'number') {
                // $gallery.flickity('select', cellIndex);
                this.setState({ currentImage: cellIndex });
                console.log(cellIndex);
            }
        })
        this.flkty.on('')
    }

    handleRightButton = () => {
        this.flkty.next();
    }

    handleLeftButton = () => {
        this.flkty.previous();
    }


    render() {
        return (<div className={this.props.modal ? "slide_show___container_modal" : "slide_show___container"}>
            <div className="ss__selectedimg" onClick={this.props.openModal ? this.props.openModal : false}>
                <div className="ss__selectedimg-container">

                    {/* <img src={process.env.PUBLIC_URL + this.props.folder + '/' + this.props.imgs[this.state.currentImage]} alt='crrouse-1' /> */}
                    {this.props.imgs[this.state.currentImage].type === 'Image'
                     ? <img src={this.props.imgs[this.state.currentImage].imgURL} alt="" />
                     : this.props.imgs[this.state.currentImage].type === 'Augmented Reality' ?
                        <a href={this.props.imgs[this.state.currentImage].link} target="_blank" rel="noopener noreferrer" >
                          <img src={this.props.imgs[this.state.currentImage].imgURL} alt="" />
                        </a>
                        :
                        <iframe
                          title="slideshow_title"
                          width="560"
                          height="315"
                          src={"https://www.youtube.com/embed/" + this.props.imgs[this.state.currentImage].link}
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen>
                        </iframe>
                    }
                    {/* <div className="close-modal" onClick={() => this.setState({ openModal: false })}></div> */}
                    {this.props.modal && <div className="close-modal" onClick={() => this.props.modal(false)}></div>}
                </div>
            </div>
            <Flickity
                className={'ss__carrousel'} // default ''
                elementType={'div'} // default 'div'
                options={{
                    initialIndex: this.state.currentImage,
                    wrapAround: true,
                }} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false

                // static // default false
                flickityRef={c => this.flkty = c}>
                {/* <div className="ss__carrousel"> */}
                {
                    this.props.imgs.map((e, i) => {
                        console.log(e.type);
                        console.log(this.props);
                        return <div className="ss__image-container" key={"imagecontainer-" + i} >
                            {e.type === 'Image' ?
                                <img src={e.imgURL} alt={e.titulo} />
                                :
                                <>
                                    {e.type === "Augmented Reality" ?
                                        <a href={e.link} target="_blank" rel="noopener noreferrer" >
                                            <img className="vid" src={e.imgURL} alt="video" />
                                            <p className="video-icon" style={{ color: "#ffffff", fontSize: 16 }}>VR</p>
                                        </a>
                                        :
                                        <>
                                            <img className="vid" src={"https://img.youtube.com/vi/" + e.link + "/default.jpg"} alt="asd_imge" ></img>
                                            <img className="video-icon" src={process.env.PUBLIC_URL + '/icons/Group 679.svg'} alt="video-asd"></img>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    })
                }
                {/* </div> */}
            </Flickity>
        </div>);
    }
}

export default SlideShow;
