import React from 'react';
import './CookieMessage.scss';
import { Link } from 'react-router-dom';

const CookieMessage = ({ setCookie }) => {
    return (<div className="cookie-container">
        <p className="">Ce site utilise des cookies. En cliquant sur accepter ou continuer à naviguer sur le site, vous acceptez notre utilisation des cookies. <Link to="/cookies" className="p-link">Plus d'informations ici.</Link></p>
        <div className="cookie__btn-conatiner">
            <button className="cookie__cancel" onClick={() => setCookie(false)}>Annuler</button>
            <button className="cookie__acept" onClick={() => setCookie(true)}>Accepter</button>
        </div>
    </div>);
}

export default CookieMessage;