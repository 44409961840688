import React from 'react';
import './Thanks.scss';
import BannerSlide from '../../Components/BannerSlide/BannerSlide';

const Thanks = (props) => {
    return (<div className="thanks__container">
        <BannerSlide
            title="Inscription Confirmée"
            heigh="60vh"
        />
        {/* <h1 className="title-h1-brown">Inscription Confirmée</h1> */}
        <div className="thanks__text">
            <p className="decoration-p-brown">Votre inscription à notre liste a été confirmée.</p>
            <p className="decoration-p-brown">Merci de vous être abonné!</p>
        </div>
    </div>);
}

export default Thanks;