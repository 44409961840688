import React, { Component } from 'react';
import app from '../../../firebaseConfig';
import "./MultipleEdit.scss";

class MultipleEdit extends Component {

    constructor(props) {
        super(props);
        this.props.selected ? (this.props.selected.length <= 0 && this.props.history.push("/admin")) : this.props.history.push("/admin")
        this.state = {
            selected: props.selected,
            promotorList: [],
            loading: false
        };
    }


    componentDidMount() {
        const promotores = app.database().ref("promotores/")
        const promotoresList = []
        promotores.once("value").then(ss => {
            ss.forEach(child => {
                console.log(child.val())
                promotoresList.push(child.val())
            })
        }).then(() => {
            if (promotoresList)
                this.setState({ promotorList: promotoresList })
        });
    }

    cancelUpload = () => {
        this.props.history.push("/admin");
    }

    handleUpload = () => {
        this.setState({ loading: true })
        const db = app.database().ref("projects/");
        Promise.all(
            this.state.selected.forEach(e => {
                if (e.checked) delete e.checked
                db.child(e.id).set(e);
            })
        ).then(() => {
            this.setState({ loading: false })
            this.props.history.push("/admin");
        })
    }

    render() {
        return (<div className="multiple-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">PROGRAMMES</h1>
            </div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Programmes</h1>
                <div className="cc__styledtable-container">
                    <div className="content">
                        <p className="styled__label-pink">Nom</p>
                        <p className="styled__label-pink">Adresse bureau des ventes</p>
                        <p className="styled__label-pink">Adresse de projet</p>
                        <p className="styled__label-pink">Téléphone</p>
                        <p className="styled__label-pink">Promoteur</p>
                    </div>
                    {this.state.selected.map((e, i) => {
                        return <>
                            <div className="cc__division"></div>
                            <div className="content">
                                <input type="text" name="" id="" value={e.title} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, title: el.target.value } : e)
                                })} />
                                <input type="text" name="" id="" value={e.dirProject.name} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, dirProject: { ...e.dirProject, name: el.target.value } } : e)
                                })} />
                                <input type="text" name="" id="" value={e.dirSell.name} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, dirSell: { ...e.dirSell, name: el.target.value } } : e)
                                })} />

                                <input type="text" name="" id="" value={e.phone} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, phone: el.target.value } : e)
                                })} />
                                <select name="" id="" onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, promotor: el.target.value } : e)
                                })} >
                                    {
                                        this.state.promotorList.map(el => {
                                            return el.id === e.promotor ? <option value={el.id} selected>{el.nombre}</option> : <option value={el.id}>{el.nombre}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </>
                    })}
                    <div className="cc__division"></div>
                </div>
                <div className="cc__button-2">
                    <button className="cc__button-1 accept" onClick={this.cancelUpload}>Cancel</button>
                    <button className="cc__button-1 cancel" onClick={() => this.handleUpload()}>Eenregistrer les modifications</button>
                </div>
            </div>
        </div>);
    }
}

export default MultipleEdit;