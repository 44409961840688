import React from 'react';
import "./SideBarOptions.scss"
const SideBarOptions = (props) => {
    return (<div className="cc__sidebar">
        {props.title && props.title}
        <div className="cc__sidebar-container">
            {props.children}
        </div>
    </div>);
}

export default SideBarOptions;