import React, {Component} from "react";
import "./Programs.scss";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import SearchBar from "../../Components/SearchBar/SearchBar";

import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import app from "../../Admin/firebaseConfig";
import MetaTags from "react-meta-tags";

class Programs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchImg: [],
            filteredImages: [],
            allPrograms: [],
            rows: 0,
            loads: props.loadPrograms ? props.loadPrograms : 1,
            toastM: "",
            toastMS: "warning",
            header: {titulo: '', img: ''},
            loaded: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        app.database().ref('programmes/').once('value').then(ss => {
            this.setState({header: ss.val()})
        })
        const projects = app.database().ref("projects/");
        const projetList = [];
        this.loadingProjects(projects, projetList);

        app.database().ref("generalInfo/").once('value').then(ss => {
            this.setState({generalInfo: ss.val()}, () => {
                this.setState({loaded: true})
            })
        });
    }

    shuffleArray = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    loadingProjects = (projects, projetList) => {
        projects.once("value").then(ss => {
            ss.forEach(child => {
                //console.log(child.val())
                projetList.push({
                    ...child.val(),
                    img: child.val().imgs ? child.val().imgs[0] : null
                });
            })
        }).then(() => {
            this.shuffleArray(projetList)
            console.log(projetList.length)
            console.log(Math.ceil(projetList.length / 7))
            this.setState({
                allPrograms: projetList,
                filteredImages: projetList,
                rows: Math.ceil(projetList.length / 7)
            }, () => {
                this.setState({loads: this.props.loadPrograms});
                if (this.props.match.params.type) {
                    var searchFilter = this.state.filteredImages.filter(e => {

                        var budgetRequest = this.props.match.params.budget.split('.');
                        var quantityRequest = this.props.match.params.quant; //Cambiar por QuantityRequest
                        var villeRequest = this.props.match.params.ville;
                        var typeRequest = this.props.match.params.type;

                        // console.log(budgetRequest.length, quantityRequest, villeRequest);
                        if (budgetRequest.length > 1 || quantityRequest.length !== "0" || villeRequest !== "0" || typeRequest !== "0") {
                            //Retorna el rango de los precios de casas
                            var precioInicial = parseInt(budgetRequest[0]);
                            var precioFinal = parseInt(budgetRequest[1])
                            var valormenor = precioInicial <= parseInt(e.minPrice);
                            var valorMayor = precioFinal >= parseInt(e.minPrice);
                            //Si valor o mayor retornan verdaedo, el precio se encuentra en el rango pedido por el ususario
                            valormenor = typeof budgetRequest[0] === 'undefined' ? true : valormenor;
                            valorMayor = typeof budgetRequest[1] === 'undefined' ? true : valorMayor;

                            //Check housing
                            var housingQuantityResult = false
                            if (isNaN(quantityRequest)) {
                                housingQuantityResult = true;
                            } else {
                                housingQuantityResult = parseInt(e.housing.pieces[0]) >= parseInt(quantityRequest);
                            }

                            var housingType = true;
                            if (typeRequest !== "0") {
                                // console.log(e.housing.qu);
                                console.log(e.housing.quantity)
                                // housingType = Array.isArray(e.housing.tipo) || e.housing.tipo.toLowerCase().indexOf(typeRequest.toLowerCase()) === 0;
                                if (typeRequest === "2") {
                                    housingType = parseInt(e.housing.quantity[1]) > 0
                                }

                                if (typeRequest === "1") {
                                    housingType = parseInt(e.housing.quantity[0]) > 0
                                }
                            }
                            // housingType = typeRequest !== "0" ? false : true;

                            // //Verificar el tipo
                            var validateVille = e.id.indexOf(villeRequest) === 0;
                            validateVille = villeRequest === "0" ? true : validateVille;

                            if (housingQuantityResult && validateVille && valorMayor && valormenor && housingType) {
                                return e;
                            }
                        }
                    })//.............Termina el filter array

                    console.log(searchFilter);

                    //Retorna todos los programas
                    if (this.props.match.params.ville === "0" && this.props.match.params.type === "0" && this.props.match.params.budget === "0" && this.props.match.params.quant === "0") {
                        this.setState({filteredImages: projetList}, () => {
                            var pages = Math.ceil(this.state.filteredImages.length / 7);
                            this.setState({rows: pages})
                        })
                    } else if (!this.props.match.params) {
                        this.setState({filteredImages: projetList}, () => {
                            var pages = Math.ceil(this.state.filteredImages.length / 7);
                            this.setState({rows: pages})
                        })
                    } else {
                        this.setState({filteredImages: searchFilter}, () => {
                            if (this.state.filteredImages.length <= 0) {
                                this.setState({
                                    toastM: "Il n'y a pas de résultats",
                                    toastMS: "success",
                                }, () => {
                                    this.setOpen(true);
                                });
                            }
                            var pages = Math.ceil(this.state.filteredImages.length / 7);
                            this.setState({rows: pages})
                        })
                    }
                }
            })
        });
    }

    componentDidUpdate(lastProps) {
        if (lastProps !== this.props) {
            const projects = app.database().ref("projects/");
            const projetList = [];

            this.loadingProjects(projects, projetList);
        }
    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));

    setOpen = (what) => {
        this.setState({open: what});
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setOpen(false);
    };

    showMore = () => {
        // console.log(this.state.loads, this.state.filteredImages, this.state.rows)
        if (this.state.loads < 3) {
            this.setState({loads: this.state.loads + 1});
        }
    }

    creatingRows = (images = [], rows = 3) => {
        var columns = [];

        for (let row = 2; row < 2 + rows; row++) {
            columns.push(
                images.map((e, i) =>
                    i % rows == 0 ? (
                        <div key={"row-" + (row + i)} className="cc_programs_masonry-img">
                            <img src={e.thumbnailUrl} alt="imagesita"/>
                        </div>
                    ) : ("")
                )
            );
        }

        return columns;
    };


    quantityToFr = (n) => {
        var f = [];
        for (let i = 0; i < n; i++) {
            f.push("1fr");
        }
        return f.join(" ");
    };

    /*Cambia la cantidad de recuadros cargados*/
    setLoadPageOnMain = (programId) => {
        this.props.setLoadPrograms(this.state.loads);
        this.props.history.push(programId)
    }


    bloqueDePrograma = (el, masonryStyle) => {
        //console.log(el);
        return <div onClick={() => this.setLoadPageOnMain("/programme" + el.slug)}
                    className={"cc_masonry-grid " + masonryStyle}>
            <figure>
                <img src={el.imgs[0].imgURL} alt={el.title} title={el.title}/>
            </figure>
            <div className="card__info-container">
                <h3>{el.title}</h3>
                <p className="card__info__title">
                    <img src={"/icons/Group 427.svg"} alt={el.title} title={el.title} className="location-input"/>
                    <h4>{el.lugar + " ( " + el.zipCode + " )"}</h4>
                </p>
            </div>
        </div>
    }

    gridByChunks = (chuckQuantity) => {

        const rows = [];
        var iii = 0;

        var masonryStyle = ''
        var elementCount = this.state.filteredImages.slice(7 * (this.state.loads - 1), 7 * this.state.loads);
        if (elementCount.length === 3) {
            masonryStyle += "item-3"
        } else if (elementCount.length === 2) {
            masonryStyle += "item-2"
        } else if (elementCount.length === 1) {
            masonryStyle += "item-1"
        } else {
            masonryStyle = "items";
        }

        for (let i = 0; i < chuckQuantity; i++) {
            var msSt = masonryStyle;
            rows.push(
                <div className="cc_masonry-container">
                    {
                        this.state.filteredImages.map((e, ind) => {
                            var mayorA = ind >= (7 * i);
                            var menorA = ind < (7 * i) + 7;
                            if (mayorA && menorA) {
                                if (chuckQuantity > i + 1) {
                                    msSt = "items";
                                }
                                return this.bloqueDePrograma(e, msSt)
                            }
                            return;
                        })
                    }
                </div>
            )
        }


        return rows;
    }

    //Masonry de tres columnas
    //La ultima columna debe tener el tamaño de los demás
    render() {

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        return (
            <>{this.state.loaded &&
            <div
                className="cc_programs_masonry"
                style={{gridTemplateRows: this.quantityToFr(this.state.rows)}}
            >
                <div className="wrapper">
                    <MetaTags>
                        <title>Programmes immobilers neufs | Appartements & maisons | C&C</title>
                        <meta name="description" content="Retrouvez tous nos programmes de logements individuels ou collectifs neufs ● Paris & Ile-de-France ● Appartements & maisons ● Pour plus d’informations, contactez-nous ►" />
                        <meta property="og:title" content="Programmes immobilers neufs | Appartements & maisons | C&C" />
                    </MetaTags>
                </div>
                {/* <h1>{this.state.loads} - {this.state.filteredImages.length} - {this.state.rows}</h1> */}
                <BannerSlide title={this.state.header.titulo} img={this.state.header.img}/>
                <SearchBar villeOptions={this.state.allPrograms}/>
                {this.gridByChunks(this.state.loads)}

                {/**Visualización del botón de ver más */}
                {this.state.loads < this.state.rows && <button onClick={() => {
                    this.showMore()
                }} className="get_programs"> Voir plus des Programmes Neufs</button>}

                <section className="social">
                    <a href={this.state.generalInfo.instagram}>
                        <img
                            alt="instagram"
                            title="instagram"
                            src={process.env.PUBLIC_URL + "/icons/Path 353.svg"}
                        />
                    </a>
                    <a href={this.state.generalInfo.linkedin}>
                        <img
                            alt="facebook"
                            title="facebook"
                            src={process.env.PUBLIC_URL + "/icons/linkedin1.svg"}
                        />
                    </a>
                    <a href={this.state.generalInfo.youtube}>
                        <img
                            alt="youtube"
                            title="youtube"
                            src={process.env.PUBLIC_URL + "/icons/youtube1.svg"}
                        />
                    </a>
                </section>
                <Snackbar
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.toastMS}>
                        {this.state.toastM}
                    </Alert>
                </Snackbar>
            </div>
            }</>
        );
    }
}

export default Programs;
