import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import app from '../../../firebaseConfig';

class SinglePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: "",
            pageTitle: "",
            texto: "",
        }
    }

    handleChangeInput = (name, value) => {
        this.setState({ [name]: value });
    }


    render() {
        return (<div className="cc__single-page">
            <div className="cc__section">

                <h1 className="styled__h1-brown style__align-right">Nombre de la <u> página</u></h1>

                <h1 className="styled__h1-brown style__align-left">Header</h1>
                <StyledInputs
                    value={this.state.pageName}
                    type="input"
                    title="Nom du page"
                    name="pageName"
                    handleChange={this.handleChangeInput}
                />
                <StyledInputs
                    value={this.state.pageName}
                    type="input"
                    title="Titre"
                    name="pageTitle"
                    handleChange={this.handleChangeInput}
                />
                <StyledInputs
                    value={this.state.pageName}
                    type="textarea"
                    title="Texte"
                    name="texto"
                    handleChange={this.handleChangeInput}
                />
                <div className="cc__button-2">
                    <button className="cc__button accept">Cancel</button>
                    <button className="cc__button cancel">Envoyer</button>
                </div>
            </div>
        </div>);
    }
}

export default SinglePage;