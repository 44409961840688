import React from 'react';
import "./ModificationOptions.scss";
import { Link } from 'react-router-dom';

const ModificationOptions = ({ crear = true, editar = false, eliminar, activate }) => {
    return (<div className="cc__modification-options">
        <Link to={crear} className="cc__item">
            <p className="styled__label-brown-16px">Creér</p>
            <div className={crear ? "cc__symbol" : "inactivo"}>
                <img src={process.env.PUBLIC_URL + "/icons/PlusWhite.svg"} alt="" />
            </div>
        </Link>
        <div className="cc__item" onClick={editar}>
            <p className="styled__label-brown-16px">Modifier</p>
            <div className={activate ? "cc__symbol" : "inactivo"}>
                <img src={process.env.PUBLIC_URL + "/icons/Editable.svg"} alt="" />
            </div>
        </div>
        <div className="cc__item" onClick={eliminar}>
            <p className="styled__label-brown-16px">Supprimer</p>
            <div className={activate ? "cc__symbol" : "inactivo"}>
                <img src={process.env.PUBLIC_URL + "/icons/Less.svg"} alt="" />
            </div>
        </div>
    </div>);
}

export default ModificationOptions;