import React from 'react';
import "./Footer.scss";


const Footer = () => {
    const year = new Date().getFullYear();

    return (<div className="cc__footer">
        <p className="styled__p-white ">© Conseil & Commercialisation {year}</p>
    </div>);
}

export default Footer;