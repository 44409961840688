import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import './MapBox.css';
mapboxgl.accessToken = 'pk.eyJ1IjoidXVnaGEiLCJhIjoiY2s4djFydTk3MGV2ZjNlcWh0OWRuaTZpMCJ9.8o_jIlJ2mGlfqnyW4lLvRg';

class MapBox extends Component {
    constructor(props) {
        super(props);
        const dir = this.props.marker.dir.split(",");
        console.log(dir);
        this.state = {
            lng: parseInt(dir[0]),
            lat: parseInt(dir[1]),
            zoom: 10,
        };

        this.geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [this.state.lat, this.state.lng]
                    }
                }
            ]
        };
    }

    componentDidMount() {
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [this.state.lat, this.state.lng],
            zoom: this.state.zoom,
        });

        this.map.on("load", (e) => {
            this.map.addSource('point', {
                'type': 'geojson',
                'data': this.geojson
            });

            this.map.addLayer({
                'id': 'point',
                'type': 'circle',
                'source': 'point',
                'paint': {
                    'circle-radius': 10,
                    'circle-color': '#eb0271'
                }

            });
            this.map.addControl(new mapboxgl.NavigationControl());
        });

    }

    componentDidUpdate(nextState) {
        const dir = nextState.marker.dir.split(",");
        console.log(dir);
        this.map.flyTo({ center: [parseInt(dir[1]), parseInt(dir[0])] })
    }

    render() {
        return (<div style={{ width: '100%', height: '100%' }} ref={el => this.mapContainer = el}></div>);
    }
}

export default MapBox;