import React from 'react';
import "./Logout.scss";
import app from "../../firebaseConfig";

const signOut = () => {
    app.auth().signOut().then(() => {
        console.log("Has cerrado sesión exitosamente")
    }).catch(e => {
        console.log("Lo sentimos, ha habido un error")
    });
}

const Logout = (props) => {
    return (<div className="cc__logout">
        <div className="cc__logout-content">
            <img src={process.env.PUBLIC_URL + "/icons/user.svg"} alt="user logout icon" />
            <label className="logout" onClick={
                () => signOut()
            }>Se déconnecter</label>
        </div>
        <div className="cc__division"></div>
    </div>);
}

export default Logout;