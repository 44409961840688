import React from 'react';
import "./StyledCreate.scss";
import { Link } from 'react-router-dom';

const StyledCreate = ({ nombre, link, action, width, height }) => {
    return (
        <>

            {(link && !action) &&
                <Link className="cc__styledcreate-conatiner" to={link} style={{ height: height && height, width: width && width }}>
                    <div className="bg-container"></div>
                    <img src={process.env.PUBLIC_URL + "/icons/Group 734.png"} alt="" />
                    <p className="styled__label-gray-18px low-white">{nombre}</p>
                    <div className="shadow"></div>
                    <div className="shadow"></div>
                </Link>
            }
            {
                (!link && action) &&
                <div className="cc__styledcreate-conatiner" onClick={action} style={{ height: height && height, width: width && width }}>
                    <div className="bg-container"></div>
                    <img src={process.env.PUBLIC_URL + "/icons/Group 734.png"} alt="" />
                    <p className="styled__label-gray-18px low-white">{nombre}</p>
                    <div className="shadow"></div>
                </div>
            }
        </>
    );
}

export default StyledCreate;