import React, { Component } from "react";
import "./SearchBar.scss";
import { Link } from "react-router-dom";
//import Autocomplete from 'react-autocomplete';

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
class SearchBar extends Component {
    constructor(props) {
        super(props);
        if (props && props.villeOptions && props.villeOptions.length > 0) {
            props.villeOptions.sort(function(a, b){
                if(a.lugar.toLowerCase() < b.lugar.toLowerCase()) { return -1; }
                if(a.lugar.toLowerCase() > b.lugar.toLowerCase()) { return 1; }
                return 0;
            })
        }

        this.state = {
            quantity: this.props.quantity ? this.props.quantity : 0,
            ville: this.props.ville ? this.props.ville : 0,
            type: this.props.type ? this.props.type : 0,
            budget: this.props.budget ? this.props.budget : 0,
        };
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    //El departamente es la villa y la villa es el departamento, el nombramiento está mal
    handleVilleInput = (e, v) => {
        if (v) {
            this.setState({
                ville: v.id
            })
        } else {
            this.setState({
                ville: 0
            })
        }
    }

    handleTypeInput = (e, v) => {
        if (v) {
            this.setState({
                type: v.value
            })
        }
        else {
            this.setState({
                type: 0
            })
        }
    }

    //El departamente es la villa y la villa es el departamento, el nombramiento está mal
    handleDeparmentInput = (e, v) => {
        if (v) {
            this.setState({
                departement: v.trim()
            })
        } else {
            this.setState({
                departement: 0
            })
        }
    }
    handleQuantityInput = (e, v) => {
        if (v) {
            this.setState({
                quantity: v.value
            })
        }
        else {
            this.setState({
                quantity: 0
            })
        }
    }

    handleBudgetInput = (e, v) => {
        if (v) {
            var min = v.min ? v.min : 0;
            var max = v.max ? v.max : 0;

            this.setState({
                budget: min + "." + max
            })
        } else {
            this.setState({
                budget: 0
            })
        }
    }

    render() {
        return (
            <div className="searchbar_container">
                <div className="searchbar_wrapper">
                    <div className="searchbar_intro">
                        À la recherche <br /> d'un programme ?
                    </div>
                    <form action="post" method="post">
                        <div className="form__group field">
                            <Autocomplete
                                id="ville-input"
                                options={ this.props.villeOptions }
                                getOptionLabel={ option => option.lugar }
                                name="ville"
                                onChange={this.handleVilleInput}
                                onInputChange={this.handleVilleInput}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ville"
                                        margin="normal"
                                        variant="standard"
                                        // onChange={this.handleVilleInput}
                                        value={this.state.ville}
                                    />
                                )}
                            />
                        </div>
                        <div className="form__group field">
                            <Autocomplete
                                id="type-input"
                                options={tipoApartamento}
                                getOptionLabel={option => option.label}
                                name="quantity"
                                onChange={this.handleQuantityInput}
                                onInputChange={this.handleQuantityInput}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Typologie"
                                        margin="normal"
                                        autoComplete="off"
                                        variant="standard"
                                        // onChange={(e) => this.handleInput(e)}
                                        value={this.state.quantity}
                                    />
                                )}
                            />
                        </div>
                        <div className="form__group field">
                            <Autocomplete
                                id="type-input"
                                //maison = 1
                                //appartement = 2
                                options={typeMasion}
                                getOptionLabel={option => option.name}
                                name="type"
                                onChange={this.handleTypeInput}
                                onInputChange={this.handleTypeInput}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Type"
                                        margin="normal"
                                        autoComplete="off"
                                        variant="standard"
                                        // onChange={(e) => this.handleInput(e)}
                                        value={this.state.type}
                                    />
                                )}
                            />
                        </div>
                        <div className="form__group field">
                            <Autocomplete
                                id="budget-input"
                                options={budget}
                                getOptionLabel={option => option.label}
                                name="budget"
                                onChange={this.handleBudgetInput}
                                onInputChange={this.handleBudgetInput}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Budget"
                                        margin="normal"
                                        variant="standard"
                                        value={this.state.budget}
                                    // onChange={(e) => this.handleBudgetInput(e)}
                                    />
                                )}
                            />
                        </div>
                        <Link
                            className="form__button-container"
                            to={
                                "/programmes/" +
                                (this.state.quantity === "" ? 0 + "/" : this.state.quantity + "/") +
                                (this.state.ville === "" ? 0 + "/" : this.state.ville + "/") +
                                (this.state.type === "" ? 0 + "/" : this.state.type + "/") +
                                (this.state.budget === "" ? 0 + "/" : this.state.budget)
                            }>
                            <input
                                className="form__button"
                                type="image"
                                src={process.env.PUBLIC_URL + "/icons/Path 51.svg"}
                                value=">"
                                alt="upload_image"
                            />
                        </Link>
                    </form>
                </div>
            </div>
        );
    }
}
export default SearchBar;

const typeMasion = [{ value: 1, name: "Maison" }, { value: 2, name: "Appartement" }];

const budget = [
    {
    label: `Jusqu'à 200 000 €`,
    i: 0,
    max: 200000
    },
    {
    label: `200 000 € - 350 000 €`,
    i: 1,
    min: 200000,
    max: 350000
    },
    {
        label: `350 000 € - 500 000 €`,
        i: 2,
        min: 350000,
        max: 500000
    },
    {
    i: 3,
    label: `500 000 € et plus`,
    min: 500000
    }
    ];

const QUANTITY = 0;

const tipoApartamento = [
    {
    label: 'Studio',
    type: QUANTITY,
    value: 1
},
    {
        label: '2 pièces',
        type: QUANTITY,
        value: 2
    },
    {
        label: '3 pièces',
        type: QUANTITY,
        value: 3
    },
    {
        label: '4 pièces',
        type: QUANTITY,
        value: 4
    },
    {
        label: '5 pièces',
        type: QUANTITY,
        value: 5
    }]
