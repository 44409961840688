import React from "react";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import Slider from "../../Components/Slider/Slider";
import "./Group.scss";
import { useEffect } from "react";
import { useState } from "react";
import app from "../../Admin/firebaseConfig";
import MetaTags from "react-meta-tags";

const Group = (props) => {
  const [grupo, setGrupo] = useState({});
  const [loaded, setLaoded] = useState(false)

  useEffect(() => {
    app.database().ref("group/").once('value').then(ss => {
      if (ss.val()) {
        setGrupo(ss.val());
        setLaoded(true);
      }
    })
  }, [])

  return (<>{
    loaded &&
    <div className="group_container">
      <div className="wrapper">
        <MetaTags>
          <title>Programmes immobilers neufs | Appartements & maisons | C&C</title>
          <meta name="description" content="Conseil & Commercialisation ● Spécialiste du neuf pour la résidence principale et l’investissement locatif ● Appartements & maisons ● Paris & Ile-de-France ● Contactez-nous ►" />
          <meta property="og:title" content="Programmes immobilers neufs | Appartements & maisons | C&C" />
        </MetaTags>
      </div>
      <BannerSlide title={grupo.titulo} img={grupo.img} />
      <StyledContainer cn="cc-middle" s={30} prlxSize={0}>
        <div className="inversion__info" style={{ padding: "30px" }}>
          <h2 className="title-h2-brown" style={{ textAlign: "left" }}>
            Notre <u>histoire</u>
          </h2>
          <p style={{ textAlign: "left", color: "#a0a0a0", fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
            Conseil & Commercialisation, spécialiste du neuf pour la résidence
            principale et l’investissement locatif, a été fondé en janvier 2016.
            Installé à Boulogne-Billancourt, nous intervenons en Ile-de-France
            mais également sur l’ensemble du territoire, notamment en Aquitaine
            et Normandie.
            <br />
            <br />
            La force de notre structure est d’accompagner très en amont nos
            partenaires promoteurs et ce, de l’identification de la charge
            foncière à la commercialisation des appartements et maisons.
            <br />
            <br />
            Composée de collaborateurs tous expérimentés et régulièrement formés
            aux nouvelles réglementations, Conseil & Commercialisation crée et
            assure le lien, entre le promoteur et le client à la recherche de sa
            résidence principale ou son investissement locatif.
          </p>
        </div>
      </StyledContainer>
      <StyledContainer cn="cc-middle" scn="cc-styled-right" s={20} prlxSize={0}>
        <div className="inversion__info-g" style={{ padding: "30px" }}>
          <h2 className="title-h2-brown" >
            Nos <u>métiers</u>
          </h2>
          <h3 className="title-h2-brown">
            C&C Résidentiel et C&C Investir
          </h3>
          <p style={{ color: "#6d6d6d", fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
            Deux métiers pour une même prestation : le conseil et la vente C&C
            Résidentiel est dédiée à la vente de biens en Résidence principale
            ou d’Investissement locatifs dans les espaces de vente présents sur
            les sites des programmes ou le cas échéant, en vente debout en
            l’absence de bulle de vente.
            <br />
            <br />
            C&C Investir, composée de collaborateurs spécialisés dans le conseil
            en gestion de patrimoine, intervient directement auprès d’une
            clientèle d’investisseurs soucieux d’optimiser leur fiscalité au
            travers d’investissements de niche et le plus souvent sans
            contrainte de localisation en partenariat avec la grande majorité
            des promoteurs nationaux.
          </p>
          <h3 className="title-h2-brown">
            Développement Foncier et Transactions Grands Comptes
          </h3>
          <p style={{ color: "#6d6d6d", fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
            La recherche de terrain fait partie intégrante de notre activité. À
            ce titre, et pour chaque opportunité initiée par nous ou le
            promoteur, une étude de capacité et de faisabilité est rendue
            accompagnée d’un avis commercial portant sur la programmation et
            surface habitable (Shab) préconisées en respect des règles
            d’urbanisme (PLU, PLUI)
            <br />
            <br />
            Soucieux de conforter les financements dont une partie est
            constituée d’un crédit relais, nous accompagnons nos clients dans la
            revente de leur bien avec dès la 1ère visite sur site, la remise
            d’un avis de valeur.
            <br />
            <br />
            Notre département Grands Comptes répond également aux attentes de
            foncières et marchands de biens, dans l’arbitrage de leur patrimoine
          </p>
        </div>
      </StyledContainer>
      <StyledContainer cn="cc-middle">
        <div className="current_projects-wrapper">
          <h2
            className="title-h2-brown left-align border-bot-pink-1"
            style={{ textAlign: "left" }}>
            Rencontrez <br /> notre <u> équipe dirigeante</u>
          </h2>
          <Slider
            desktop={true}
            imgs={grupo.people}
            imgLenght={grupo.people.length} 
            cardWidth={325}
            cardHeight={400}
            sliderType={"PEOPLE"}
            />
        </div>
      </StyledContainer>
    </div>}</>
  );
};

export default Group;
