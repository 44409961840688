import React from 'react';
import { useRef } from "react";
import { useState } from 'react';
import "./StyledFileUpload.scss";

const readURL = (e, setCurrentImageURL, setCurrentImageName) => {
    if (e.files && e.files[0]) {
        setCurrentImageName("(" + e.files[0].name + ")")
        var reader = new FileReader();
        reader.onload = e => {
            setCurrentImageURL(e.target.result);
        }

        reader.readAsDataURL(e.files[0]);
    }
}

const StyledFileUpload = ({ title, name, onFileChange, stle = "", img }) => {
    const imageRef = useRef(null);
    const [currentImageURL, setCurrentImageURL] = useState(null);
    const [currentImageName, setCurrentImageName] = useState(null);

    return (<div className={"styledFileUpload" + stle}>
        <h1 className="style__align-left styled__label-pink-18px">{title}</h1>
        <div className="styled__inner-wrapper">
            {
                img && !currentImageName ?
                    <div className="styled__upload-preview" alt="selected image" style={{ backgroundImage: `url(${img})` }}> </div>
                    :
                    <div className="styled__upload-preview" alt="selected image" style={{ backgroundImage: `url(${currentImageURL})` }}> </div>
            }
            <div className="styled__info">
                <div className="styled__info-src short">
                    <label className="styled__label-pink">image actuelle</label>
                    <p className="styled__p-gray">{currentImageName}</p>
                </div>
                <div className="styled__info-src">
                    <label className="styled__label-pink short">changer l'image</label>
                    <input className="styled__upload-button" type="button" value="" onClick={() => imageRef.current.click()} value="(télécharger l'image)" />
                    <input name={name} accept="image/*" type="file" ref={imageRef} onChange={e => {
                        readURL(e.target, setCurrentImageURL, setCurrentImageName);
                        onFileChange(e.target.name, e.target.files[0])
                    }} style={{ display: "none" }} />
                </div>
            </div>
        </div>
    </div>);
}

export default StyledFileUpload;