import React, { Component } from "react";
import "./Footer.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import app from "../../Admin/firebaseConfig";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "", loaded: false
    };
  }

  uploadToMaichimp = (e) => {
    const formData = new FormData();
    formData.append('mc-embedded-subscribe-form', this.state.email);
    formData.append('b_57563e3e4e34fe905f580b845_e0cd3e1a64', "");

    axios({
      method: 'post',
      data: formData,
      url: "",
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
    })
  }
  componentDidMount() {
    app.database().ref("generalInfo/").once('value').then(ss => {
      this.setState({ generalInfo: ss.val() }, () => {
        this.setState({ loaded: true })
      })
    })
  }

  handleMailingChange = e => {
    this.setState({ email: e.target.value });
  }


  render() {
    return (
      <>{this.state.loaded &&
        <div className="footer__container">
          <div className="footer__info-container">
            <div className="left">
              <img
                src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"}
                style={{ width: 97 }}
                alt="logo"
                title="logo"
              />
              {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
              <form action="https://immo.us8.list-manage.com/subscribe/post?u=57563e3e4e34fe905f580b845&amp;id=e0cd3e1a64" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" method="post" target="_blank" noValidate>
                <div className="form__group field" id="news-letter-register">
                  <input
                    type="input"
                    className="form__field"
                    placeholder="Email"
                    name="EMAIL"
                    id="email"
                    value={this.state.email}
                    onChange={e => this.handleMailingChange(e)}
                    required
                  />
                  <input type="hidden" name="b_57563e3e4e34fe905f580b845_e0cd3e1a64" tabIndex="-1" value="" ></input>
                  <label htmlFor="email" className="form__label">
                    Email
                </label>
                </div>
                <input className="submit_form" style={{ fontSize: 10 }} type="submit" value="S’ABONNER" />
              </form>
            </div>
            <div className="right">
              <div className="row">
                <a href="tel:+33186957644">+33 1 86 95 76 44</a>
                <Link to="/cookies">À PROPOS DES COOKIES</Link>
                <Link to="/politique_de_confidentialite">MENTIONS LÉGALES</Link>
              </div>
              <div className="row">
                <Link to="/groupe">À PROPOS DE NOUS</Link>
                <Link to="/contact">CONTACTEZ NOUS</Link>
                <a href="https://www.youtube.com/channel/UCGF34IXsBuDitYGsvfihr6g">CHAINE YOUTUBE</a>
              </div>
              <div className="row">
                <section className="social">
                </section>
              </div>
            </div>
          </div>
          <div className="footer__info-cr">
            <p>© Conseil & Commercialisation {new Date().getFullYear()}</p>
          </div>
        </div>}</>
    );
  }
}

export default Footer;
