import React, { Component } from "react";
import '../SlideShow/flickity.min.css';
import "./Slider.scss";
import Card from "./Card";
import { Link } from "react-router-dom";
import Flickity from 'react-flickity-component'

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: this.props.imgs[0],
      modal: true,
      noArrows: false,
    };
    this.index = 1;
    this.slideTransform = this.slideTransform.bind(this);
    this.flktRef = React.createRef();
  }

  handleRightButton = () => {
    this.flktRef.next();
  };

  handleLeftButton = () => {
    this.flktRef.previous();
  };

  visualizarModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  slideTransform = (index, length, more) => {
    let output = ``;
    if (window.innerWidth <= 340) {
      output = `${index * (100 / (length + (more ? 1 : 0))) + 0.2}%`;
    } else if (window.innerWidth <= 600) {
      output = `${index * (100 / (length + (more ? 1 : 0))) - 1}%`;
    } else {
      output = `${index * (100 / (length + (more ? 1 : 0)))}%`;
    }

    return output;
  };
  render() {
    return (
      <div className="slider__conatiner-f">
        <Flickity className="slider__carousel-wrapper"
          elementType={'div'} // default 'div'
          options={{
            initialIndex: 1
          }} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
          flickityRef={c => this.flktRef = c}

        >
          {this.props.imgs.map((e, i) => (
            // <Link key={i} to={"/programme/" + e.id}>
            e !== null &&
            <Card
                key={i}
                prop={e}
                cardWidth={this.props.cardWidth}
                cardHeight={this.props.cardHeight}
                sliderType={this.props.sliderType}
            />
            // </Link> */
          ))}
          {this.props.more ? (
            <Link to="/programmes" className="slide-more">
              <img
                src={process.env.PUBLIC_URL + "/icons/Group 734.svg"}
                alt=""
              />
              <p>voir plus</p>
            </Link>
          ) : ("")}
        </Flickity>
        <div className={this.props.desktop ? "slider__buttons desktop" : "slider__buttons"}>
          {this.state.noArrows === true &&
            <img
                src={process.env.PUBLIC_URL + "/icons/arrow-left.png"}
                alt="arrow left"
                onClick={this.handleLeftButton}></img>}
          {this.state.noArrows === true &&
              <img
              src={process.env.PUBLIC_URL + "/icons/arrow-right.png"}
              alt="arrow right"
              onClick={this.handleRightButton}></img>
          }
        </div>
      </div>
    );
  }
}

export default Slider;

