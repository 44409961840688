import React from 'react';
import './Menu.scss';
import { Link, NavLink } from 'react-router-dom';

const AdminMenu = ({ props }) => {
    return (<div className="cc__menu-container">

        <img className="cc__menu-logo" src="/img/logo/cyc-logo.svg" alt="logo" title="logo" />
        <NavLink exact activeClassName="cc__admin-link_active" to="/admin" className="cc__admin-link">
            <img src="/icons/home.svg" alt="accueil" title="accueil" />
            <p className="ccadmin__menu-font">Les projets</p>
        </NavLink>
        <NavLink activeClassName="cc__admin-link_active" to="/admin/promotor" className="cc__admin-link">
            <img src="/icons/004-tower-crane.svg" alt="promoteur" title="promoteur" />
            <p className="ccadmin__menu-font">Promoteurs</p>
        </NavLink>
        <NavLink activeClassName="cc__admin-link_active" to="/admin/pages" className="cc__admin-link">
            <img src="/icons/XMLID_1283_.svg" alt="liste" title="liste" />
            <p className="ccadmin__menu-font">Pages</p>
        </NavLink>
        <NavLink activeClassName="cc__admin-link_active" to="/admin/ley" className="cc__admin-link">
            <img src="/icons/auction.svg" alt="lois" title={"lois"} />
            <p className="ccadmin__menu-font">Lois</p>
        </NavLink>
        <NavLink activeClassName="cc__admin-link_active" to="/admin/certificat" className="cc__admin-link">
            <img src="/icons/certificado.svg" alt="certifications" title="certifications" />
            <p className="ccadmin__menu-font">Certificat</p>
        </NavLink>
    </div>);
}

export default AdminMenu;