import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    left: '40px',
    right: '0px',
    bottom: '0px',
    top: '-50px',
    width: '40%',
    height: '50%',
};

export class GMap extends Component {
    constructor(props) {
        super(props);
        const dir = this.props.marker.dir.split(",");
        this.state = {
            program: {latitude: parseFloat(dir[0]), longitude: parseFloat(dir[1])}
        }
    }

    displayMarkers = () => {
            return <Marker key={0} id={0}
                           position={{
                                lat: this.state.program.latitude,
                                lng: this.state.program.longitude
                           }}
                           onClick={() => console.log("You clicked me!")} />
    }

    render() {
        return (
            <Map
                google={this.props.google}
                fullscreenControl={false}
                mapTypeControl={false}
                streetViewControl={false}
                zoom={8}
                style={mapStyles}
                initialCenter={{
                    lat: this.state.program.latitude,
                    lng: this.state.program.longitude}}
            >
                {this.displayMarkers()}
            </Map>
        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
            apiKey: 'AIzaSyDXpotICw7m25BAFGsXWTDjjguzwVobZVk',
            language: 'fr'
        }
    ))(GMap)
