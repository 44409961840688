import React, {Component} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core/'
import "./GridCard.scss";
import SimpleCard from "./SimpleCard";
import PartnersCard from "./PartnersCard";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

class AltCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: props.projects && props.projects.length > 0 && props.projects.filter(e => {
                if (e && e.imgs && e.imgs.length > 0 && e.imgs[0].imgURL) {
                    return e
                }
            }),
            partners: props.partners,
        }
    }

    render() {

        return (
            <div className={useStyles.root}>
                {useStyles.media}
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    {this.state.projects && this.state.projects.length > 0 && this.state.projects.map(elem => (
                        elem !== null &&
                        <Grid item xs={12} sm={6} md={4} key={this.state.projects.indexOf(elem)} className={useStyles.rootCard}>
                            {elem && elem.imgs && elem.imgs[0].imgURL && <SimpleCard props={elem}></SimpleCard>}
                        </Grid>
                    ))}
                    {this.state.partners && this.state.partners.length > 0 && this.state.partners.map(elem => (
                        elem !== null &&
                        <Grid item xs={12} sm={6} md={3} key={this.state.partners.indexOf(elem)} className={useStyles.rootCard}>
                            {elem && <PartnersCard props={elem}></PartnersCard>}
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }
}

export default AltCard;