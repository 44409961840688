import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from "../../../firebaseConfig";
import uuid from 'react-uuid';

class Crear extends Component {
    // state = {}
    constructor(props) {
        super(props);
        this.state = {
            changed: false,
            uploadInfo: {
                link: "",
                nombre: "",
                description: "",
                logo: null,
                id: ""
            },
            loading: false
        }
    }

    handleInputChange = (name, value) => {
        console.log(value);
        this.setState({ uploadInfo: { ...this.state.uploadInfo, [name]: value } })
    }

    handleUpload = () => {
        var task = null;
        this.setState({ loading: true })
        const db = app.database();
        if (this.state.uploadInfo.logo !== null && typeof this.state.uploadInfo.logo !== "string") {
            console.log(this.state.uploadInfo.logo)
            const imageName = this.state.uploadInfo.logo.name.split(".");
            var imageExtention = imageName.splice(imageName.length - 1);
            var storageRef = app.storage().ref(`promotores/${uuid() + "." + imageExtention}`);
            storageRef.put(this.state.uploadInfo.logo).then((ss) => {
                ss.ref.getDownloadURL().then(url => {
                    // console.log(url)
                    const newKey = db.ref('promotores/').push().key;
                    db.ref('promotores/' + newKey).set({ ...this.state.uploadInfo, logo: url, id: newKey }).then(() => {
                        this.setState({ loading: false })
                        this.props.history.push("/admin/promotor");
                    });
                });
            });
        } else {
            console.log("else")
            db.ref('promotores/' + this.state.uploadInfo.id).set(this.state.uploadInfo).then(() => {
                this.props.history.push("/admin/promotor");
            });
        }
    }

    cancelUpload = () => {
        this.props.history.push("/admin/promotor");
    }

    render() {
        return (<div className="cc__page_editor-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">PROMOTEURS</h1>
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Promoteur</h1>
                <StyledInputs
                    value={this.state.nombre}
                    type={"input"}
                    name="nombre"
                    title="Nom du promoteur"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.link}
                    type={"input"}
                    name="link"
                    title="URL"
                    handleChange={this.handleInputChange}
                />
                <StyledFileUpload
                    name="logo"
                    title="Image Hero"
                    onFileChange={this.handleInputChange} />
                <StyledInputs
                    value={this.state.description}
                    type={"textarea"}
                    name="description"
                    title="Description"
                    handleChange={this.handleInputChange}
                />
                <div className="cc__button-2">
                    <button className="cc__button accept" onClick={this.cancelUpload}>Annuler</button>
                    <button className="cc__button cancel" onClick={() => this.handleUpload()}>Envoyer</button>
                </div>
            </section>
            <div className="cc__division"></div>
        </div>);
    }
}

export default Crear;