import React, { Component } from 'react';
// import ModificationOptions from '../../../Components/ModifcationOptions/ModificationOptions';
import StyleEditable from '../../../Components/StyledEditable/StyleEditable';
import SideBarOptions from '../../../Components/SideBarOptions/SideBarOptions';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import app from '../../../firebaseConfig';
import "./Main.scss"

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            generalInfo: {
                youtube: "",
                instagram: "",
                linkedin: "",
                email: "",
                phone: "",
                adresse: "",
                coor: ""
            },
            home: { title: "home", img: "" },
            selectedPages: [],
            loaded: false
        }
    }

    setGeneralInformation = (name, value) => {
        this.setState({
            generalInfo: {
                ...this.state.generalInfo,
                [name]: value
            }
        })
    }

    componentDidMount() {
        this.loadingState();
    }

    loadingState = async () => {

        const home = app.database().ref("home/").child("header");
        await home.once("value").then(ss => {
            if (ss.val()) {
                this.setState({
                    home: {
                        ...this.state.home,
                        img: ss.val().imagenActual
                    }
                })
            }
        });

        await app.database().ref("generalInfo/").once("value").then(ss => {
            this.setState({ generalInfo: ss.val() });
        })
        await app.database().ref("investissement/").once("value").then(ss => {
            if (ss.val()) this.setState({ investissement: ss.val() });
        })
        await app.database().ref("programmes/").once("value").then(ss => {
            if (ss.val()) this.setState({ programmes: ss.val() });
        })
        await app.database().ref("group/").once("value").then(ss => {
            if (ss.val()) this.setState({ group: ss.val() });
        })
        await app.database().ref("vendre/").once("value").then(ss => {
            if (ss.val()) this.setState({ vendre: ss.val() });
        })
        await app.database().ref('contact/').once("value").then(ss => {
            if (ss.val()) this.setState({ contact: ss.val() });
        })

        this.setState({ loaded: true })
    }

    uploadContactInfo = () => {
        const generalInfo = app.database().ref("generalInfo/");
        const { youtube, instagram, linkedin, email, phone, adresse, coor } = this.state.generalInfo;
        generalInfo.set({ youtube, instagram, linkedin, email, phone, adresse, coor });
    }

    handleSelectedEditable = (e) => {
        console.log(e.target.checked);
    }

    render() {
        return (
            <>{!this.state.loaded ? "loading" :
                <div className="cc__main-pages">
                    {/* Si hay elementos seleccionados hacer display de las opciones
            - Agregar animación de entrada */}
                    <section className="cc__fullsection">
                        <div className="main_title">
                            <h1 className="styled__h1-brown margin-40px">Pages</h1>
                        </div>
                    </section>
                    <section className="cc__middle">
                        <div className="cc__left">
                            <div className="cc__section">
                                <div className="cc__row">
                                    <p className="styled__p-gray">Pages sélectionnées (0)</p>
                                </div>
                            </div>
                            <div className="cc__section cc__center">
                                <h1 className="styled__h1-brown">Home</h1>
                                <StyleEditable
                                    bgImg={this.state.home.img}
                                    title={"Home"}
                                    link={"/admin/pages/home"}
                                />
                            </div>
                            <div className="cc__division"></div>
                            <div className="cc__pages-list">
                                <StyleEditable
                                    bgImg={this.state.investissement.img}
                                    title={this.state.investissement.titulo}
                                    link="/admin/pages/investissement"

                                />
                                <StyleEditable
                                    bgImg={this.state.programmes.img}
                                    title={this.state.programmes.titulo}
                                    link="/admin/pages/programmes"
                                />
                                <StyleEditable
                                    bgImg={this.state.group.img}
                                    title={this.state.group.titulo}
                                    link="/admin/pages/group"
                                />
                                <StyleEditable
                                    bgImg={this.state.vendre.img}
                                    title={this.state.vendre.titulo}
                                    link="/admin/pages/vendre"
                                />
                                <StyleEditable
                                    bgImg={this.state.contact.img}
                                    title={this.state.contact.titulo}
                                    link="/admin/pages/contact"
                                />
                            </div>
                        </div>
                        {/* <ModificationOptions /> */}
                        <SideBarOptions
                            title={<h3 className="styled__h3-gray">Aspects <u>généraux</u></h3>}
                        >
                            <h1 className="styled__label-gray-18px style__align-left">Contact</h1>
                            <StyledInputs
                                value={this.state.generalInfo.email}
                                type="input"
                                title="e-mail"
                                name="email"
                                handleChange={this.setGeneralInformation}
                            />
                            <StyledInputs
                                value={this.state.generalInfo.phone}
                                type="input"
                                title="Téléphone"
                                name="phone"
                                handleChange={this.setGeneralInformation}
                            />
                            <h1 className="styled__label-gray-18px style__align-left">Réseaux sociaux</h1>
                            <StyledInputs
                                value={this.state.generalInfo.youtube}
                                type="input"
                                title="You Tube"
                                name="youtube"
                                handleChange={this.setGeneralInformation}
                            />
                            <StyledInputs
                                value={this.state.generalInfo.instagram}
                                type="input"
                                title="Instagram"
                                name="instagram"
                                handleChange={this.setGeneralInformation}
                            />
                            <StyledInputs
                                value={this.state.generalInfo.linkedin}
                                type="input"
                                name="linkedin"
                                title="LinkedIn"
                                handleChange={this.setGeneralInformation}
                            />
                            <h1 className="styled__label-gray-18px style__align-left">Emplacement</h1>
                            <StyledInputs
                                value={this.state.generalInfo.adresse}
                                type="input"
                                name="adresse"
                                title="Adresse"
                                handleChange={this.setGeneralInformation}
                            />
                            <StyledInputs
                                value={this.state.generalInfo.coor}
                                type="input"
                                name="coor"
                                title="Coordonnées"
                                handleChange={this.setGeneralInformation}
                            />
                            <div className="cc__button-2">
                                <button className="cc__button cancel" onClick={() => this.uploadContactInfo()}>Enregistrer</button>
                            </div>
                        </SideBarOptions>
                    </section>
                </div>}</>);

    }
}


export default Main;