import React from "react";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import StyledContainer from "../../Components/StyledContainer/StyledContainer";

import "./Venta.scss";
import DropDown from "../../Components/DropDown/DropDown";
import { Parallax } from "react-scroll-parallax";
import { useEffect } from "react";
import app from "../../Admin/firebaseConfig";
import { useState } from "react";
import MetaTags from "react-meta-tags";

const Venta = ({ props }) => {
  const [venta, setVenta] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    app.database().ref("vendre/").once('value').then(ss => {
      setVenta(ss.val())
      setLoaded(true);
    });
  }, [])

  return (
    <>{loaded &&
      <div className="venta_container">
        <div className="wrapper">
          <MetaTags>
            <title>Vente immobilière : conseil et gestion | Paris & Ile-de-France | C&C</title>
            <meta name="description" content="Propriétaire d’un terrain, d’un appartement ou d’un immeuble, confiez-nous la revente de votre bien. De l’estimation à la vente, nous nous occupons de tout. Contactez-nous ►" />
            <meta property="og:title" content="Vente immobilière : conseil et gestion | Paris & Ile-de-France | C&C" />
          </MetaTags>
        </div>
        <BannerSlide title={venta.titulo} img={venta.img} />
        <StyledContainer cn="cc-middle" s={10} prlxSize={-10} pdng={50}>
          <div className="inversion__info" style={{ padding: "30px" }}>
            <h2 className="title-h2-brown" style={{ textAlign: "left" }}>
              Faites confiance à notre équipe de spécialiste de la promotion immobilère
            </h2>
            <p style={{ textAlign: "left", color: "#A0A0A0" , fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
              Que vous soyez propriétaire d’un terrain, d’un appartement ou d’un immeuble : confiez-nous la revente de votre bien. De l’estimation à la vente, nous nous occupons de tout.
          </p>
            <p style={{ textAlign: "left", color: "#A0A0A0" , fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
              Nous nous déplaçons sur place pour estimer la valeur de votre bien. Nous nous chargeons ensuite de la diffusion de vos annonces (photos, descriptif, diagnostique) et réalisons les visites pour votre compte. Nous vous accompagnons jusqu’à la signature de l’acte de vente chez le notaire.
          </p>
            <p style={{ textAlign: "left", color: "#A0A0A0" , fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
              Selon votre choix de vendre l’immeuble en bloc ou à la découpe, nous vous accompagnons pour l’ensemble des démarches règlementaires et administratives. Nous réalisons pour votre compte une étude de marché détaillée et la mise en place d’une stratégie commerciale et marketing adaptée. Nous vous accompagnons jusqu’à la signature de l’acte de vente chez le notaire.
          </p>
            <p style={{ textAlign: "left", color: "#A0A0A0" , fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>Nous nous déplaçons sur place pour une prise en compte de la situation de votre bien (environnement, topographie des lieux, transports, écoles, commerces…). Nous réalisons une étude de capacité afin d’évaluer les droits à construire puis une étude de faisabilité dans le but d’optimiser les surfaces à créer. Nous déterminons ensemble un prix que nous soumettons ensuite aux grands comptes ou particuliers. Nous vous accompagnons jusqu’au rendez-vous chez le notaire.</p>
          </div>
        </StyledContainer>
        <div className="program__map">
          <div className="cc_styled-bar"></div>
          <div className="program__venta-wrapper">
            <Parallax y={[30, -10]}>
              <div className="map_container">
                {Object.keys(props).map((key) => (
                  <DropDown
                    button={"Nous contacter"}
                    key={key}
                    k={key}
                    more={false}
                    info={props[key].info}
                    title={props[key].title}
                  />
                ))}
              </div>
            </Parallax>
            <div className="info_venta">
              <h2 className="title-h2-brown">
                Et bien parlons <u> affaires !</u>
              </h2>
              <p style={{ marginTop: 40, color: "#A0A0A0", textAlign: "left", fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }} >
                Nous rencontrer, c’est faire le choix de confier la vente de votre
                bien à une équipe de professionnels dédiés au développement
                foncier à la transaction pour grands comptes et particuliers.
            </p>
            </div>
          </div>
        </div>
      </div>}</>
  );
};

export default Venta;
