import app from "../../firebaseConfig";
import { useContext } from "react";
import { withRouter, Redirect } from "react-router";
import React, { useCallback } from 'react';
import { AuthContext } from "../../AuthContext";
import "./Login.scss";
import { useState } from "react";

const Recover = ({ history }) => {

    const [emailSend, setEmailState] = useState(false);
    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email } = event.target.elements;
            try {
                await app
                    .auth().sendPasswordResetEmail(email.value).then(() => {
                        setEmailState(true);
                    })
                // history.push("/admin");
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const currentUser = useContext(AuthContext);
    if (currentUser.currentUser) {
        return <Redirect to="/" />
    }
    // jpgarias09@gmail.com
    // ProbandoProbando
    return (<div className="cc__login-container">
        <form className="form-container" onSubmit={handleLogin}>
            {!emailSend ?
                <>
                    <h1>Récupérer le <u>mot de passe</u></h1>
                    <label className="styled__label-brown-16px " htmlFor="">Utilisateur </label>
                    <input className="styled__p-gray " type="text" name="email" />
                    <button type="submit">Envoyer</button>
                    <img className="logo-login" src={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"} alt="logo" title="logo" />
                </>
                : <h1>Email envoyé</h1>}
        </form>
    </div>);
}

export default withRouter(Recover);