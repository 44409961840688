import React, { Component } from 'react';
import ModificationOptions from '../../../Components/ModifcationOptions/ModificationOptions';
import StyledCreate from '../../../Components/Create/StyledCreate';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import StyleEditable from '../../../Components/StyledEditable/StyleEditable';
import "./Certificados.scss"
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from '../../../firebaseConfig';
import uuid from 'react-uuid';
import StyledLoi from '../../../Components/StyledLoi/StyledLoi';

class Certificados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            certificados: [],
            openModal: false,
            currentEditing: 0
        }
    }

    componentDidMount() {
        let certificados = [];

        app.database().ref("certificados/").once("value").then(ss => {
            ss.forEach(child => {
                certificados.push({ ...child.val(), checked: false });
            })

            this.setState({ certificados })
        })
    }

    uploadFile = async () => {

        const certificados = this.state.certificados.slice();
        const certificadoRef = app.database().ref("certificados/");

        for (const certificado of certificados) {
            if (certificado.imgFile) {
                await this.uploadImageAsPromise(certificado.imgFile).then(url => {
                    delete (certificado.imgFile);
                    delete (certificado.checked);
                    certificado.imgURL = url;
                })
            }

            const dbKey = certificado.id ? certificado.id : certificadoRef.push().key;
            certificado.id ? certificadoRef.child(dbKey).set(certificado) : certificadoRef.child(dbKey).set({ ...certificado, id: dbKey })
        }
    }

    handleCerfiticadoChange = (evt) => {
        this.setState({
            certificados:
                this.state.certificados.map((certificado, index) => {
                    return parseInt(evt.target.name) === index ? { ...certificado, checked: evt.target.checked } : certificado
                })
        })
    }

    createNewCertificate = () => {
        var certificado = this.state.certificados.slice();
        certificado.push({
            imgFile: "",
            imgURL: "",
            title: "",
            checked: false
        });

        this.setState({ certificados: certificado, currentEditing: certificado.length - 1 }, () => {
            this.setState({ openModal: true })
        })
    }

    handleInputChange = (name, value) => {
        this.setState({
            certificados: this.state.certificados.map((e, i) => {
                return i === this.state.currentEditing ? { ...e, [name]: value } : e
            })
        })

        if (value.type) {
            readURL(this.handleChanginURL)
        }
    }

    handleChanginURL = (url) => {
        console.log(url)
        this.setState({
            certificados: this.state.certificados.map((e, i) => {
                return i === this.state.currentEditing ? { ...e, imgURL: url } : e
            })
        })
    }

    uploadImageAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var storage = app.storage();
                var imageExtention = imageName.splice(imageName.length - 1);
                var storageRef = storage.ref(`certificados/${uuid() + "." + imageExtention}`);

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(url => {
                        resolve(url);
                    });
                })
            }
        })
    }

    handleEdit = () => {
        var items = this.state.certificados.filter(e => e.checked);
        if (items.length > 0) {
            this.props.setSelected(items);
            this.props.history.push("/admin/certificat/edit");
        }
    }

    deleteItems = () => {
        const db = app.database().ref('certificados/');
        Promise.all(
            this.state.certificados.map(e => {
                if (e.checked) {
                    db.child(e.id).remove();
                }
            })
        ).then(() => this.props.history.push('/admin/certificat'))
    }

    cancelAdded = () => {
        var canceled = [...this.state.certificados]
        canceled.splice(canceled.length - 1);
        this.setState({ certificados: canceled });
    }

    render() {
        return (<div className="cc__main-promotor">
            <section className="cc__fullsection">
                <div className="main_title">
                    <h1 className="styled__h1-brown margin-40px">Certificats</h1>
                    <ModificationOptions
                        editar={this.handleEdit}
                        activate={this.state.certificados.filter(e => e.checked === true).length > 0}
                        eliminar={this.deleteItems}
                    />
                </div>
            </section>
            <section className="cc__middle">
                <div className="cc__section">
                    <div className="cc__row">
                        <p className="styled__p-gray">Lois sélectionnés ({this.state.certificados.filter(e => e.checked === true).length})</p>
                    </div>
                    <div className="cc__row-certificado">
                        {this.state.certificados.map((e, i) => {
                            return <StyledLoi
                                nombre={e.title}
                                img={e.imgURL}
                                checked={e.checked}
                                id={e.id}
                                handleChange={el => this.setState({
                                    certificados: this.state.certificados.map((ele, index) => { return i === index ? { ...ele, checked: el.target.checked } : ele })
                                })}
                            />
                        })}
                        <StyledCreate
                            nombre="Ajouter un certificat"
                            action={this.createNewCertificate}
                            width={"250px"}
                        />
                    </div>
                </div>
            </section>
            {
                this.state.certificados.filter(e => { return !e.hasOwnProperty('id') }).length >= 1 &&
                < div className="cc__button-2">
                    <button className="cc__button cancel" onClick={() => this.uploadFile()}>Enregistrer</button>
                </div>
            }
            {
                this.state.openModal &&
                <StyledModal>
                    <StyledInputs
                        value={this.state.certificados[this.state.currentEditing].title}
                        type="input"
                        name="title"
                        title="Nom"
                        handleChange={this.handleInputChange}
                    />
                    <StyledFileUpload
                        name="imgFile"
                        title="Logo"
                        onFileChange={this.handleInputChange}
                    />
                    <div className="cc__button-2">
                        <button className="cc__button accept" onClick={() => this.setState({ openModal: false }, () => {
                            this.cancelAdded()
                        })}>Annuler</button>
                        <button className="cc__button cancel" onClick={() => { this.setState({ openModal: false }) }}>Envoyer</button>
                    </div>
                </StyledModal>
            }
        </div >);
    }
}

export default Certificados;

const StyledModal = (props) => {
    return (<div className="cc__modal-container">
        <div className="cc__modal-info">
            {props.children}
        </div>
    </div>);
}

const readURL = (e, setCurrentImageURL) => {
    if (e.files && e.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
            setCurrentImageURL(e.target.result);
            // console.log(e.target.result)
            // return e.target.result;
        }

        reader.readAsDataURL(e.files[0]);
    }
}



