import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import './PageEditor.scss';
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from '../../../firebaseConfig';
import uuid from 'react-uuid';


class PageEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header: {
                nombreDePagina: "",
                titulo: "",
                imagenActual: "",
            },
            headers: [],
            section1: {
                texto: ""
            },
            section2: {
                titulo: "",
                visualiacionProyectos: ["-", "-", "-", "-", "-", "-"],
                cantidadDeProyectos: 6
            },
            section3: {
                titulo: "",
                cantidadDeIconos: 5,
                iconos: [
                    {
                        img: " ",
                        titulo: "asdasd",
                        texto: "zxczxcz"
                    }, {
                        img: " ",
                        titulo: " ",
                        texto: " "
                    }, {
                        img: " ",
                        titulo: " ",
                        texto: " "
                    }, {
                        img: " ",
                        titulo: " ",
                        texto: " "
                    }, {
                        img: " ",
                        titulo: " ",
                        texto: " "
                    }, {
                        img: " ",
                        titulo: " ",
                        texto: " "
                    },
                ]
            },
            section4: {
                titulo: "",
                cantidadDePromotores: 6,
                promotores: [
                    1, 2, 3, 4, 5, 6
                ],
            },
            section5: {
                titulo: "",
                cantidadDeComentarios: 5,
                comments: [],
                currentEditing: 0
                //Falta por planear el diseño para este elemento
            },
            opciones: {
                project: ["-", "-", "-", "-", "-", "-"],
                modalOpen: false
            }
        }
        this.orderName = ['Premier', 'Deuxième', 'Troisième', 'Quatrième', 'Cinquième', 'Sixième'];
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const projects = app.database().ref("projects/");
        const projectList = [];

        projects.once("value").then(ss => {

            ss.forEach(child => {
                projectList.push({ valor: child.val().id, name: child.val().title });
            })
            projectList.push({ valor: "-", name: "-" });
        }).then(() => {
            this.setState({ opciones: { ...this.state.opciones, project: projectList } });
        })

        app.database().ref("home/").once("value").then(ss => {
            if (ss.val()) this.setState(ss.val());
        })
    }

    //Cambiar composición de los objetos de la sección del header
    handleHeaderInputChange = (name, value) => {
        this.setState({ header: { ...this.state.header, [name]: value } })
    }

    //Cambiar objetos de la seccion #2
    handleSection2Change = (name, value) => {
        name = name.split("-")

        if (name[0] === "visualiacionProyectos") {
            var updatedElement = [...this.state.section2.visualiacionProyectos];
            // return;
            updatedElement[parseInt(name[1])] = value;
            this.setState({
                section2: {
                    ...this.state.section2, visualiacionProyectos: this.state.section2.visualiacionProyectos.map((e, i) => {
                        return parseInt(name[1]) === i ? value : e
                    })
                }
            })
        }
        else {
            this.setState({ section2: { ...this.state.section2, [name[0]]: value } })
        }
    }
    //Cambiar objetos de la seccion #1
    handleSection1Change = (name, value) => {
        this.setState({ section1: { ...this.state.section1, [name]: value } })
    }

    handleSection3Change = (name, value) => {
        this.setState({
            section3: {
                ...this.state.section3, [name]: value
            }
        })
    }

    handleSection4Change = (name, value) => {

    }

    handleSection3ChangeIcons = (name, value) => {
        const objectPositionArray = name.split("-");
        this.setState({
            section3: {
                ...this.state.section3, iconos: this.state.section3.iconos.map((e, i) => {
                    return i === parseInt(objectPositionArray[1]) ? { ...e, [objectPositionArray[0]]: value } : e
                })
            }
        })
    }

    uploadElement = async () => {
        //Varaibles de subida de archivos
        const { header, section1, section2, section3, section5, headers } = this.state;

        // const iconos = section3.iconos;
        var bannerImg = header.imagenActual;
        var uploadedIcons = [];

        //Verificar si el objeto imagen actual de el estado es tiene un archivo
        if (header.imagenActual.hasOwnProperty("name")) {
            await this.uploadImageAsPromise(bannerImg).then(downloadURL => {
                bannerImg = downloadURL;
            })
        }

        //Verifica si cada uno de los íconos contiene un archivo o una URL

        for (const iconFile of this.state.section3.iconos) {
            if (iconFile.img.hasOwnProperty("name")) {
                await this.uploadImageAsPromise(iconFile.img).then(downloadURL => {
                    uploadedIcons.push({ ...iconFile, img: downloadURL })
                })
            } else {
                uploadedIcons.push(iconFile)
            }
        }

        const home = app.database().ref("home/");

        home.set({
            header: { ...header, imagenActual: bannerImg },
            headers: { ...headers },
            section1,
            section2,
            section3: { ...section3, iconos: uploadedIcons },
            section5
        }).then(() => {
            this.props.history.push("/admin/pages")
        })
    }

    uploadImageAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var imageExtention = imageName.splice(imageName.length - 1);
                const storageRef = app.storage().ref(`pages/${uuid() + "." + imageExtention}`)

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(downloadURL => {
                        resolve(downloadURL);
                    })
                })
            }
        })
    }

    render() {
        return (<div className="cc__page_editor-container">
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">HOME</h1>
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Header</h1>
                <StyledInputs
                    value={this.state.header.nombreDePagina}
                    type={"input"}
                    name="nombreDePagina"
                    title="Nom du page"
                    handleChange={this.handleHeaderInputChange}
                />
                <StyledFileUpload
                    name="imagenActual"
                    title="Image Hero"
                    img={this.state.header.imagenActual}
                    onFileChange={this.handleHeaderInputChange} />
            </section>
            <div className="cc__division"></div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Section 1</h1>
                <StyledInputs
                    value={this.state.section1.texto}
                    type="textarea"
                    name="texto"
                    title="Texte"
                    handleChange={this.handleSection1Change}
                />
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_2">
                <h1 className="styled__h2-brown style__align-left">Section 2</h1>
                <div className="cc__row-2itmes">
                    <StyledInputs
                        value={this.state.section2.titulo}
                        type="input"
                        name="titulo"
                        title="Titre"
                        className="first-element"
                        handleChange={this.handleSection2Change}
                    />
                </div>
                <p className="styled__label-brown-16px style__align-left">Avantage</p>
                <div className="cc__row">
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[0]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-0"
                        title="Premier projet"
                        handleChange={this.handleSection2Change}
                    />
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[1]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-1"
                        title="Deuxième projet"
                        handleChange={this.handleSection2Change}
                    />
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[2]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-2"
                        title="Trosiéme projet"
                        handleChange={this.handleSection2Change}
                    />
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[3]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-3"
                        title="Quatrième projet"
                        handleChange={this.handleSection2Change}
                    />
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[4]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-4"
                        title="Cinquième projet"
                        handleChange={this.handleSection2Change}
                    />
                    <StyledInputs
                        value={this.state.section2.visualiacionProyectos[5]}
                        options={this.state.opciones.project}
                        type="select"
                        name="visualiacionProyectos-5"
                        title="Sixième projet"
                        handleChange={this.handleSection2Change}
                    />
                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_3">
                <h1 className="styled__h2-brown style__align-left">Section 3</h1>
                <div className="cc__row-2itmes">
                    <StyledInputs
                        value={this.state.section3.titulo}
                        type="input"
                        name="titulo"
                        title="Titre"
                        className="first-element"
                        handleChange={this.handleSection3Change}
                    />
                    {/* <StyledInputs
                        value={this.state.section2.cantidadDeProyectos}
                        type="input"
                        name="cantidadDeProyectos"
                        title="Nombre de projets"
                        className="second-element"
                        handleChange={this.handleSection3Change}
                    /> */}
                </div>
                <p className="styled__label-brown-16px style__align-left">Ordre de projet</p>
                <div className="cc__row">
                    {
                        this.state.section3.iconos.map((e, i) => {
                            return <div className="cc__item">
                                <StyledFileUpload
                                    stle="_short"
                                    name={"img-" + i}
                                    title={this.orderName[i] + " Icône"}
                                    img={e.img}
                                    onFileChange={this.handleSection3ChangeIcons}
                                />
                                <StyledInputs
                                    value={this.state.section3.iconos[i].titulo}
                                    type="input"
                                    name={"titulo-" + i}
                                    title={this.orderName[i] + " titre"}
                                    handleChange={this.handleSection3ChangeIcons}
                                />
                                <StyledInputs
                                    value={this.state.section3.iconos[i].texto}
                                    type="textarea"
                                    name={"texto-" + i}
                                    title={this.orderName[i] + " texte"}
                                    handleChange={this.handleSection3ChangeIcons}
                                />
                            </div>
                        })
                    }
                </div>
            </section>
            <div className="cc__division"></div>
            <section className="cc__section section_4">
                <h1 className="styled__h2-brown style__align-left">Section 4</h1>
                <div className="cc__row-2itmes">
                    <StyledInputs
                        value={this.state.section5.titulo}
                        type="input"
                        name="titulo"
                        title="Titre"
                        className="first-element"
                        handleChange={(name, value) => {
                            this.setState({ section5: { ...this.state.section5, titulo: value } })
                        }}
                    />
                </div>
                <p className="styled__label-brown-16px style__align-left">Création de commentaires</p>
                <div className="cc__comments">
                    {
                        this.state.section5.comments.map((e, i) => {
                            return <div className="comment-button-edit">
                                <p onClick={() => {
                                    this.setState({ section5: { ...this.state.section5, currentEditing: i } }, () => {
                                        console.log(this.state.section5.currentEditing);
                                        this.setState({ opciones: { ...this.state.opciones, modalOpen: true } })
                                    })
                                }}> {e.nombre} </p>
                                <img src={process.env.PUBLIC_URL + "/icons/x-pink.svg"} alt="" onClick={() => {
                                    var comments = this.state.section5.comments.slice();
                                    comments.splice(i, 1);
                                    console.log(comments);
                                    this.setState({ section5: { ...this.state.section5, comments: comments } })
                                }} />
                            </div>
                        })
                    }
                    <div className="comment-button-add" onClick={() => {
                        this.setState({ opciones: { ...this.state.opciones, modalOpen: true } });
                        var comments = this.state.section5.comments.slice();
                        comments.push({
                            nombre: "Nom du client",
                            text: "mots"
                        })
                        this.setState({
                            section5: {
                                ...this.state.section5,
                                comments: comments
                            }
                        }, () => this.setState({ section5: { ...this.state.section5, currentEditing: this.state.section5.comments.length - 1 } }))
                    }}>
                        <p className="styled__p-white">Ajouter un commentaire</p>
                        <img src={process.env.PUBLIC_URL + "/icons/Plus.svg"} alt="" />
                    </div>
                </div>
                <div className="cc__button-2">
                    <button className="cc__button accept" onClick={() => {
                        console.log("cancelado")
                    }}>Nettoyer</button>
                    <button className="cc__button cancel" onClick={this.uploadElement}>Envoyer</button>
                </div>
            </section>
            {
                (this.state.section5.comments.length > 0 && this.state.opciones.modalOpen) &&
                <StyledModal>
                    <StyledInputs
                        value={this.state.section5.comments[this.state.section5.currentEditing].nombre}
                        type={"input"}
                        name={"nombre"}
                        title={"nom"}
                        handleChange={(name, value) => {
                            this.setState({
                                section5: {
                                    ...this.state.section5, comments: this.state.section5.comments.map((e, i) => {
                                        return i === this.state.section5.currentEditing ? { ...e, [name]: value } : e
                                    })
                                }
                            })
                        }}
                    />
                    <StyledInputs
                        value={this.state.section5.comments[this.state.section5.currentEditing].text}
                        type={"textarea"}
                        name={"text"}
                        title={"text"}
                        handleChange={(name, value) => {
                            this.setState({
                                section5: {
                                    ...this.state.section5, comments: this.state.section5.comments.map((e, i) => {
                                        return i === this.state.section5.currentEditing ? { ...e, [name]: value } : e
                                    })
                                }
                            })
                        }}
                    />
                    <div className="cc__button-2">
                        <button className="cc__button accept" onClick={() => {
                            // const comment = this.state.section5.comments.splice(this.state.section5.currentEditing, 1);
                            this.setState({ opciones: { ...this.state.opciones, modalOpen: false } }, () => {
                                // this.setState({ section5: { ...this.state.section5, comments: comment } })
                            });
                        }}>Nettoyer</button>
                        <button className="cc__button cancel" onClick={() => this.setState({ opciones: { ...this.state.opciones, modalOpen: false } })}>Créer</button>
                    </div>
                </StyledModal>
            }
        </div >);
    }
}

export default PageEditor;


const StyledModal = (props) => {
    return (<div className="cc__modal-container">
        <div className="cc__modal-info">
            {props.children}
        </div>
    </div>);
}
