import React from 'react';
import "./StyledEditable.scss";
import { Link } from 'react-router-dom';

const StyleEditable = ({ imgUrl, bgImg, link, title, checked, id, handleChange }) => {
    return (<div className="StyleEditable" key={id}>
        {
            bgImg &&
            <img className="cc__styled_editable-bg" src={process.env.PUBLIC_URL + bgImg} alt="background_image" />
        }
        <div className="cc__styled_editable-inner">
            {
                imgUrl &&
                <img className="cc__editable-img" src={process.env.PUBLIC_URL + imgUrl} alt="" />
            }
            <p>{title}</p>
        </div>
        <div className="cc__styled_editable-inner">
            {
                handleChange &&
                <label className="cc__styled-checkbox">
                    <input onChange={e => handleChange(e)} name={id} type="checkbox" className="cc__styled-checkbox-input" checked={checked} value={checked} />
                    {/* <span className="checkmark"></span> */}
                    <div className="cc__styled-checkbox-selected"></div>
                </label>
            }
            <Link to={link + (id != null ? id : "")}>
                <img className="cc__editable-icon" src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" />
            </Link>
            <div className="cc__styled_editable-shadow"></div>
        </div>
    </div>);
}

export default StyleEditable;