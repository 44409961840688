import React from "react";
import DropDown from "../../Components/DropDown/DropDown";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import "./Inversion.scss";
import { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import app from "../../Admin/firebaseConfig";
import { useState } from "react";
import MetaTags from "react-meta-tags";
const Inversion = ({ props, isOpen, setvisual }) => {

  const [loaded, setLoaded] = useState(false);
  const [leyes, setLeyes] = useState({});
  const [header, setHeader] = useState({ img: '', titulo: '' })

  useEffect(() => {
    window.scrollTo(0, 0);

    app.database().ref("investissement/").once("value").then(ss => {
      if (ss.val()) {
        setHeader({ img: ss.val().img, titulo: ss.val().titulo })
      }
    })

    app.database().ref("leyes2/").once("value").then(ss => {
      var ley = []
      ss.forEach(child => {
        ley.push(child.val());
      })
      ley.sort((a,b) => a.order > b.order ? 1 : -1);
      return ley;
    }).then((ley) => {
      setLeyes(ley);
      setLoaded(true);
    })
  }, []);

  return (
    <>
      {
        loaded &&
        <div className="inversion__container">
          <div className="wrapper">
            <MetaTags>
              <title>Investissement locatif | Paris & Ile-de-France | C&C</title>
              <meta name="description" content="Constitution de patrimoine, revenus complémentaires, réductions d’impôt, etc. nous vous accompagnons dans votre projet immobilier ● Pour plus d’informations, contactez-nous ►" />
              <meta property="og:title" content="Investissement locatif | Paris & Ile-de-France | C&C" />
            </MetaTags>
          </div>
          <BannerSlide title={header.titulo} img={header.img} />
          <StyledContainer cn="cc-middle" s={10} prlxSize={-10} pdng={50}>
            <div className="inversion__info" style={{ padding: "30px" }}>
              <h2 className="title-h1-brown " style={{ textAlign: "left" }}>
                Se lancer dans <u> l’investissement locatif</u>
              </h2>
              <p>
                <i style={{ color: "#eb0271", fontSize: 29 }}>
                  La pierre, un placement sûr, sécurisé et concret.
            </i>
                <br />
                <br />
                <p style={{ fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px'}}>
                  Rentabilité, constitution de patrimoine, transmission, revenus complémentaires, réductions d’impôt…l’investissement locatif dans le cadre de l’achat d’un appartement, d’une maison, de places de parking, vous permet d’anticiper sur l’avenir, de garantir votre futur, de sécuriser votre présent.
                </p>
          </p>
            </div>
          </StyledContainer>
          <div className="program__map">
            <div className="cc_styled-bar"></div>

            <div className="program__ley-wrapper">
              <Parallax y={[20, -20]}>
                <div className="map_container" style={{ filter: "none !important" }}>
                  {leyes.map((e) => (
                    <DropDown
                      key={e.id}
                      k={e.slug}
                      more={true}
                      // button={"Lancer la simulation"}
                      info={e.description.substring(0, 255).trim() + "..."}
                      title={e.nombre}
                      isOpen={isOpen}
                      // setvisual={setvisual}
                    />
                  ))}
                </div>
              </Parallax>
              <div className="info_venta">
                <h2 className="title-h1-brown">
                  Envie de <u>vous lancer?</u>
                </h2>
                <p style={{ textAlign: "left", fontFamily: 'Poppins, goudy-old-style, serif', fontSize: '22px' }}>
                  Afin de mieux vous conseiller, vous accompagner dans votre projet immobilier, nous vous proposons les principaux dispositifs en vue de vous garantir le meilleur choix d’investissement.
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>);
};

export default Inversion;
