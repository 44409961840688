import * as firebase from 'firebase';
import 'firebase/auth';

const app = firebase.initializeApp({
    apiKey: "AIzaSyCFUdRB8ZJj_mEity4clY7PciIAwUcSsFU",
    authDomain: "conseil-commercialisation.firebaseapp.com",
    databaseURL: "https://conseil-commercialisation.firebaseio.com",
    projectId: "conseil-commercialisation",
    storageBucket: "conseil-commercialisation.appspot.com",
    messagingSenderId: "726353082486",
    appId: "1:726353082486:web:30472624eec56310190757"
});

export default app;