import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import "./PartnersCard.scss";

const useStyles = makeStyles({
    rootPartners: {
        maxWidth: 245,
        position: 'relative',
        margin: 'auto',
        borderRadius: '10px',
    },
    mediaPartnersCard: {
        width: 170,
        height: 170,
        margin: 'auto',
    },
});

export default function MediaCard({props}) {
    const classes = useStyles();

    return (
        // <Card className={classes.rootPartners}>
            <div className={classes.mediaPartnersCard}>
                <div style={{
                    backgroundImage: "url(" + props.image + ")",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'center',
                    borderRadius: '10px',
                    height: '100%',
                    width: '100%',
                    /* top: 0; */
                    /* left: 0; */
                    /* margin: 0px; */
                    /* padding: 0px; */
                    float: 'left',
                    alignItems: 'center',
                    // objectFit: 'cover',
                }}
                >
                </div>
            </div>
        // </Card>
    );
}
