import React from 'react';
import './StyledLoi.scss';

const StyledLoi = ({ nombre, img, checked, value, handleChange, id }) => {
    return (<div className="ley-selected" key={id}>
        <div className="bg-container"></div>
        <div className="left">
            <div className="img__ley-icon">
                <img src={img} alt={nombre} />
            </div>
            <p className="styled__label-brown-16px">{nombre}</p>
        </div>
        <label className="cc__styled-checkbox right">
            <input className="cc__styled-checkbox-input" type="checkbox" name={id} id="" value={value} checked={checked} onChange={e => handleChange(e)} />
            <div className="cc__styled-checkbox-selected"></div>
        </label>
        <div className="shadow"></div>
    </div>);
}

export default StyledLoi;