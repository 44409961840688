import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './adstyle.scss';
import AdminMenu from "./Components/Menu/AdminMenu";
// import { useState } from 'react';
import Logout from './Components/Logout/Logout';
import PageEditor from './Pages/PageEditor';
import Promotores from './Pages/Promotores';
import Leyes from './Pages/Leyes';
import Proyectos from './Pages/Proyectos';
import { AuthProvider } from './AuthContext';
import Login from './Pages/Login/Login';
// import PrivateRoute from './PrivateRoute';
import Certificados from './Pages/Certificados/Index';
import Footer from './Components/Footer/Footer';
import Recover from './Pages/Recover/Recover';

const Admin = ({ props, location }) => {
    // const [prueba, setPrueba] = useState('asdfa');
    // const [selectPrueba, setSelect] = useState(["uno", "dos", "tres"])

    return (
        <AuthProvider>
            <div className="cc__admin-container">
                <Switch>
                    <Route path="/admin/recover" component={Recover} />
                    <Route path="/admin/login" component={Login} />
                    <Route path="/admin" component={AdminMenu} />
                </Switch>
                <div className="cc__admin-info_wrapper">
                    {/* {location.pathname} */}
                    {(location.pathname !== "/admin/login" && location.pathname !== "/admin/recover") && <Logout />}
                    <PageEditor />
                    <Promotores />
                    <Leyes />
                    <Proyectos />
                    <Certificados />
                </div>
            </div>
            <Route path="/admin" component={Footer} />
        </AuthProvider>
    );
}

export default Admin;
