import React, { useState } from "react";
import "./DropDown.scss";
import {Link, NavLink} from "react-router-dom";
import { useRef } from "react";

const DropDpwn = (props) => {
  const info = useRef(null);
  const container = useRef(null);

  const [height, setHeight] = useState(0);
  const [collapsed, setCollapsed] = useState(true);

  function handleOpen() {
    var infoContainer = info.current;
    if (collapsed) {
      setHeight(infoContainer.offsetHeight + "px");
    } else {
      setHeight("0px");
      setCollapsed(true);
    }
    setCollapsed(!collapsed);
  }

  function focusOut() {
    setTimeout(function () {
      setHeight("0px");
      setCollapsed(true)
    }, 100);
    ;
  }

  return (
    <div className="dropdown" onClick={() => handleOpen()}
      onBlur={focusOut}>
      <button className={collapsed ? "dropdown__title close" : "dropdown__title open"}>
        <h3>{props.title}</h3>
        <img className="plus" src={process.env.PUBLIC_URL + "/icons/Group 83.svg"} alt="plus gris" title="plus gris" />
        <img className="plus-hover" src={process.env.PUBLIC_URL + "/icons/Group 277.svg"} alt="plus" title="plus" />
        <img className="minus" src={process.env.PUBLIC_URL + "/icons/Group 52.svg"} alt="ligne haut" title="ligne haut"/>
      </button>
      <div
        className={collapsed ? "collapsable collapsed" : "collapsable"}
        ref={container}
        style={{ maxHeight: height }}>
        <div className="info" ref={info}>
          <p>
            {props.info}
            {props.more ? <Link to={{ pathname: "/lois/" + props.k }}>Voir plus</Link> : ""}
          </p>
          {buttons(props)}
        </div>
      </div>
    </div>
  );
};

const buttons = (props) => {
  if (props.setvisual) {
    return <span onClick={props.setvisual}>{props.button}</span>;
  } else if (props.button) {
    return (
      <span>
        <NavLink to="/contact" style={{ fontSize: 14, color: "#EB1A71" }}>{props.button}</NavLink>
      </span>
    );
  } else {
    return "";
  }
};

export default DropDpwn;
