import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from "../../../firebaseConfig";
import uuid from 'react-uuid';

class Editar extends Component {
    // state = {}
    constructor(props) {
        super(props);
        this.state = {
            changed: false,
            uploadInfo: {
                link: "",
                nombre: "",
                description: "",
                logo: null,
                id: ""
            }, loading: false
        }
    }
    componentDidMount() {
        app.database().ref(`/promotores/${this.props.match.params.id}`).once("value").then(ss => {

            if (ss.val()) {
                this.setState({ uploadInfo: ss.val() })
            } else {
                //Enviar a mensaje de que no se encontró la página
                console.log("No se ha encontrado nada");
            }
        })
    }

    handleInputChange = (name, value) => {
        this.setState({ uploadInfo: { ...this.state.uploadInfo, [name]: value } })
    }


    cancelUpload = () => {
        this.props.history.push("/admin/promotor");
    }

    handleUpload = () => {
        var task = null;
        this.setState({ loading: true })
        const db = app.database();
        if (this.state.uploadInfo.logo !== null && typeof this.state.uploadInfo.logo !== "string") {

            const imageName = this.state.uploadInfo.logo.name.split(".");
            var imageExtention = imageName.splice(imageName.length - 1);
            var storageRef = app.storage().ref(`promotores/${uuid() + "." + imageExtention}`);
            task = storageRef.put(this.state.uploadInfo.logo).then((ss) => {
                ss.ref.getDownloadURL().then(url => {
                    db.ref('promotores/' + this.state.uploadInfo.id).set({ ...this.state.uploadInfo, logo: url })
                        .then(() => {
                            this.setState({ loading: false })
                            this.props.history.push("/admin/promotor");
                        });
                });
            });
        } else {
            db.ref('promotores/' + this.state.uploadInfo.id).set(this.state.uploadInfo).then(() => {
                this.props.history.push("/admin/promotor");
            });
        }
    }

    render() {
        return (<div className="cc__page_editor-container">
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">PROMOTEURS</h1>
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Promoteur</h1>
                <StyledInputs
                    value={this.state.uploadInfo.nombre}
                    type={"input"}
                    name="nombre"
                    title="Nom du promoteur"
                    handleChange={this.handleInputChange}
                />
                <StyledInputs
                    value={this.state.uploadInfo.link}
                    type={"input"}
                    name="link"
                    title="URL"
                    handleChange={this.handleInputChange}
                />
                <StyledFileUpload
                    name="logo"
                    title="Image Hero"
                    img={this.state.uploadInfo.logo}
                    onFileChange={this.handleInputChange} />
                <StyledInputs
                    value={this.state.uploadInfo.description}
                    type={"textarea"}
                    name="description"
                    title="Description"
                    handleChange={this.handleInputChange}
                />
                <div className="cc__button-2">
                    <button className="cc__button accept" onClick={this.cancelUpload}>Nettoyer</button>
                    <button className="cc__button cancel" onClick={() => this.handleUpload()}>Envoyer</button>
                </div>
            </section>
            <div className="cc__division"></div>
        </div>);
    }
}

export default Editar;