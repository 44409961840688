import React, {Component} from "react";
import "./Home.scss";
import Slider from "../Components/Slider/Slider";
import {Parallax} from "react-scroll-parallax";
import Experience from "../Experience/Experience";
import MetaTags from 'react-meta-tags';
import StyledContainer from "../Components/StyledContainer/StyledContainer";
import SearchBar from "../Components/SearchBar/SearchBar";
import app from "../Admin/firebaseConfig";
import {withRouter} from 'react-router-dom';
import SSlider from "react-slick";
import TextSlider from "../Components/TextSlider/TextSlider";
import AltCard from "../Components/GridCards/GridCard";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loads: props.loadPrograms ? props.loadPrograms : 1,
            rows: 0,
            loaded: false,
            imgCarousel: [],
            partners: [],
            clients: [],
            currentClientIndex: 0,
            form: {
                name: "",
                email: "",
                phone: 0,
                comment: "",
            },
            projectList: [],
            finishedLoading: false,
            currentImage: this.props.img,
            position: 0,
            seconds: parseInt(props.startTimeInSeconds, 10) || 0,
        };
    }

    handleRightClickClient = () => {
        if (this.state.currentClientIndex >= 0 && this.state.currentClientIndex + 1 < this.state.section5.comments.length ) {
            this.setState({currentClientIndex: this.state.currentClientIndex + 1});
        } else {
            this.setState({currentClientIndex: 0});
        }
    };

    handleLeftClickClient = () => {
        if (this.state.currentClientIndex - 1 < 0) {
            this.setState({currentClientIndex: this.state.section5.comments.length - 1});
        } else {
            this.setState({currentClientIndex: this.state.currentClientIndex - 1});
        }
    };

    handleInput = (evt) => {
        const value = evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value,
        });
    };

    handleEmailSending = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("name", this.state.form.name);
        data.append("phone", this.state.form.phone);
        data.append("comment", this.state.form.comment);
        data.append("email", this.state.form.email);

        fetch("./mailing.php", {
            method: "POST",
            body: data,
        }).then((res) => {
            return res.text();
        }).then((b) => {})
            .catch((err) => {});
    };

    componentDidMount() {
        this.loading();
    }

    redirect(url) {
        window.open(url, '_blank');
    }

    download(purl, ref) {
        var storage = app.storage().ref();
        // var httpsReference = storage.refFromURL(purl);
        var self = this
        storage.child(ref).getDownloadURL().then(function(url) {
            self.redirect(url)
        }).catch(function(error) {
            console.log(error);
        });
    }

    promoteurOrder = () => {
        //Obtengo todas las ID's de los elementos seleccionados
        return new Promise((resolve, reject) => {
            // var promotores = [];
            app.database().ref("promoteurOrder/").once("value").then(ss => {
                resolve(ss.val());
            })
        })
    }

    loading = async () => {
        const projects = app.database().ref("projects/");
        const projetList = [];

        //Obteniendo los proyectos
        await projects.once("value").then(ss => {
            ss.forEach(child => {
                projetList.push({
                    ...child.val(),
                    img: child.val().imgs ? child.val().imgs[0] : null
                });
            })
        }).then(() => {
            this.setState({projectList: projetList})
        });

        //Obteniendo las imagenes de los promotores
        var pOr = this.promoteurOrder();
        pOr.then(async promotores => {
            var promotoresSeleccionados = [];
            for (const id of promotores) {
                await app.database().ref("promotores/").child(id).once("value").then(promotor => {
                    promotoresSeleccionados.push({image: promotor && promotor.val() && promotor.val().logo});
                })
            }
            return promotoresSeleccionados;
        }).then(ps => {
            this.setState({partners: ps});
        });

        var home = new Promise((resolve, reject) => {
            app.database().ref("home/").once("value").then(ss => {
                if (ss.val()) {
                    resolve(ss.val());
                }
                reject("Ha habido un error");
            });
        })

        home.then(async homeValues => {
            // console.log(homeValues);
            this.setState({
                header: homeValues.header,
                headers: homeValues.headers,
                section1: homeValues.section1,
                section3: homeValues.section3,
                section2: homeValues.section2,
                section5: homeValues.section5
            });

            if (homeValues.section2.visualiacionProyectos) {
                var idProyectos = homeValues.section2.visualiacionProyectos;
                var objetoProyectos = []
                for (const proyecto of idProyectos) {
                    await app.database().ref("projects/").child(proyecto).once("value").then(ss => {
                        objetoProyectos.push(ss.val())
                    })
                }
                return objetoProyectos;
            }
        }).then(section2Values => {
            this.setState({imgCarousel: section2Values})
            this.setState({loaded: true});
        })

        // Compose projects for patchwork
        this.loadingProjects(projects, projetList);
    }

    shuffleArray = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    loadingProjects = (projects, projetList) => {
        this.shuffleArray(projetList)
        this.setState(
            {
                allPrograms: projetList,
                filteredImages: projetList,
                rows: Math.ceil(projetList.length / 7)
            },
            () => {

                this.setState({loads: this.props.loadPrograms ? this.props.loadPrograms : 1});
            })
    }

    /*Cambia la cantidad de recuadros cargados*/
    setLoadPageOnMain = (programId) => {
        this.props.history.push(programId)
    }

    bloqueDePrograma = (el, masonryStyle) => {
        return <div onClick={() => this.setLoadPageOnMain("/programme" + el.slug)}
                    key={el.id}
                    className={"cc_masonry-grid " + masonryStyle}>
            <figure>
                <img src={el.imgs[0].imgURL} alt={el.title} title={el.title}/>
            </figure>
            <div className="card__info-container">
                <h3>{el.title}</h3>
                <p className="card__info__title">
                    <img src={"/icons/Group 427.svg"} alt={el.title} title={el.title} className="location-input"/>
                    {el.lugar + " ( " + el.zipCode + " )"}
                </p>
            </div>
        </div>
    }

    showMore = () => {
        if (this.state.loads < 3) {
            this.setState({loads: this.state.loads + 1});
        }
    }

    gridByChunks = (chuckQuantity) => {

        const rows = [];
        var masonryStyle = ''
        var elementCount = this.state.filteredImages.slice(7 * (this.state.loads - 1), 7 * this.state.loads);

        if (elementCount.length === 3) {
            masonryStyle += "item-3"
        } else if (elementCount.length === 2) {
            masonryStyle += "item-2"
        } else if (elementCount.length === 1) {
            masonryStyle += "item-1"
        } else {
            masonryStyle = "items";
        }

        for (let i = 0; i < chuckQuantity; i++) {
            var msSt = masonryStyle;
            rows.push(
                <div className="cc_masonry-container" key={i}>
                    {
                        this.state.filteredImages.map((e, ind) => {
                            var mayorA = ind >= (7 * i);
                            var menorA = ind < (7 * i) + 7;
                            if (mayorA && menorA) {
                                if (chuckQuantity > i + 1) {
                                    msSt = "items";
                                }
                                return this.bloqueDePrograma(e, msSt)
                            }
                            return;
                        })
                    }
                </div>
            )
        }

        return rows;
    }

    renderArrows = () => {
        return (
            <div className="slider-arrow">
                <div
                    className="arrow-btn prev"
                    onClick={() => this.slider.slickPrev()}>
                    <img
                        className="left"
                        src={process.env.PUBLIC_URL + "/icons/arrowleft.png"}
                        alt="fleche gauche"
                        title="fleche gauche"
                    >
                    </img>
                </div>
                <div
                    className="arrow-btn next"
                    onClick={() => this.slider.slickNext()}>
                    <img
                        className="right"
                        src={process.env.PUBLIC_URL + "/icons/arrowright.png"}
                        alt="fleche droite"
                        title="fleche droite"
                    ></img>
                </div>
            </div>
        );
    };

    render() {

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            height: 200,
            lazyLoad: true,
        };

        return (
            <>{
                this.state.loaded &&
                <div className="home">
                    <div className="wrapper">
                        <MetaTags>
                            <title>Programmes immobilers neufs Paris & Ile-de-France | C&C</title>
                            <meta name="description" content="Conseil & Commercialisation ● Spécialiste du neuf pour la résidence principale et l’investissement locatif ● Appartements & maisons ● Paris & Ile-de-France ● Contactez-nous." />
                            <meta property="og:title" content="Programmes immobilers neufs Paris & Ile-de-France | C&C" />
                        </MetaTags>
                    </div>
                    <div className="sslider__container">
                        {this.renderArrows()}
                        <SSlider {...settings} ref={c => (this.slider = c)}>
                            {this.state.headers && this.state.headers.length > 0 && this.state.headers.map((header, index) => {
                                return <div  className="slide" key={index}>
                                    <div className="slide_image" style={{backgroundImage: `url(${header.image})`}}></div>
                                    <p className="slide_text">{header.title}</p>
                                </div>
                            })}
                        </SSlider>
                    </div>
                    <SearchBar className="cc-search-bar-home" villeOptions={this.state.projectList}/>
                    <StyledContainer cn="cc-no-container" s={30} prlxSize={0} styledBar={1}>
                        <div className="current_projects_wrapper_no_container">
                            <h1
                                className="title-h1-brown left-align border-bot-pink-1"
                                style={{textAlign: "left"}}>
                                Programmes <u>en Vue</u>
                            </h1>
                            {
                                this.state.imgCarousel.length > 0 &&
                                <Slider
                                    imgs={this.state.imgCarousel}
                                    imgLenght={3}
                                    noArrows={true}
                                    sliderType={"PLACE"}/>
                            }
                        </div>
                    </StyledContainer>

                    {/** See all new projects */}
                    <a href="/programmes">
                        <button className="get_programs spaced"> Tous les programmes neufs</button>
                    </a>

                    {this.gridByChunks(this.state.loads)}

                    {this.state.loads < this.state.rows ? <button onClick={() => {
                        this.showMore()
                    }} className="get_programs"> Voir plus des Programmes Neufs</button> : ''}

                    <div className="home__message">
                        <h1 className="title-h1-brown">
                            <img
                                src={process.env.PUBLIC_URL + "/icons/Group 557.svg"}
                                alt="tilde"
                                title="tilde"
                            />{this.state.section1.texto}
                        </h1>
                    </div>

                    <button
                        className="get_programs spaced"
                        onClick={(e) => this.download(
                            "https://firebasestorage.googleapis.com/v0/b/conseil-commercialisation.appspot.com/o/attachment%2Fplaquette-c-et-c.pdf?alt=media&token=ae6b9516-8e3c-47e0-bf93-97c5cc37fc5d",
                            "/attachment/plaquette-c-et-c.pdf"
                        )}
                    >
                        Téléchargez la plaquette du Groupe C&C
                    </button>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <section className="ventajas__container" style={{marginTop: 260}}>
                        <Parallax
                            y={[20, -30]}
                            className={"img-ses"}
                            styleInner={{position: "absolute", top: 0, right: 0}}>
                            <img
                                alt="novas-inside"
                                title="novas-int"
                                src={process.env.PUBLIC_URL + "/img/novas_int_hd.png"}
                            />
                        </Parallax>

                        <Parallax y={[-10 + "%", 5]}>
                            <div className="ventajas__info-container">
                                <h1 className="title-h1-brown border-bot-pink-2">
                                    Les avantages <u>du neuf</u>
                                </h1>
                                <div className="ventajas__items-container">
                                    {
                                        this.state.section3.iconos.map( (e , index) =>{
                                            return <div className="ventajas__item-container" key={index}>
                                                <img
                                                    alt="avantage-icone"
                                                    title="avantage-icone"
                                                    className="ventajas__img"
                                                    src={e.img}>
                                                </img>
                                                <h2 className="title-h2-brown">{e.titulo}</h2>
                                                <p>{e.texto}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </Parallax>
                    </section>
                    <StyledContainer s={30} prlxSize={0}>
                        <div className="current_projects-wrapper partners">
                            <h1
                                className="title-h1-brown left-align border-bot-pink-1"
                                style={{textAlign: "left"}}>
                                Ils nous <br/>
                                font <u>confiance </u>
                            </h1>
                            {this.state.partners && this.state.partners.length > 0 &&
                                <AltCard partners={this.state.partners}></AltCard>
                            }
                        </div>
                    </StyledContainer>
                    <section className="comments__container">
                            {this.state.section5 && this.state.section5.comments && this.state.section5.comments.length > 0 &&
                            <>
                                <TextSlider className="text__slider" comments={this.state.section5.comments} title={["Les mots de ", <br/>, <u>nos clients</u>]} />
                            </>}
                    </section>

                    <section className="social">
                        <a href="https://www.instagram.com/conseiletcommercialisation/">
                            <img
                                alt="instagram"
                                title="instagram"
                                src={process.env.PUBLIC_URL + "/icons/Path 353.svg"}
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/conseil-&-commercialisation/">
                            <img
                                alt="facebook"
                                title="facebook"
                                src={process.env.PUBLIC_URL + "/icons/linkedin1.svg"}
                            />
                        </a>
                        <a href="https://www.youtube.com/channel/UCGF34IXsBuDitYGsvfihr6g">
                            <img
                                alt="youtube"
                                title="youtube"
                                src={process.env.PUBLIC_URL + "/icons/youtube1.svg"}
                            />
                        </a>
                    </section>
                </div>
            }</>);
    }
}

export default withRouter(Home);
