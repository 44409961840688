import React, {Component} from "react";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import SlideShow from "../../Components/SlideShow/SlideShow";
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import "./Program.scss";
import GMap from "../../Components/GMap/GMap";
import {Parallax} from "react-scroll-parallax";

import {Helmet} from 'react-helmet';

import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// import MuiAlert from "@material-ui/lab/Alert";
import app from "../../Admin/firebaseConfig";

import AltCard from "../../Components/GridCards/GridCard";

const trismestre = (n) => {
    if (n <= 3) {
        return "1er";
    } else if (n > 3 && n <= 6) {
        return "2e";
    } else if (n > 6 && n <= 9) {
        return "3e";
    } else if (n > 9) {
        return "4ème";
    }
};
const addSpace = (text) => {
    if (text) {
        var spacedText = [];
        var arrayedText = text.toString().split("");
        arrayedText = arrayedText.reverse();
        for (let i = 0; i < arrayedText.length; i++) {
            if (i % 3 === 0 && i !== 0) {
                spacedText.push(" ");
            }
            spacedText.push(arrayedText[i]);
        }
        return spacedText.reverse().join("");
    }
};

class Program extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            redirectUrl: "",
            currentProgram: {
                id: 2,
                title: '',
                zipCode: 0,
                lugar: "",
                currentState: "",
                housing: {
                    tipo: "",
                    quantity: [0, 0],
                    pieces: [0, 0]
                },
                folder: '',
                pdf: '',
                piezas: 0,
                maxPrice: 0,
                minPrice: 0,
                startProject: {month: 11, year: 2020},
                leyes: [],
                description: "",
                detailsSalesOffice: "",
                imgs: [],
                dirProject: {
                    name: " ",
                    dir: "0,0"
                },
                dirSell: {
                    name: " ",
                    dir: "0,0"
                },
                atentionHour: {
                    cita: false,
                    weekend: {
                        start: '14:00', end: '19:00'
                    },
                    weekday: {
                        start: '10:00', end: ' 19:00'
                    }
                },
                phone: "",
                promotor: {
                    logo: '',
                    description: ""
                },
                nearProject: []
            },
            recomendations: [],
            name: "",
            email: "",
            phone: "",
            open: false,
            toastM: "",
            toastMS: "warning",
            modalOpen: false,
            loaded: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let projects = {};

        console.log(this.props);

        // if program id !
        if(this.props.match.params.programId) {
            projects = app.database().ref("projects/").child(this.props.match.params.programId);
        }

        // if slug
        if (this.props.match.params.location && this.props.match.params.name) {
            const slug = "/"+this.props.match.params.location+"/"+this.props.match.params.name;
            projects = app.database().ref().child("projects")
                            .orderByChild("slug")
                            .equalTo(slug);
        }

        console.log(projects);

        projects.once("value").then(async ss => {
            if (ss.val()) {
                const firstKey = Object.keys(ss.val())[0]
                let programObj = ss.val()

                if (typeof firstKey == "string" && firstKey.startsWith('-')) {
                    programObj = ss.val()[firstKey]
                }

                this.setState({
                    currentProgram: {
                        ...programObj,
                        nearProject: programObj.nearProject ? programObj.nearProject : []
                    }, loaded: true
                }, () => {
                    if (this.state && this.state.currentProgram && this.state.currentProgram.promotor && (typeof this.state.currentProgram.promotor === "string")) {
                        app.database().ref("promotores/").child(this.state.currentProgram.promotor)
                            .once("value").then((ss) => {
                            // this.setState({currentProgram: { ...this.state.currentProgram, promotor: ss.val()})
                            this.setState({currentProgram: {...this.state.currentProgram, promotor: ss.val()}})
                        })
                    }
                });
                var nearProjects = []
                if (programObj.hasOwnProperty("nearProject")) {
                    for (const id of programObj.nearProject.visualiacionProyectos) {
                        await app.database().ref("projects/").child(id).once("value").then(ss => {
                            nearProjects.push(ss.val());
                        })
                    }
                }
                return nearProjects;
            } else {
                this.props.history.push("/programmes");
                return false;
            }
        }).then(near => {
            if (!near)
                return;
            near = near.filter(function (el) {
                return el != null;
            });
            if (near && near.length > 0) {
                this.setState({
                    currentProgram: {
                        ...this.state.currentProgram,
                        nearProject: near
                    }
                });
            }
        })
    }

    componentDidUpdate(lastProps) {
        if (lastProps.match !== this.props.match) {
            this.setState({loaded: false});
            window.scrollTo(0, 0);

            let projects = {};

            if(this.props.match.params.programId) {
                projects = app.database().ref("projects/").child(this.props.match.params.programId);
            }

            if (this.props.match.params.location && this.props.match.params.name) {
                const slug = "/"+this.props.match.params.location+"/"+this.props.match.params.name;
                console.log(slug);
                projects = app.database().ref().child("projects").orderByChild("slug")
                    .equalTo(slug);
            }

            // const projects = app.database().ref("projects/").child(this.props.match.params.programId);
            projects.once("value").then(async ss => {
                const firstKey = Object.keys(ss.val())[0]
                let programObj = ss.val()

                if (typeof firstKey == "string" && firstKey.startsWith('-')) {
                    programObj = ss.val()[firstKey]
                }

                if (programObj) {
                    this.setState({
                        currentProgram:
                            {
                                ...programObj,
                                nearProject: programObj.nearProject ? programObj.nearProject : []
                            }, loaded: true
                    });

                    var nearProjects = []
                    if (programObj.hasOwnProperty("nearProject")) {
                        for (const id of programObj.nearProject.visualiacionProyectos) {
                            await app.database().ref("projects/").child(id).once("value").then(ss => {
                                nearProjects.push(ss.val());
                            })
                        }
                    }
                    return nearProjects;
                } else {
                    this.props.history.push("/programmes");
                }
            }).then(near => {
                if (near.length > 0) {
                    this.setState({currentProgram: {...this.state.currentProgram, nearProject: near}});
                } else {
                    console.log('on process')
                }
            })
        }
    }

    getDistance = (xA, xB, yA, yB) => {
        var xDiff = xA - xB;
        var yDiff = yA - yB;
        return Math.sqrt(xDiff * xDiff + yDiff + yDiff);
    };

    housing = (e) => {
        // console.log(e);
        if (e) {
            var housingString = "";// = (e.pieces[0] === 1 ? "Studio au " : e.pieces[0] + " à ") + e.pieces[1] + " pieces";
            if (e.pieces[0] === 0) {
                housingString += ""
            } else if (e.pieces[0] <= 1) {
                housingString += "Du Studio au "
            } else {
                housingString += "Du " + e.pieces[0] + " au ";
            }
            housingString += e.pieces[1] + " pièces"
            if (Array.isArray(e.pieces)) {
                var casa = ["maisons", "appartements"];
                e.quantity.forEach((el, i) => {
                    if (el > 0)
                        housingString += ", " + el + " " + casa[i];
                });
            } else {
                housingString += ", " + e.quantity + " " + e.tipo;
            }
            return housingString;
        }
    };
    handleInputChange = (e) => {
        // console.log(e.target.value);
        this.setState({
            data: {...this.state.data, [e.target.name]: e.target.value},
        });
    };

    sendEmail = async () => {
        if (this.state.name === "" || this.state.email === "") {
            this.setState({
                toastM: "Vous devez saisir toutes vos données",
                toastMS: "warning",
            }, () => {
                this.handleClick();
            });

        } else {
            //ALL Correct proceed to send mail
            const contactUrl = "https://conseil-commercialisation.immo/api/mail/blueprint";
            const postData = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                blueprint: this.state.currentProgram.title,
                programId: this.props.match.params.programId
            };
            try {
                let serverResponse = await axios.post(contactUrl, postData);
                if (serverResponse.data.status === "ok") {
                    this.setState({
                        toastM: "Votre message a été envoyé",
                        toastMS: "success",
                    }, () => {
                        this.handleClick();
                    });
                } else {
                    this.setState({
                        toastM:
                            "Une erreur s'est produite lors du traitement de votre message",
                        toastMS: "error",
                    }, () => {
                        this.handleClick();
                    });
                }
            } catch (e) {
                this.setState({
                    toastM:
                        "Une erreur s'est produite lors du traitement de votre message",
                    toastMS: "error",
                }, () => {
                    this.handleClick();
                });
            }
        }
    }
    //TOAST CONFIG

    useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));
    //const classes = useStyles();
    //const [open, setOpen] = React.useState(false);
    setOpen = (what) => {
        this.setState({open: what});
    };

    handleClick = () => {
        this.setOpen(true);
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setOpen(false);
    };

    openModal = (value = true) => {
        this.setState({openModal: value});
    }

    redirect(url) {
        window.open(url, '_blank');
    }

    download(purl, ref) {
        var storage = app.storage().ref();
        // var httpsReference = storage.refFromURL(purl);
        var self = this
        storage.child(ref).getDownloadURL().then(function(url) {
            self.redirect(url)
        }).catch(function(error) {
            console.log(error);
        });
    }

    render() {
        // console.log(this.state.currentProgram);
        return (<>
                {!this.state.loaded || this.state.currentProgram == undefined ? <p>loading</p> :
                    <div className="program__container">
                        <Helmet>
                            <meta name="twitter:title" content="C&C - Conseil et commercialisation"/>
                            <meta property="og:title" content="C&C - Conseil et commercialisation"/>
                            <meta property="og:description" content={this.state.currentProgram.description}/>
                            <meta property="og:image" content={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"}/>
                            <title>{this.state.currentProgram.title} - {this.state.currentProgram.lugar + " (" + this.state.currentProgram.zipCode + ")"}</title>
                            <meta name="twitter:description" content={this.state.currentProgram.description}/>
                            <meta name="description" content={this.state.currentProgram.description}/>
                        </Helmet>
                        <BannerSlide
                            title={this.state.currentProgram.title}
                            img={process.env.PUBLIC_URL + "/img/header_program_inside.png"}
                            heigh="60vh"
                            subtitle={
                                this.state.currentProgram.lugar +
                                " (" +
                                this.state.currentProgram.zipCode +
                                ")"
                            }
                        />
                        <StyledContainer cn="cc-left" pdng="60px" prlxSize={-3} s={3}>
                            <div className="program__info-container">
                                <div className="program__info-data">
                                    <h2 className={"subtitle-h1-like-brown"}>
                                        {this.state.currentProgram.title} à {this.state.currentProgram.lugar}
                                    </h2>
                                    <div className="subtitle-h2-like-pink-i">
                                        {this.housing(this.state.currentProgram.housing)}
                                    </div>
                                    <div className="title-h2-brown-i left-align top-margin">
                                        À partir de {addSpace(this.state.currentProgram.minPrice)} €
                                    </div>
                                    <p className="program-status">
                                        {" "}
                                        {this.state.currentProgram.currentState}
                                    </p>
                                    <div className="program__info-data__calendar">
                                        <img
                                            src={process.env.PUBLIC_URL + "/icons/Group 54.svg"}
                                            alt="calendar-icon"
                                            title="calendar-icon"
                                        />
                                        <span className="small-span-b">
                                        {trismestre(this.state.currentProgram
                                                        && this.state.currentProgram.startProject
                                                        && this.state.currentProgram.startProject.month)}{" "}
                                            trimestre {
                                                this.state.currentProgram
                                                && this.state.currentProgram.startProject
                                                && this.state.currentProgram.startProject.year
                                            }
                                    </span>
                                    </div>
                                    {this.state.currentProgram && this.state.currentProgram.detailedDescriptionResidence &&
                                    this.state.currentProgram.detailedDescriptionResidence != "" &&
                                    this.state.currentProgram.detailedDescriptionResidence.trim().length > 0 &&
                                        <div className="description_title">
                                            <h3>La résidence</h3>
                                            <p
                                                style={{color: "#5c5c5c"}}
                                                dangerouslySetInnerHTML={{
                                                __html: this.state.currentProgram.detailedDescriptionResidence,
                                                }}>
                                            </p>
                                        </div>
                                    }
                                    {this.state.currentProgram && this.state.currentProgram.detailedDescriptionPlace &&
                                    this.state.currentProgram.detailedDescriptionPlace != "" &&
                                    this.state.currentProgram.detailedDescriptionPlace.trim().length > 0 &&
                                    <div>
                                        <h3 className="description_title">Le lieu</h3>
                                        <p
                                            style={{color: "#5c5c5c"}}
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.currentProgram.detailedDescriptionPlace,
                                            }}>
                                        </p>
                                    </div>
                                    }
                                    {this.state.currentProgram && this.state.currentProgram.detailedDescriptionPros &&
                                    this.state.currentProgram.detailedDescriptionPros != "" &&
                                    this.state.currentProgram.detailedDescriptionPros.trim().length > 0 &&
                                    <div>
                                        <h3 className="description_title">Les avantages</h3>
                                        <p
                                            style={{color: "#5c5c5c"}}
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.currentProgram.detailedDescriptionPros,
                                            }}>
                                        </p>
                                    </div>
                                    }
                                    {this.state.currentProgram && this.state.currentProgram.detailedDescriptionPrices &&
                                    this.state.currentProgram.detailedDescriptionPrices != "" &&
                                    this.state.currentProgram.detailedDescriptionPrices.trim().length > 0 &&
                                    <div>
                                        <h3 className="description_title">Grille tarifaire</h3>
                                        <p
                                            style={{color: "#5c5c5c"}}
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.currentProgram.detailedDescriptionPrices,
                                            }}>
                                        </p>
                                    </div>
                                    }
                                    {!(this.state.currentProgram && this.state.currentProgram.detailedDescriptionResidence &&
                                    this.state.currentProgram.detailedDescriptionResidence != "" &&
                                    this.state.currentProgram.detailedDescriptionResidence.trim().length > 0) && this.state.currentProgram && this.state.currentProgram.description &&
                                    <p
                                        style={{color: "#5c5c5c"}}
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.currentProgram.description,
                                        }}></p>
                                    }
                                    <div className="lois">
                                        {this.state.currentProgram && this.state.currentProgram.leyes
                                        && this.state.currentProgram.leyes.map((e) => {
                                            return e.checked && <img src={e.imgURL} alt={e.title}/>
                                        })}
                                    </div>
                                    {/* <img src={ process.env.PUBLIC_URL + "/icons/Path 51.svg"} alt=""/> */}
                                    {this.state.currentProgram.pdf ? (
                                        <div>
                                            <button
                                                className={"desktop download"}
                                                onClick={(e) => this.download(this.state.currentProgram.pdf.url, this.state.currentProgram.pdf.fileRef)}>
                                                Télécharger la Brochure
                                            </button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <SlideShow
                                    imgs={this.state.currentProgram.imgs}
                                    folder={this.state.currentProgram.folder}
                                    openModal={this.openModal}
                                />
                                {/*<div className="slider__container">*/}
                                {/*    <SSlider {...settings}>*/}
                                {/*        {this.state.currentProgram.imgs.map((image) => {*/}
                                {/*            return <h3><img src={image.imgURL}/></h3>*/}
                                {/*        })}*/}
                                {/*    </SSlider>*/}
                                {/*</div>*/}
                                {this.state.currentProgram.pdf ? (
                                        <div className={"responsive"}>
                                            <button
                                                className={"download"}
                                                onClick={(e) => this.download(this.state.currentProgram.pdf.url, this.state.currentProgram.pdf.fileRef)}>
                                                Télécharger la Brochure
                                            </button>
                                        </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </StyledContainer>
                        <div className="program__interested-form">
                            <div className="subtitle-h2-like-pink-i title-h2-pink">Ce projet vous intéresse ?</div>
                            <form action="" onSubmit={(e) => e.preventDefault()}>
                                <div className="form__group field">
                                    <input
                                        type="input"
                                        className="form__field"
                                        placeholder="Name"
                                        name="name"
                                        id="name"
                                        required
                                        onChange={(e) => this.setState({name: e.target.value})}
                                    />
                                    <label htmlFor="name" className="form__label">
                                        Nom / Prénom *
                                    </label>
                                </div>
                                <div className="form__group field">
                                    <input
                                        type="input"
                                        className="form__field"
                                        placeholder="Email"
                                        name="email"
                                        id="email"
                                        required
                                        onChange={(e) => this.setState({email: e.target.value})}
                                    />
                                    <label htmlFor="email" className="form__label">
                                        Email *
                                    </label>
                                </div>
                                <div className="form__group field">
                                    <input
                                        type="input"
                                        className="form__field"
                                        placeholder="Téléphone"
                                        name="phone"
                                        id="phone"
                                        required
                                        onChange={(e) => this.setState({phone: e.target.value})}
                                    />
                                    <label htmlFor="email" className="form__label">
                                        Téléphone
                                    </label>
                                </div>
                                <button type="button" onClick={this.sendEmail}>
                                    Recevoir les Plans
                                </button>
                            </form>
                        </div>
                        <div className="program__map">
                            <div className="cc_styled-bar"></div>
                            <div className="program__map-wrapper">
                                <div className="map_container">
                                    {/* <GoogleMap markPosition={this.state.currentProgram.dirProject} /> */}
                                    {/*<MapBox marker={this.state.currentProgram.dirProject} />*/}
                                    <GMap className="map_block" marker={this.state.currentProgram.dirProject}></GMap>
                                </div>
                                <div className="info_venta">
                                    <h2 className="subtitle-h1-like-brown">
                                        Visitez notre{" "}
                                        <u>
                                            <br/>
                                            Bureau de Vente
                                        </u>
                                    </h2>
                                    <p style={{color: "#5c5c5c"}}>
                                        {this.state.currentProgram.detailsSalesOffice &&
                                            this.state.currentProgram.detailsSalesOffice.trim().length > 0 &&
                                            this.state.currentProgram.detailsSalesOffice
                                        }
                                    </p>
                                    <div className="contact_data-container">
                                        <div className="contact_data-item">
                                            <img
                                                src={process.env.PUBLIC_URL + "/icons/Group 558.svg"}
                                                alt="project-direction"
                                                title="project-direction"
                                            />
                                            <div className="contanct_data-info">
                                                {this.state.currentProgram &&
                                                 this.state.currentProgram.atentionHour &&
                                                 this.state.currentProgram.atentionHour.cita ? (
                                                    <div className="time">
                                                        <p style={{color: "#5c5c5c", fontWeight: 700}}>
                                                            Ouvert sur Rendez-Vous
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="time">
                                                            <p style={{color: "#5c5c5c", fontWeight: 700}}>
                                                                Du mardi au vendredi :{" "}
                                                            </p>
                                                            <p style={{color: "#5c5c5c"}}> De 14h à 19h</p>
                                                        </div>
                                                        <div className="time">
                                                            <p style={{color: "#5c5c5c", fontWeight: 700}}>
                                                                Samedi :{" "}
                                                            </p>
                                                            <p style={{color: "#5c5c5c", textAlign: "right"}}>
                                                                {" "}
                                                                De 10h à 13h <br/> et de 14h à 19h
                                                            </p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {this.state.currentProgram.dirSell ? (
                                            <div className="contact_data-item">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/icons/Group 49.svg"}
                                                    title="icon-location"
                                                    alt="icon-location"
                                                />
                                                <div className="contanct_data-info">
                                                    <p style={{color: "#5c5c5c", fontWeight: 700}}>
                                                        {" "}
                                                        Bureau de Vente
                                                    </p>
                                                    <p style={{color: "#5c5c5c"}}>
                                                        {this.state.currentProgram.dirSell.name}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="contact_data-item">
                                            <img
                                                src={process.env.PUBLIC_URL + "/icons/Path 185.svg"}
                                                alt="project-contact-icon"
                                                title="project-contact-icon"
                                            />
                                            <div className="contanct_data-info">
                                                <p style={{color: "#5c5c5c", fontWeight: 700}}>
                                                    Site du Projet
                                                </p>
                                                <p style={{color: "#5c5c5c"}}>
                                                    { this.state.currentProgram
                                                    && this.state.currentProgram.dirProject
                                                    && this.state.currentProgram.dirProject.name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="contact_data-item">
                                            <img
                                                src={process.env.PUBLIC_URL + "/icons/Group 48.svg"}
                                                alt="contact-phone"
                                                title="contact-phone"
                                            />
                                            <div className="contanct_data-info">
                                                <a
                                                    className="phone-violet-a"
                                                    href={"tel:" + this.state.currentProgram.phone}>
                                                    {this.state.currentProgram.phone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            //Detecta si hay más de un promotor
                            Array.isArray(this.state.currentProgram.promotor) ? (
                                this.state.currentProgram.promotor.map((e, i) => {
                                    return (
                                        <Parallax y={[20 + "%", -20]}>
                                            <div className="program__company-container">
                                                <div className="company__info">
                                                    {
                                                        i <= 0 ?
                                                            <h2 className="subtitle-h1-like-brown">
                                                                À Propos<u> du Promoteur immobilier</u>
                                                            </h2> : ''
                                                    }
                                                    <p
                                                        style={{color: "#5c5c5c"}}
                                                        dangerouslySetInnerHTML={{__html: e.description}}></p>
                                                </div>
                                                <img
                                                    src={e.logo}
                                                    alt="promotor"
                                                    title="promotor"
                                                />
                                            </div>
                                        </Parallax>
                                    );
                                })
                            ) : this.state.currentProgram.promotor ? (
                                <Parallax y={[20 + "%", -20]}>
                                    <div className="program__company-container">
                                        <div className="company__info">
                                            <h2 className="subtitle-h1-like-brown">
                                                À Propos du Promoteur <u>Immobilier</u>
                                            </h2>
                                            <p
                                                style={{color: "#5c5c5c"}}
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.currentProgram.promotor.description,
                                                }}></p>
                                        </div>
                                        <img
                                            src={this.state.currentProgram.promotor.logo}
                                            alt="Promoteur"
                                            title="promotor"
                                        />
                                    </div>
                                </Parallax>
                            ) : ("")
                        }
                        {((this.state.currentProgram.nearProject.length > 0) && this.state.currentProgram.nearProject) &&
                        <StyledContainer cn="cc-middle" s={20} prlxSize={10}>
                            <div className="current_projects-wrapper">
                                <h2
                                    className="subtitle-h1-like-brown left-align border-bot-pink-1"
                                    style={{textAlign: "left"}}>
                                    D’autres Projets Immobiliers <u>Intéressants</u> <br/> en Île-de-France
                                </h2>
                                {this.state.currentProgram.nearProject && this.state.currentProgram.nearProject.length > 0 &&
                                    <AltCard projects={this.state.currentProgram.nearProject}></AltCard>
                                }
                            </div>
                        </StyledContainer>}
                        <Snackbar
                            open={this.state.open}
                            autoHideDuration={6000}
                            onClose={this.handleClose}>
                            {/* <Alert onClose={this.handleClose} severity={this.state.toastMS}>
                        {this.state.toastM}
                    </Alert> */}
                            <SnackbarContent style={{
                                backgroundColor: "#eb0271",
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }} message={this.state.toastM} onClose={this.handleClose}/>

                        </Snackbar>
                        {
                            //Son slidesshow separados, este es cómo se ve cuando es un modal
                            //El de arriba es solo la versión que no es modal
                            //En el slideshow de arriba es donde está la acción del activar el modal con el click
                            this.state.openModal ?
                                <>
                                    <SlideShow
                                        imgs={this.state.currentProgram.imgs}
                                        folder={this.state.currentProgram.folder}
                                        modal={this.openModal}
                                    />
                                    {/* <div className="close-modal" onClick={() => this.setState({ openModal: false })}></div> */}
                                </>
                                : false
                        }
                    </div>

                }
            </>
        );
    }
}


export default Program;
