import React, { Component } from 'react';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';
import StyledFileUpload from '../../../Components/StyledFileUpload/StyledFileUpload';
import app from '../../../firebaseConfig';
import uuid from 'react-uuid';
import StyledCreate from '../../../Components/Create/StyledCreate';
import { useState } from 'react';
import "./Group.scss";

class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: "",
            img: null,
            people: [],
            modalOpen: false,
            currentEditing: 0
        }
    }

    componentDidMount() {
        if (this.props.typeEdit) {
            app.database().ref(this.props.typeEdit + "/").once("value").then(ss => {
                this.setState(ss.val())
            })
        } else {
            this.props.history.push("/admin/pages");
        }
    }

    uploadEdited = async () => {
        var downloadURL = "";
        // Array con la información de cada una le las personas
        var peopleInfo = [];
        if (this.state.img.name) {
            await this.uploadImageAsPromise(this.state.img, this.state.img.name)
                .then(url => {
                    downloadURL = url
                })
        }

        if (this.state.people.length > 0) {
            // Subiendo las imagenes de cada persona
            const { people } = this.state;
            for (const persona of people) {
                if (persona.hasOwnProperty("imgFile")) {
                    await this.uploadImageAsPromise(persona.imgFile).then(downloadURL => {
                        persona.imgURL = downloadURL;
                        delete (persona.imgFile);
                        peopleInfo.push(persona);
                    })
                } else {
                    peopleInfo.push(persona);
                }
            }
        }
        app.database().ref(this.props.typeEdit + "/").set({ ...this.state, img: downloadURL, people: peopleInfo }).then(() => {
            this.props.history.push("/admin/pages");
        });

    }

    uploadImageAsPromise = async (e, name) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var imageExtention = imageName.splice(imageName.length - 1);
                const storageRef = app.storage().ref(`${name}/${uuid() + "." + imageExtention}`)

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(downloadURL => {
                        resolve(downloadURL);
                    })
                })
            }
        })
    }

    handleInputChange = (name, value) => {
        this.setState({
            people: this.state.people.map((e, i) => {
                return i === this.state.currentEditing ? { ...e, [name]: value } : e
            })
        });
    }

    createPerson = (peopleData) => {
        const obj = this.state.people.slice();

        obj.push(peopleData);

        this.setState({ people: obj, modalOpen: false })
    }

    render() {
        return (<div className="cc__container">
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right" style={{ textTransform: "uppercase" }}>{this.props.typeEdit}</h1>
            </div>
            <section className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Header</h1>
                <StyledInputs
                    value={this.state.titulo}
                    type={"input"}
                    name="nombreDePagina"
                    title="Titre"
                    handleChange={(name, value) => this.setState({
                        titulo: value
                    })}
                />
                <StyledFileUpload
                    name="imagenActual"
                    title="Image Hero"
                    img={this.state.img}
                    onFileChange={(name, value) => {
                        this.setState({ img: value })
                    }}
                />

            </section>
            <div className="cc__division"></div>
            <div className="cc__wraped">
                {this.state.people.map(e => {
                    return (<figure className="people_card">
                        <img src={e.imgURL} alt="" />
                        <div className="bottom-text">
                            <h3 className="styled__label-gray-18px">{e.nombre}</h3>
                            <p className="styled__p-white">{e.des}</p>
                            <p className="styled__p-white">{e.email}</p>
                        </div>
                    </figure>)
                })}
                <StyledCreate
                    nombre="Agregar persona"
                    action={() => this.setState({ modalOpen: true })}
                    width={230}
                    height={300}
                />
            </div>
            <div className="cc__button-2">
                <button className="cc__button accept" >Nettoyer</button>
                <button className="cc__button cancel" onClick={this.uploadEdited}>Envoyer</button>
            </div>
            <br />
        <br />
        <br />
            {this.state.modalOpen &&
                <StyledModal>
                    <ModalForm
                        closeModal={() => this.setState({ modalOpen: false })}
                        addPeople={this.createPerson}
                    />
                </StyledModal>}
        </div>);
    }
}


const StyledModal = (props) => {
    return (<div className="cc__modal-container">
        <div className="cc__modal-info">
            {props.children}
        </div>
    </div>);
}

const ModalForm = ({ closeModal, editingImg, addPeople }) => {
    const [persona, setPersona] = useState({
        nombre: "Nom",
        des: "La description",
        email: "email",
        imgURL: "https://laopinion.pe/wp-content/themes/fox/images/placeholder.jpg",
        imgFile: null
    });

    const handleInputChange = (name, value) => {
        setPersona(prev => ({
            ...prev,
            [name]: value
        }))
    }

    return <>
        <StyledInputs
            value={persona.nombre}
            name="nombre"
            type="input"
            title="Nom"
            handleChange={handleInputChange}
        />
        <StyledInputs
            value={persona.des}
            name="des"
            type="input"
            title="la description"
            handleChange={handleInputChange}
        />
        <StyledInputs
            value={persona.email}
            name="email"
            type="input"
            title="Email"
            handleChange={handleInputChange}
        />
        <img src={persona.imgURL} alt="" width="100" />
        <input type="file" name="imgFile" id="" onChange={(e) => {
            handleInputChange(e.target.name, e.target.files[0]);
            readURL(e.target, value => {
                setPersona(prev => ({
                    ...prev,
                    imgURL: value
                }))
            })
        }} />
        <div className="cc__button-2">
            <button className="cc__button accept" onClick={closeModal}>Nettoyer</button>
            <button className="cc__button cancel" onClick={() => addPeople(persona)}>Envoyer</button>
        </div>
      
    </>
}

//Leer la URL de la imagen de manera local
const readURL = (e, setCurrentImageURL) => {
    if (e.files && e.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
            setCurrentImageURL(e.target.result);
        }
        reader.readAsDataURL(e.files[0]);
    }
}

export default Group;