import React, { Component } from 'react';
import app from '../../../firebaseConfig';
import "./MultipleEdit.scss";
import uuid from 'react-uuid';

class MultipleEdit extends Component {

    constructor(props) {
        super(props);
        this.props.selected ? (this.props.selected.length <= 0 && this.props.history.push("/admin/ley")) : this.props.history.push("/admin/ley")
        this.state = {
            selected: props.selected,
            leyesList: [],
            loading: false
        };
    }

    componentDidMount() {
        console.log('Estamos en la seccion de leyes')
        if (this.props.selected.length <= 0) {
            this.props.history.push("/admin/ley");
        }
        const leyes = app.database().ref("leyes/")
        const leyesList = []
        leyes.once("value").then(ss => {
            ss.forEach(child => {
                console.log(child.val())
                leyesList.push(child.val())
            })
        }).then(() => {
            if (leyesList)
                this.setState({ leyesList: leyesList })
        });
    }

    cancelUpload = () => {
        this.props.history.push("/admin");
    }

    handleUpload = async () => {
        this.setState({ loading: true })
        const db = app.database().ref("leyes/");

        let loisSelected = this.state.selected.slice();
        let loiObject = {};


        for (const loi of loisSelected) {
            if (loi.logo.hasOwnProperty("name")) {
                await this.uploadFileAsPromise(loi.logo).then(res => {
                    loi.logo = res;
                })
            }
        }

        loisSelected.forEach(e => {
            loiObject[e.id] = e;
        });

        db.update(loiObject).then(() => {
            this.setState({ loading: false })
            this.props.history.push("/admin/ley")
        });
    }

    uploadFileAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var storage = app.storage();
                var imageExtention = imageName.splice(imageName.length - 1);
                var fileRef = `leyes/${uuid() + "." + imageExtention}`;
                var storageRef = storage.ref(fileRef);

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(url => {
                        resolve({ url, fileRef });
                    });
                })
            }
        })
    }

    render() {
        return (<div className="multiple-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">LOIS</h1>
            </div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Lois à Modifier</h1>
                <div className="cc__styledtable-container">
                    <div className="content">
                        <p className="styled__label-pink">Nom</p>
                        <p className="styled__label-pink">Image</p>
                    </div>
                    {this.state.selected.map((e, i) => {
                        return <>
                            <div className="content">
                                <input type="text" name="" id="" value={e.nombre} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, nombre: el.target.value } : e)
                                })} />
                                <input type="text" name="" id="" value={e.description} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, description: el.target.value } : e)
                                })} />
                            </div>
                            <div className="cc__division"></div>
                        </>
                    })}
                </div>
                <div className="cc__button-2">
                    <button className="cc__button-1 accept" onClick={this.cancelUpload}>Cancel</button>
                    <button className="cc__button-1 cancel" onClick={() => this.handleUpload()}>Eenregistrer les modifications</button>
                </div>
            </div>
        </div >);
    }
}

export default MultipleEdit;