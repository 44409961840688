import React, { Component } from "react";
import Slider from "react-slick";
import "./TextSlider.scss";

export default class TextSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            comments: this.props.comments,
        };
    }

    renderArrows = () => {
        return (
            <div className="texxt__slider-arrow">
                <div
                    className="arrow-btn prev"
                    onClick={() => this.slider.slickPrev()}>
                    <img
                        className="left"
                        src={process.env.PUBLIC_URL + "/icons/arrowleft.png"}
                        alt="fleche gauche"
                        title="fleche gauche"
                    >
                    </img>
                </div>
                <div
                    className="arrow-btn next"
                    onClick={() => this.slider.slickNext()}>
                    <img
                        className="right"
                        src={process.env.PUBLIC_URL + "/icons/arrowright.png"}
                        alt="arrow droite"
                        title="fleche droite"
                    ></img>
                </div>
            </div>
        );
    };
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 600,
            lazyLoad: true,
        };
        return (
            <div className="texxt__slider">
                <h1 className="title-h1-brown custom-title">{this.state.title}</h1>
                {this.renderArrows()}
                <Slider className="texxt__slider_slider" {...settings} ref={c => (this.slider = c)}>
                    {this.state.comments && this.state.comments.length > 0  && this.state.comments.map((comment, index) => {
                        return <div  className="slider" key={index}>
                            <p className="slide_comment_author">{comment.nombre}</p>
                            <p className="slide_comment_text">{comment.text}</p>
                        </div>
                    })}
                </Slider>
            </div>
        );
    }
}