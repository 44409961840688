import React, { Component } from 'react';
import ModificationOptions from '../../../Components/ModifcationOptions/ModificationOptions';
import StyledEditableProject from '../../../Components/StyledEditableProject/StyledEditableProject';
import "./Main.scss"
import app from "../../../firebaseConfig";
import StyledCreate from '../../../Components/Create/StyledCreate';


class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLogo: "",
            hasChanged: false,
            projectList: []
        }
    }

    componentDidMount() {
        const projects = app.database().ref("projects/");
        const projetList = [];

        projects.once("value").then(ss => {
            ss.forEach(child => {
                projetList.push({
                    ...child.val(),
                    img: child.val().imgs ? child.val().imgs[0] : null
                });
            })
        }).then(() => {
            this.setState({ projectList: projetList })
        });
    }

    handleChange = (name, value) => {
        console.log(name, value);
    }

    handleCheckboxChange = (evt) => {
        this.setState({
            projectList: this.state.projectList.map(e => {
                if (evt.target.name === e.id) {
                    return {
                        ...e,
                        checked: evt.target.checked
                    }
                }
                return e;
            })
        });
    }

    uploadFile = async () => {
        const dataBase = app.database();
    }

    deleteItems = () => {
        if (this.state.projectList.filter(e => e.checked === true).length >= 0) {

            const db = app.database().ref("projects/");
            Promise.all(
                this.state.projectList.map(e => {
                    if (e.checked) {
                        db.child(e.id).remove();
                    }
                })
            ).then(() => {
                this.props.history.push("/admin");
            })
        }
    }

    editItems = () => {
        var items = this.state.projectList.filter(e => e.checked);
        if (items.length > 0) {
            this.props.setSelected(items);
            this.props.history.push("admin/project/edit");
        }
    }

    render() {
        return (<div className="cc__main-promotor">
            <section className="cc__fullsection">
                <div className="main_title">
                    <h1 className="styled__h1-brown margin-40px">Programmes</h1>
                    {<ModificationOptions eliminar={this.deleteItems} editar={this.editItems} crear={"admin/project/create"} activate={this.state.projectList.filter(e => e.checked === true).length > 0} />}
                </div>
            </section>
            <section className="cc__section">
                <div className="cc__row">
                    <p className="styled__p-gray">Programmes sélectionnés ({this.state.projectList.filter(e => e.checked === true).length})</p>
                </div>
            </section>
            <section className="cc__middle">
                <div className="cc__left">
                    <div className="cc__pages-list">
                        {this.state.projectList.map(e => {
                            return <StyledEditableProject
                                link={"/admin/project/edit/"}
                                title={e.title}
                                subtitle={e.lugar + ` (${e.zipCode})`}
                                bgImg={e.imgs[0].imgURL}
                                id={e.id}
                                handleChange={this.handleCheckboxChange}
                                checked={e.checked}
                            />
                        })}
                        <StyledCreate
                            nombre="Nouveau projet"
                            link="/admin/project/create"
                            width={300}
                            height={250}
                        />
                    </div>
                </div>
                {/* <ModificationOptions /> */}
            </section>
        </div>);

    }
}

export default Main;