import React, { Component } from 'react';
import app from '../../../firebaseConfig';
import "./MultipleEdit.scss";
import uuid from 'react-uuid';
import StyledInputs from '../../../Components/StyledInputs/StyledInputs';

class MultipleEdit extends Component {

    constructor(props) {
        super(props);
        this.props.selected ? (this.props.selected.length <= 0 && this.props.history.push("/admin/promotor")) : this.props.history.push("/admin/promotor")
        this.state = {
            selected: props.selected,
            certificadoList: [],
            loading: false
        };
    }


    componentDidMount() {
        console.log('Estamos en la seccion de promotor')
        if (this.props.selected.length <= 0) {
            this.props.history.push("/admin/promotor");
        }
        const leyes = app.database().ref("promotores/")
        const certificadoList = []
        leyes.once("value").then(ss => {
            ss.forEach(child => {
                console.log(child.val())
                certificadoList.push(child.val())
            })
        }).then(() => {
            if (certificadoList)
                this.setState({ certificadoList: certificadoList })
        });
    }

    cancelUpload = () => {
        this.props.history.push("/admin/promotor");
    }

    handleUpload = async () => {
        this.setState({ loading: true })
        const db = app.database().ref("promotores/");

        let promotoresSelected = this.state.selected.slice();
        let promotoresObject = {};


        for (const promotor of promotoresSelected) {
            if (promotor.logo.hasOwnProperty("name")) {
                await this.uploadFileAsPromise(promotor.imgURL).then(res => {
                    promotor.imgURL = res;
                })
            }
        }

        promotoresSelected.forEach(e => {
            promotoresObject[e.id] = e;
        });

        db.update(promotoresObject).then((e) => {
            this.setState({ loading: false })
            this.props.history.push("/admin/promotor")
        });
    }

    uploadFileAsPromise = async (e) => {
        return new Promise((resolve, reject) => {
            if (e) {
                const imageName = e.name.split(".");
                var storage = app.storage();
                var imageExtention = imageName.splice(imageName.length - 1);
                var fileRef = `promotores/${uuid() + "." + imageExtention}`;
                var storageRef = storage.ref(fileRef);

                storageRef.put(e).then(ss => {
                    ss.ref.getDownloadURL().then(url => {
                        resolve({ url, fileRef });
                    });
                })
            }
        })
    }

    render() {
        return (<div className="multiple-container">
            {
                this.state.loading &&
                <div className="loading-bar">
                    <p className="styled__p-white ">chargement</p>
                </div>
            }
            <div className="cc__header-title">
                <h1 className="styled__h1-brown_underline style__align-right">LOIS</h1>
            </div>
            <div className="cc__section">
                <h1 className="styled__h2-brown style__align-left">Lois à Modifier</h1>
                <div className="cc__styledtable-container">
                    <div className="content-title">
                        <p className="styled__label-pink">Nom</p>
                        <p className="styled__label-pink">Image</p>
                    </div>
                    {/*this.state.selected.map((e, i) => {
                        const buttonRef = React.createRef();
                        return <>
                            <div className="cc__division"></div>
                            <div className="content">
                                <input type="text" name="" id="" value={e.nombre} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, nombre: el.target.value } : e)
                                })} />
                                <input type="text" name="" id="" value={e.description} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, description: el.target.value } : e)
                                })} />
                                <input className="hidden" type="file" name="" id="" ref={buttonRef} onChange={(el) => this.setState({
                                    selected: this.state.selected.map((e, index) => i === index ? { ...e, logo: el.target.files[0] } : e)
                                })} />
                                <input className="button__defult" type="button" value="(télécharger l'image)" onClick={() => buttonRef.current.click()} />
                                {typeof e.logo === "string" ?
                                    <a href={e.logo} target="_blank" className="logo__link">
                                        <img src={process.env.PUBLIC_URL + "/icons/link.svg"} alt="" style={{ width: 20 }} />
                                    </a>
                                    :
                                    <p className="styled__label-pink">
                                        {typeof e.logo.name !== "undefined" && e.logo.name}
                                        {typeof e.logo.size !== "undefined" && " (" + e.logo.size / 1000 + "kb)"}
                                    </p>
                                }
                            </div>
                        </>
                    })*/}
                    {this.state.selected.map((e, i) => {
                        const buttonRef = React.createRef();
                        return <>
                            <div className="cc__division"></div>
                            <div className="content">
                                <StyledInputs
                                    value={e.nombre}
                                    type="input"
                                    name={"nombre"}
                                    handleChange={(nombre, value) => this.setState({
                                        selected: this.state.selected.map((e, index) => i === index ? { ...e, [nombre]: value } : e)
                                    })}
                                />
                                <div className="img__change">
                                    <input className="hidden" type="file" name="" id="" ref={buttonRef} onChange={(el) => this.setState({
                                        selected: this.state.selected.map((e, index) => i === index ? { ...e, logo: el.target.files[0] } : e)
                                    })} />
                                    <input className="button__defult" type="button" value="(télécharger l'image)" onClick={() => buttonRef.current.click()} />
                                    {typeof e.logo === "string" ?
                                        <a href={e.logo} target="_blank" className="logo__link" rel="noopener noreferrer">
                                            <img src={process.env.PUBLIC_URL + "/icons/link.svg"} alt="" style={{ width: 20 }} />
                                        </a>
                                        :
                                        <p className="styled__label-pink">
                                            {typeof e.logo.name !== "undefined" && e.logo.name}
                                            {typeof e.logo.size !== "undefined" && " (" + e.logo.size / 1000 + "kb)"}
                                        </p>
                                    }
                                </div>
                            </div>
                        </>
                    })}


                    <div className="cc__division"></div>
                </div>
                <div className="cc__button-2">
                    <button className="cc__button-1 accept" onClick={this.cancelUpload}>Cancel</button>
                    <button className="cc__button-1 cancel" onClick={() => this.handleUpload()}>Eenregistrer les modifications</button>
                </div>
            </div>
        </div >);
    }
}

export default MultipleEdit;