import React from 'react';
import { useState } from 'react';
import PrivateRoute from '../../PrivateRoute';
import Certificados from './Main/Certificados';
import MultipleEdit from './MultipleEdit/MultipleEdit';

const Index = () => {
    const [selected, setSelected] = useState([]);
    return (<>
        <PrivateRoute exact path="/admin/certificat" component={(props) => <Certificados setSelected={setSelected} selected={selected} {...props} />} />
        <PrivateRoute exact path="/admin/certificat/edit" component={(props) => <MultipleEdit selected={selected} {...props} />} />
    </>);
}

export default Index;