import React from 'react';
import './Card.scss'
import { Link } from 'react-router-dom';

const cardType = (type, cardPropertis, cardWidth = 300, cardHeight = 225) => {
    //console.log(cardPropertis);
    if (type === "PLACE") {
        return (
            <Link key={cardPropertis.id} to={"/programme" + cardPropertis.slug}>
                <div style={{
                    width: cardWidth ? cardWidth : 300,
                    height: cardHeight ? cardHeight : 400,
                    backgroundSize: 'cover',
                    backgroundImage: `url(${cardPropertis.imgs[0].imgURL})`
                }} alt=""></div>
                <div className="card__info-container">
                    <h3 style={{ fontSize: 16 }}>{cardPropertis.title}</h3>
                    <p style={{ fontSize: 12 }}>{cardPropertis.lugar} ( {cardPropertis.zipCode} )</p>
                </div>
            </Link>
        )

    } else if (type === "LOGO") {
        return (
            <>
                <div style={{
                    width: cardWidth ? cardWidth : 300,
                    height: cardHeight ? cardHeight : 255,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundImage: `url(${process.env.PUBLIC_URL + cardPropertis.image})`
                }} alt=""></div>

            </>
        )
    } else if (type === "PEOPLE") {
        return (
            <>
                <div style={{
                    width: 300,
                    height: 400,
                    backgroundSize: 'cover',
                    backgroundImage: `url(${cardPropertis.imgURL})`
                }} alt=""></div>
                <div className="card__info-container">
                    <h3 style={{ fontSize: 16 }}>{cardPropertis.nombre}</h3>
                    <p style={{ fontSize: 12 }}>{cardPropertis.des} </p>
                    <p style={{ fontSize: 12 }}>{cardPropertis.email} </p>
                </div>
            </>
        )
    }
}

const Card = ({ prop, cardWidth, cardHeight, sliderType }) => {
    return (<div className={(prop.title || prop.nombre) ? 'card__container  card__container-shadow' : 'card__container-p'}>
        {cardType(sliderType, prop, cardWidth, cardHeight)}
    </div>);
}

export default Card;