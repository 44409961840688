import React from 'react';
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import '../Cookies/Cookies.scss';
import { useEffect } from 'react';


const Cookies = ({ match, setvisual }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className="cookies-container">
            <BannerSlide title="Confidentialité" />
            <StyledContainer cn="cc-middle" prlxSize={-4} s={4}>
                <div className="content-cookies">
                    <h1 className="title-h1-brown">Politique de <u>confidentialité.</u></h1>
                    <h2>Introduction</h2>
                    <p>Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée. C’est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.</p>
                    <h2>Collecte des renseignements personnels</h2>
                    <p>Nous collectons les renseignements suivants :
                        <br />
                        <br />Nom
                            <br />Prénom
                            <br />Adresse électronique
                            <br />Numéro de téléphone
                        <br />
                        <br />

                        Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l’interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins pour réunir des informations vous concernant.
                        Les données personnelles collectées sont stockées par Conseil et Commercialisation pour une durée maximale de 3 ans.
                    </p>
                    <h2>Formulaires  et interactivité :</h2>
                    <p>Vos renseignements personnels sont collectés par le biais de formulaires de contact, à savoir :
                    <br />
                        <br />
                                Formulaire d’abonnement à la Newsletter, les informations seront inscrites dans un fichier témoin de Conseil et Commercialisation et stockées sur une durée maximale de 3 ans.

                            <br />
                                Formulaires de demande de renseignements sur des programmes immobiliers, les informations seront inscrites dans des fichiers témoins appartenant aux promoteurs des programmes concernés par les demandes. Les informations collectées sont également stockées par Conseil et Commercialisation sur une durée maximale de 3 ans.

                        <br />
                    Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                    <br />
                        <br />Informations / Offres
                        <br />
                    Vos renseignements sont également collectés par le biais de l’interactivité pouvant s’établir entre vous et notre site Web par le biais de notre plugin Google Analytics et ce, de la façon suivante:
                    <br />
                        <br />

                                Expérience utilisateur et Statistiques

                        <br />
                    Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :

<br />
                        <br />
                                Amélioration du service et accueil personnalisé

                        <br />
                    </p>

                    <h2>Fichiers journaux et témoins</h2>
                    <p>

                        Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers témoins (cookies) avec Google Analytics et OVH. Il s’agit principalement des informations suivantes :
        <br /><br />
                        Adresse IP
        <br />
                        Système d’exploitation
        <br />
                        Pages visitées et requêtes
        <br />
                        Heure et jour de connexion
        <br />
                        Le recours à de tels fichiers nous permet :
        <br />
                        <br />

                        Amélioration du service et accueil personnalisé
                        <br />
Statistique        </p>
                    <h2>Droit d’opposition et de retrait</h2>
                    <p>
                        Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements personnels.
                        Le droit d’opposition s’entend comme étant la possibilité offerte aux internautes de refuser que leurs renseignements personnels soient utilisés à certaines fins mentionnées lors de la collecte.
<br /><br />
Le droit de retrait s’entend comme étant la possiblité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.
<br /><br />
Pour pouvoir exercer ces droits, vous pouvez adresser votre demande à l’attention du DPO :
<br />
Code postal : 9 Rue Neuve Saint-Germain, Boulogne Billancourt 92100
<br />
Courriel : contact@conseil-commercialisation.fr
<br />
Téléphone : 0186957644        </p>

                    <h2>Droit d’accès</h2>
                    <p>

                        Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant.
                    <br />
                    L’exercice de ce droit se fera auprès du DPO :
                    <br />
                    Code postal : 9 Rue Neuve Saint-Germain, Boulogne Billancourt 92100
                    <br />
                    Courriel : contact@conseil-commercialisation.fr
                    <br />
Téléphone : 0186957644 </p>

                    <h2>Sécurité</h2>
                    <p>
                        Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillants pour nous sont tenues de respecter la confidentialité de vos informations.
                        Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :
<br /><br />
Protocole SSL (Secure Sockets Layer)
<br />
Protocole SET (Secure Electronic Transaction)
<br />
Gestion des accès – personne autorisée
<br />
Gestion des accès – personne concernée
<br />
Logiciel de surveillance du réseau
<br />
Sauvegarde informatique
<br />
Développement de certificat numérique
<br />
Identifiant / mot de passe
<br />
Pare-feu (Firewalls)
<br />
                        <br />
Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsque l’on utilise Internet pour transmettre des renseignements personnels.
           </p>
                </div>

            </StyledContainer>
        </div>
    );
}

export default Cookies;