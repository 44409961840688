import React, { useState, useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Menu from "./Components/Menu/Menu";
import Home from "./Pages/Home";
import { ParallaxProvider } from "react-scroll-parallax";
import Programs from "./Pages/Programas/Programs";
import Footer from "./Components/Footer/Footer";
import Program from "./Pages/Program/Program";
import Inversion from "./Pages/Inversion/Inversion";
import Group from "./Pages/Groupe/Group";
import Venta from "./Pages/Venta/Venta";
import Contact from "./Pages/Contact/Contact";
import Ley from "./Pages/Ley/Ley";
import Simulation from "./Components/Simulation/Simulation";
import Cookies from './Pages/Cookies/Cookies';
import Legales from './Pages/Legales/Legales'
import CookieMessage from "./Components/Coockies/CookieMessage";
import Thanks from "./Pages/Thanks/Thanks";
import Admin from "./Admin/Admin";

function App() {

  const [isSimulationOpen, setSimulation] = useState(false);
  const [useCookies, setCookies] = useState(true);
  const [loadPrograms, setLoadPrograms] = useState(1);
  const openModal = () => {
    setSimulation(!isSimulationOpen);
    if (isSimulationOpen) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  //Setea las aceptación de los cookis para poder usar Google Analitycs
  const settingCookies = (value) => {
    if (!value) {
      // console.log("El usuario no quiere las cookies");
      localStorage.setItem('allow-cookies', 'false');
      localStorage.setItem('allow-cookies-time', Date.now());
    } else if (value) {
      //Setea las cookies a true
      // console.log("El usuario acepta las cookies")
      localStorage.setItem('allow-cookies', 'true');
      //Remueve las cookies del tiempo si existe
      if (localStorage.key('allow-cookies-time')) {
        localStorage.removeItem('allow-cookies-time');
      }
    }
    setCookies(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    var allowCookies = localStorage.getItem('allow-cookies');
    var cookiesTime = localStorage.getItem('allow-cookies-time');
    var oneDay = 24 * 60 * 60 * 1000;

    if (allowCookies === null && cookiesTime === null) {
      setCookies(false);
      // console.log("no hay ningún elemento en el ls");
    } else if (allowCookies === 'false') {

      var elapsedTime = Math.round(Math.abs((Date.now() - parseInt(cookiesTime)) / oneDay));
      if (cookiesTime === null) {
        localStorage.setItem('allow-cookies-time', Date.now());
      }
      if (elapsedTime > 10) {
        setCookies(false);
        // console.log("Han pasado más de dies días y se deben mostrar");
      } else {
        setCookies(true)
        // console.log("Las cookies no se muestran");
      }

    } else if (allowCookies === 'true') {
      ReactGA.initialize('UA-166624531-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
      //console.log("El usuario ya tiene aceptado las cookies");
      setCookies(true);
    } else {
      // console.log("Cualquier otra cosa");
      setCookies(false);
    }
    // console.log()
  }, [])
  
  const leyes = {
    /*eslint no-multi-str: 2*/
    pinel: {
      title: "Loi Pinel",
      info:
        "L’investissement Pinel est un dispositif fiscal en faveur de l’investissement locatif, qui vous permet"+
      "de bénéficier d’une réduction d’impôt pouvant aller jusqu’à 63 000 € en achetant un logement."+
      "Vous investissez dans un logement neuf avec l’engagement de le louer pendant 6, 9 ou 12 ans"+
      "(possibilité de louer à un ascendant ou descendant de votre famille depuis le 1er janvier 2015)."+
      "En respectant un plafond maximum de loyer (en fonction des zones Abis, A et B1), vous bénéficiez"+
      "d’un avantage fiscal et d’un effort d’épargne réduit grâce aux loyers perçus. Investir en Pinel vous"+
      "permet de devenir propriétaire avec ou sans apport et diminuer votre impôt sur le revenu jusqu’à"+
      "6 000 € par an. L’avantage fiscal de cette loi est limité à 2 acquisitions maximum par an, dans la"+
      "limite d’un montant global de 300 000 €. La valeur du bien peut dépasser ce montant mais la"+
      "défiscalisation sera plafonnée. D’autre part, un prix plafond au m2 de surface habitable est fixé"+
      "à 5 500 €/m2 pour toutes les zones.",
      tabla: [
        ["zones pinel", "BARÈME PINEL 2020"][("Zone ABis", "17,43 €/m2")],
        ["Zone A", "12,95 €/m2"],
        ["Zone B1", "10,55 €/m2"],
        ["Zone B2 & C", "9,02 €/m2"],
      ],
    },
    denormandie: {
      title: "Loi Denormandie",
      info:
        "L’investissement Denormandie est un dispositif fiscal en faveur de la rénovation de logements anciens."+
        "Vous investissez dans un logement ancien, situé dans une ville labellisé « cœur de ville » ou dans une agglomération ayant conclu une Opération de Revitalisation du Territoire, que vous vous engagez à rénover à hauteur de 25% du prix d’achat. Le logement doit être acquis entre le 1er janvier 2019 et 31 décembre 2021 et loué pendant 6, 9 ou 12 ans. En respectant un plafond maximum de loyer (le même que celui attribué au dispositif Pinel), vous bénéficiez cette fois-ci, d’un double avantage fiscal. D’une part, d’une réduction d’impôt au taux de 18% calculé sur le montant global de l’opération (montant de l’acquisition en l’état + coût des travaux) et de l’imputation du déficit foncier sur votre revenu imposable"
    },
    lmnp: {
      title: "LMNP/LMP",
      info:
        "La location meublée non professionnelle ou professionnelle (revenus locatifs > 23 000€ et > revenus imposable) s’inscrit aussi bien dans le cadre d’un investissement dans l’ancien que dans le neuf, notamment sous le régime du Censi-Bouvard pour le LMNP.",
    },
    marlaux: {
      title: "Loi Malraux",
      info:
        "L’investissement Malraux est un dispositif fiscal vous permettant de devenir propriétaire d’un immeuble ancien à caractère histoire à restaurer et de bénéficier d’un avantage fiscal pouvant monter jusqu’à 120 000 €."+
      "Cette loi a été mise en place afin de conserver le patrimoine architectural français. Ainsi, vous investissez dans un bien immobilier ancien à caractère historique sous réserve de la réalisation des travaux de rénovation par un opérateur, ce qui vous permet de bénéficier d’une réduction d’impôts calculée en fonction de la zone où se situe le bien : "+
      "30% pour les immeubles situés dans les « secteurs sauvegardés » et les QAD (quartiers anciens dégradés)"+
      "22% pour les immeubles situés dans les ZPPAUP (zone de protection du patrimoine architectural urbain et paysager)"+
      "Pour profiter de ces avantages, vous devez avoir obtenu l’ASP (autorisation spéciale auprès du Préfet) avant le démarrage des travaux et vous engager à restaurer l’ensemble du bâtiment. Le chantier doit être suivi par un Architecte des Bâtiments de France."+
      "Les logements doivent être loués nus dans les 12 mois après la fin des travaux de rénovation, et ce, en résidence principale. Il n’est pas possible de louer à un membre de sa famille.",
    },
    "Nue-propriete": {
      title: "La Nue-Propriété",
      info:
        "La Nue-Propriété s’inscrit dans le cadre d’un démembrement de propriété. L’acquéreur, appelé le nu-propriétaire (personne physique) d’une part et l’usufruitier d’autre part (personne morale, en l’occurrence un bailleur professionnel). Dans ce type d’investissement, le bailleur professionnel dispose du bien pendant une période donnée (par exemple 15 ans) pendant laquelle il percevra les loyers avec en contrepartie, la prise en charge des travaux d’entretien du bien, et de la taxe foncière. L’investisseur, soit le nu-propriétaire n’aura pas d’impôt sur les revenus fonciers à payer et bénéficie d’une réduction de prix significative à l’achat.",
    },
    "Deficit-foncier": {
      title: "Le Déficit Foncier",
      info:
        "Idéal pour les investisseurs dont l’objectif est de défiscaliser leurs revenus fonciers existants. Le coût des travaux seront, en respect de la fiscalité opérante, déduits dans un 1er temps des revenus fonciers et l’excédent sera quant à lui imputer sur le revenu imposable.",
    },
  };

  const venta = {
    appartement: {
      title: "J’ai un appartement à vendre",
      info:
        "Nous nous déplaçons sur place pour estimer la valeur"+
      "de votre bien. Nous nous chargeons ensuite de la"+
      "diffusion de vos annonces (photos, descriptif,"+
      "diagnostique) et réalisons les visites pour votre compte."+
      "Nous vous accompagnons jusqu’à la signature de l’acte"+
      "de vente chez le notaire.",
    },
    "immeuble ": {
      title: "J’ai un immeuble à vendre",
      info:
        "Selon votre choix de vendre l’immeuble en bloc ou à la"+
      "découpe, nous vous accompagnons pour l’ensemble"+
      "des démarches règlementaires et administratives."+
      "Nous réalisons pour votre compte une étude de marché"+
      "détaillée et la mise en place d’une stratégie commerciale"+
      "et marketing adaptée. Nous vous accompagnons jusqu’à"+
      "la signature de l’acte de vente chez le notaire."
    },
    terrain: {
      title: "J’ai un terrain à vendre",
      info:
        "Nous nous déplaçons sur place pour une prise en"+
      "compte de la situation de votre bien (environnement,"+
      "topographie des lieux, transports, écoles, commerces…)."+
      "Nous réalisons une étude de capacité afin d’évaluer les"+
      "droits à construire puis une étude de faisabilité dans le"+
      "but d’optimiser les surfaces à créer. Nous déterminons"+
      "ensemble un prix que nous soumettons ensuite aux"+
      "grands comptes ou particuliers. Nous vous"+
      "accompagnons jusqu’au rendez-vous chez le notaire.",
    },
  };

  return (
    // <AuthProvider>
    <Router>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={process.env.PUBLIC_URL + "/favicon2.svg"} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#eb0271" />
        <meta name="description" content="Spécialiste du neuf pour la résidence principale et l'investissement locatif, Conseil & Commercialisation vous accompagne dans la réalisation de votre projet immobilier." />
        <link rel="apple-touch-icon" href={process.env.PUBLIC_URL + "/favicon2.svg"} />
        <meta name="keywords" content="
        Loi Pinel, Loi Malraux, PTZ, Prêt-taux-zéro, Résidence principale,  RT 2012, Frais de notaire réduits, Denormandie,L MNP, Garanties, Labels, Immobilier neuf, Promotion immobilière, VEFA, Patrimoine, Appartement, Maison, Investissement, Résidence Principale, Développement Foncier, Immobilier ancien, Conseil, Commercialisation, France, Paris, Boulogne, Programme Immobilier
        "/>
        {/* <meta name="author" content="John Doe" /> */}
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
        <title>C&C - Conseil et commercialisation </title>

        <meta property="og:title" content="C&C - Conseil et commercialisation" />
        <meta property="og:description" content="Spécialiste du neuf pour la résidence principale et l'investissement locatif, Conseil & Commercialisation vous accompagne dans la réalisation de votre projet immobilier." />
        <meta property="og:image" content={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"} />
        <meta property="og:url" content={process.env.PUBLIC_URL} />

        <meta name="twitter:title" content="C&C - Conseil et commercialisation" />
        <meta name="twitter:description" content="Spécialiste du neuf pour la résidence principale et l'investissement locatif, Conseil & Commercialisation vous accompagne dans la réalisation de votre projet immobilier." />
        <meta name="twitter:image" content={process.env.PUBLIC_URL + "/img/logo/cyc-logo.svg"} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <ParallaxProvider>
        <Switch>
          <Route exact path="/">
            <Menu width="150px" />
          </Route>
          {/* <Route path="/admin/login" /> */}
          <Route path="/admin" />
          <Route path="/*">
            <Menu />
          </Route>
        </Switch>
        <div className="App">
          <Switch>
            <Route path="/abonne" component={Thanks} />
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/programmes/:quant?/:ville?/:type?/:budget?" render={(props) => <Programs {...props} setLoadPrograms={setLoadPrograms} loadPrograms={loadPrograms} />} />
            <Route path="/programme/:location/:name" component={Program} />
            <Route path="/programme/:programId" component={Program} />
            <Route path="/investissement">
              <Inversion
                props={leyes}
                isOpen={isSimulationOpen}
                setvisual={openModal}
              />
            </Route>
            <Route path="/cookies" render={(props) => <Cookies {...props} setvisual={openModal} />} />
            <Route path="/politique_de_confidentialite" component={Legales} />} />
            <Route path="/groupe">
              <Group />
            </Route>
            <Route path="/vendre">
              <Venta props={venta} />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/lois/:leyId" render={(props) => <Ley {...props} setvisual={openModal} />} ></Route>
            <Route path="/admin" component={Admin} />
            <Redirect exact from="*" to="/" />
          </Switch>
          <Simulation isOpen={isSimulationOpen} setvisual={openModal} />
        </div>
        <Switch>
          <Route path="/admin" />
          <Route path="/*" component={Footer} />
        </Switch>
      </ParallaxProvider>
      {
        !useCookies ?
          <CookieMessage setCookie={settingCookies} />
          : null
      }
    </Router>
  );
}

export default App;
