import React, {Component} from "react";
import "./BannerSlider.scss";

class BannerSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: this.props.img,
            position: 0,
            seconds: parseInt(props.startTimeInSeconds, 10) || 0
        };
    }

    componentDidMount() {
        if (this.props.headers && this.props.headers.length > 0) {
            this.interval = setInterval(() => this.changeImage(), 10000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    changeImage = () => {
        if (this.state.position + 1 < this.props.headers.length) {
            // this.state.position = this.state.position + 1
            // this.state.currentImage = this.props.headers[this.state.position].image
            this.setState((state, props) => ({
                position : state.position + 1,
            }));
        } else {
            // this.state.position = 0
            // this.state.currentImage = this.props.headers[this.state.position].image
            this.setState((state, props) => ({
                position : 0,
            }));
        }
        this.setState((state, props) => ({
            currentImage : props.headers[state.position].image,
            seconds: state.seconds + 1
        }));
    }

    fontSizeCalc = (text) => {
        if (text && text.length > 10) {
            return 55
        }
        return 'auto'
    }

    render() {
        return (
            <div className="sliderbanne__container" style={{backgroundImage: `url(${this.state.currentImage})`}}>
                <h1 className="title-h1-brown main-title"
                    style={
                        {fontSize: this.fontSizeCalc(this.props.title)}
                    }
                    dangerouslySetInnerHTML={{__html: this.props.title}}>
                </h1>
                <p style={{fontSize: 16, margin: 0}}>{this.props.subtitle}</p>
            </div>
        );
    }
}

export default BannerSlide;
