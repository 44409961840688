import React from 'react';
import StyledContainer from "../../Components/StyledContainer/StyledContainer";
import BannerSlide from "../../Components/BannerSlide/BannerSlide";
import './Cookies.scss';
import {useEffect} from 'react';


const Cookies = ({match, setvisual}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className="cookies-container">
            <BannerSlide title="Cookies"/>
            <StyledContainer cn="cc-middle" prlxSize={-4} s={4}>
                <div className="content-cookies">
                    <h1 className="title-h1-brown" style={{marginBottom: 40}}>Information sur les <u>cookies</u></h1>
                    <h2>
                        Que sont les cookies?
                    </h2>
                    <p>
                        Les cookies sont de petits fichiers texte qui contiennent une chaîne de caractères. Cette chaîne
                        peut être insérée dans votre ordinateur ou appareil mobile et est utilisée pour identifier de
                        manière unique votre navigateur ou appareil. Nous pouvons utiliser certaines technologies
                        (telles que les cookies, les pixels et le stockage local) pour fournir et protéger des produits,
                        des services et des publicités, ainsi que pour comprendre comment ils fonctionnent.
                    </p>
                    <h2>
                        À quoi servent les cookies?
                    </h2>
                    <p>

                        Les cookies (entre autres technologies) indiquent aux sites Web ou à certains services s'ils ont
                        été consultés à partir d'un ordinateur ou d'un appareil spécifique. Ces technologies peuvent
                        être utilisées pour fournir des produits, des services et des publicités. Ils nous permettent
                        également de mieux comprendre l'utilisation faite d'un site ou d'un service en question, nous
                        aident à mémoriser vos préférences, à rendre votre navigation entre les différentes pages plus
                        efficace et, de manière générale, à améliorer l'expérience d'utilisation nos services. De plus,
                        grâce aux cookies, le contenu marketing affiché sur Internet est plus pertinent et est davantage
                        lié aux intérêts de chaque personne.
                    </p>
                    <h2>
                        Pourquoi C&C utilise des cookies et des technologies similaires?
                    </h2>
                    <p>
                        Nous utilisons des cookies, des pixels, du stockage local et d'autres technologies similaires
                        pour vous montrer du contenu qui vous concerne, améliorer votre expérience et protéger davantage
                        à la fois la plateforme C&C et nos utilisateurs. Nous pouvons utiliser ces technologies pour
                        fournir notre service et faciliter son utilisation. Quant à vous, vous pouvez explorer ce
                        service, utiliser ses fonctions (par exemple, lors de l'accès à des zones protégées) et stocker
                        des informations avec une plus grande rapidité de réponse par C&C. Nous pouvons également
                        collecter des informations sur la façon dont vous utilisez notre service (par exemple, les pages
                        que vous visitez le plus souvent et si vous recevez des messages d'erreur d'autres pages
                        spécifiques). Ces technologies nous permettent de mémoriser les options que vous avez choisies
                        (par exemple, votre nom d'utilisateur, votre langue ou la région dans laquelle vous vous
                        trouvez) et d'adapter notre service pour vous offrir des fonctionnalités et un contenu
                        améliorés. De même, nous utilisons ces cookies pour mémoriser les modifications que vous avez
                        apportées par rapport à la taille du texte, la police et d'autres éléments des pages que vous
                        pouvez personnaliser. C&C et nos partenaires publicitaires peuvent utiliser ces technologies
                        pour diffuser des publicités pertinentes en fonction de vos intérêts. Ils nous permettent
                        également de nous souvenir si un site Web ou un service a été visité depuis votre appareil et de
                        suivre la navigation effectuée à travers celui-ci par d'autres sites Web ou services en dehors
                        de C&C. Ces informations peuvent être partagées avec des organisations non C&C, telles que des
                        annonceurs ou des réseaux publicitaires, pour permettre la diffusion de contenu et les aider à
                        mesurer l'efficacité des campagnes publicitaires. * Nous pouvons utiliser ces technologies pour
                        optimiser et rechercher certains produits et services, et élargir nos connaissances à leur
                        sujet.
                    </p>

                    <h2>
                        Combien de temps les cookies resteront-ils sur mon appareil?
                    </h2>
                    <p>

                        La durée de conservation d'un cookie sur un ordinateur ou un appareil varie selon qu'il s'agit
                        d'un cookie permanent ou de session. Les cookies de session restent uniquement sur l'appareil
                        jusqu'à la fin de la navigation. Cependant, les permanents sont conservés sur l'ordinateur ou
                        l'appareil mobile jusqu'à leur expiration ou leur suppression.
                    </p>
                    <h2>
                        Les cookies Propres et tiers
                    </h2>
                    <p>
                        Les cookies propres appartiennent à C&C. Concernant les cookies tiers, ils sont placés sur
                        l'appareil par une autre entité via notre service. Il peut arriver qu'une entité qui effectue
                        une certaine activité pour C&C place ce deuxième type de cookies sur un appareil, par exemple,
                        pour nous aider à mieux comprendre l'utilisation faite de notre service. Nos partenaires
                        commerciaux peuvent également insérer des cookies tiers sur un appareil pour annoncer des
                        produits et services à partir de n'importe quel emplacement sur Internet.
                    </p>

                    <h2>
                        Comment contrôler les cookies et autres mécanismes
                    </h2>
                    <p>

                        Pour modifier les paramètres des cookies, suivez les instructions fournies par le site Web ou le
                        navigateur mobile. Ils se trouvent généralement dans les sections "Aide", "Outils" ou
                        "Modifier". Veuillez noter que si vous configurez votre navigateur pour désactiver les cookies
                        ou d'autres technologies, vous ne pourrez peut-être pas accéder à certaines zones de notre
                        service ou elles pourraient ne pas fonctionner correctement.
                        <br/><br/>
                        Pour en savoir plus sur les options que les annonceurs proposent souvent aux utilisateurs pour
                        influencer la manière dont les informations sur leurs activités Internet sont collectées et
                        utilisées dans le temps et sur divers sites Web ou services en ligne tiers, visitez les pages la
                        Network Advertising Initiative <a
                        href=" http://www.networkadvertising.org/managing/opt_out.asp">
                        <u>www.networkadvertising.org</u></a>, la Digital Advertising Alliance <a
                        href="http://www.aboutads.info/"><u> www.aboutads.info</u></a> ou la European Digital
                        Advertising Alliance (<a href="http://youronlinechoices.eu/">http://youronlinechoices.eu/</a>).
                    </p>
                </div>

            </StyledContainer>
        </div>
    );
}

export default Cookies;