import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import "./SimpleCard.scss"
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        color: "white",
        fontFamily: "Poppins, goudy-old-style, serif",
        fontWeight: 400,
        position: 'relative',
        margin: 'auto',
    },
    media: {
        width: 400,
        height: 240,
    },
});

export default function MediaCard({props}) {
    const classes = useStyles();

    return (
        <Link key={props.id} to={"/programme" + props.slug}>
        <Card className={classes.root}>
                <CardMedia
                    className={classes.media}
                    image={props.imgs[0].imgURL}
                >
                    <div className="card__content__card__media__container">
                        <div className="card__content__card__media">{props.title}</div>
                    </div>
                </CardMedia>
        </Card>
        </Link>
    );
}
