import React from 'react';
import { useState } from 'react';
import './StyledInputs.scss';

const inputType = (inputType, name, className, value, handleChange, setInnerValue, required, validationType, options, password) => {

    //Verificar si todos los valores requeridos están disponibles;
    if (inputType === "select") {}
    // console.log(!inputType, value === null, !handleChange, !name)
    // console.log(value)
    var areValuesMissing = !inputType || value === null || !handleChange || !name;

    if (areValuesMissing) {
        return <p>Missing required value</p>
    }
    switch (inputType) {

        case "input":
            return <input
                type={password ? "password" : "text"}
                className={"cc__admin-input " + className}
                name={name}
                onChange={e => handleChange(e.target.name, e.target.value)}
                value={value}
                onBlur={e => setInnerValue(handleBlur(inputType, validationType, e.target.value, required))}
            />

        case "textarea":


            return <textarea
                className={"cc__admin-input " + className}
                name={name}
                onChange={e => handleChange(e.target.name, e.target.value)}
                value={value}
                onBlur={e => setInnerValue(handleBlur(inputType, validationType, e.target.value, required))}
            >
                {value}
            </textarea>

        case "select":
            // Si el valor que contiene la variable value no es un arreglo, retorna un error
            if (!options) return <p>No se han proporcionado opciones para el tag "select"</p>
            if (!Array.isArray(options)) return <p style={{ color: 'red' }}>El tipo de elemento seleccionado no puede utilizar este tipo de valores</p>
            return <select
                className={"cc__admin-input " + className}
                name={name}
                onChange={e => handleChange(e.target.name, e.target.value)}
                value={value}
                type="text"
                onBlur={e => setInnerValue(handleBlur(inputType, validationType, e.target.value, required))}
            >
                {options.map(e => <option value={e.valor ? e.valor : e} key={e.key}>{e.name ? e.name : e}</option>)}

            </select>

        default:
            return <input
                className={"cc__admin-input " + className}
                name={name}
                onChange={e => handleChange(e.target.name, e.target.value)}
                value={value}
                type="text"
                onBlur={e => setInnerValue(handleBlur(inputType, validationType, e.target.value, required))}
            />
    }
}

const handleBlur = (type, validationType, value, isRequired) => {
    switch (type) {
        case 'select':
            if (isRequired) {
                if (value === "") {
                    return "warning"
                }
            }

            break;

        case 'textarea':
            if (value === '') {
                if (isRequired) {
                    return "warning"
                }
            }
            return 'succes'

        case 'input':
            if (validationType === "email") {
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const emailIsValid = re.test(value);

                if (emailIsValid) {
                    return 'succes'
                }
                return 'error'
            } else if (validationType === "number") {
                //Verificar si es valor es un número
                const valueIsNumber = !isNaN(value);

                if (valueIsNumber) {
                    return "succes"
                }
                return "error"
            }

            if (isRequired) {
                return "warning"
            }
            break;

        default:
            break;
    }
}

const inputFeedback = (value) => {
    switch (value) {
        case 'error':
            return 'cc__input-error';
        case 'succes':
            return 'cc__input-succes';
        case 'warning':
            return 'cc__input-warning';
        default:
            return 'cc__input';
    }
}

const StyledInputs = ({ key, value, title, type, validationType, handleChange, className = "", name, required = false, options, password = false }) => {

    const [innerValue, setInnerValue] = useState(0);

    return (<div className="cc__input" key={key}>
        <label htmlFor="">{title}</label>
        <div className={"cc__" + type + " " + (validationType && inputFeedback(innerValue))}>
            {inputType(type, name, className, value, handleChange, setInnerValue, required, validationType, options, password)}
        </div>
    </div>);
}

export default StyledInputs;